<template>
  <div class="product-description">
    <div class="product-description-item">{{ $t('b_register_product.label_description_of_page_1') }}</div>
    <div class="product-description-item">{{ $t('b_register_product.label_description_of_page_2') }}</div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.product-description {
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.05em;
  color: $monoBlack;
  max-width: 600px;
  padding-left: 20px;
}
@include desktop {
  .product-description {
    padding-left: 40px;
  }
}
</style>