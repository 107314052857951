<template>
  <div>
    <v-dialog
      v-model="getDialog"
      fullscreen
      ref="dialog"
      :persistent="true"
      content-class="modal-fullscreen width-100pc"
      transition="dialog-bottom-transition"
      @click:outside="handleClickOutSite"
    >
      <div class="content-main">
        <div class="head-category-popup">
          <span class="log-confirm_text mr-auto">{{ $t('b_export_report.title_export_data')}}</span>
          <div class="list-emission-close" @click="getDialog = false">
            <img class="head-image-dialog-popup mr-0 log-confirm--btn close" src="@/assets/images/dialog/close.svg" />
            <img
              class="head-image-dialog-popup mr-0 log-confirm--btn close-active"
              src="@/assets/images/dialog/close_active.svg"
            />
            <div class="list-emission-close__text">{{ $t('b_export_report.button_close')}}</div>
          </div>
        </div>
        <div class="head-emission-category-border"></div>
        <div class="page-header">{{ title }}</div>
        <div class="export-report-popup">
          <div class="title-btn">
            <span>{{ $t('b_export_report.label_output_method')}}</span>
            <div class="title-require">{{ $t('b_export_report.label_required')}}</div>
          </div>
          <div class="selection__item">
            <default-pull-down-menu
              v-model="type"
              :items="typeExports"
              :initialValue="type"
              :disabled="false"
              width="100%"
              class="select-pulldown"
            />
          </div>
          <div class="export-icon">
            <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
          </div>
          <div class="title-btn">
            <span>{{ $t('b_export_report.label_period')}}</span>
            <div class="title-require">{{ $t('b_export_report.label_required')}}</div>
          </div>

          <div class="export-pull-down">
            <div class="row listMenu-btn-text">
              <v-select
                solo
                flat
                :items="startYearList"
                v-model="dataExport.yearStart"
                item-text="text"
                item-value="value"
                :label="chooseYear"
                class="custom-select select-type list-menu--select select-item"
                :menu-props="{ contentClass: 'select-menu export-select export pulldown-year' }"
              />
              <v-select
                solo
                flat
                :items="startMonthList"
                v-model="dataExport.monthStart"
                item-text="text"
                item-value="value"
                :label="chooseMonth"
                class="custom-select select-type list-menu--select select-item"
                :menu-props="{ contentClass: 'select-menu export-select export pulldown-year' }"
              />
            </div>
            <span class="line">ー</span>
            <div class="row listMenu-btn-text">
              <v-select
                solo
                flat
                :items="endYearList"
                v-model="dataExport.yearEnd"
                item-text="text"
                item-value="value"
                :label="chooseYear"
                class="custom-select select-type list-menu--select select-item"
                :menu-props="{ contentClass: 'select-menu export-select export pulldown-year' }"
              />
              <v-select
                solo
                flat
                :items="endMonthList"
                v-model="dataExport.monthEnd"
                item-text="text"
                item-value="value"
                :label="chooseMonth"
                class="custom-select select-type list-menu--select select-item"
                :menu-props="{ contentClass: 'select-menu export-select export pulldown-year' }"
              />
            </div>
          </div>
          <div class="export-icon">
            <img src="@/assets/icons/icon-arrow-menu.svg" alt="" />
          </div>
          <div class="title-btn">
            <span>{{ $t('b_export_report.label_narrow_down')}}</span>
          </div>
          <div class="export-pull-down margin-pull-down">
            <export-pulldown
              :key="reRenderKey.organizational"
              :dataList="dataList.organizational"
              :selectedData.sync="dataExport.organizational"
              :itemCount="dataList.organizational.length - 1"
              :placeholder="placeholderOrganizational"
              :arrowDownBtn="true"
              class="box-shadow-pull-down"
            />
          </div>
          <div class="export-pull-down">
            <export-pulldown
              :key="reRenderKey.companies"
              :dataList="dataList.companies"
              :selectedData.sync="dataExport.companies"
              :itemCount="dataList.companies.length - 1"
              :placeholder="placeholderCorporation"
              :searchPlacehoder="placeholderSearchCompany"
              :hasSearch="true"
              class="box-shadow-pull-down select-corporation-btn"
            />
          </div>
          <hr style="width: 100%; margin: 48px 0 32px; color: #4d4d4d; opacity: 0.4" />
          <div class="warp-submit">
            <common-button
              class="submit-btn"
              :label="btnExport"
              type="colored"
              v-ripple="false"
              :disabled="validateData()"
              @action="handlerExportData"
            />
          </div>
        </div>
      </div>
    </v-dialog>
    <error-popup :dialog="errorDialog" :message="errorMessage" @submit="errorDialog = false" />
  </div>
</template>
<script>
import { scopeCategoriesData, GET_ALL, methodGhg } from '@/constants/export-report.js';
import CommonButton from '@/components/utils/button.vue';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import ExportPulldown from '@/components/pulldown/exportReport';
import DefaultPullDownMenu from '@/components/products/common/pull-down-select.vue';
import { getExportReport } from '@/api/product';
import moment from 'moment';
import { mapState } from 'vuex'
import { getMonthListCustome, calcYearFromStartMonthCustome } from '@/utils/registerData';
import { getStartMonth } from "@/api/duration";
export default {
  components: { CommonButton, ErrorPopup, ExportPulldown, DefaultPullDownMenu },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    method: {
      type: Number,
      default: 1,
    },
    methodLayer2: {
      type: Number,
      default: 1,
    },
    monthYearSelectionList: {
      type: Array,
      default: () => [],
    },
    title: {
      type: String,
      default: '',
    },
    listProductMaster: {
      type: Array,
      default: () => [],
    },
    productMasterId: {
      type: Number,
      default: 1,
    },
    branchData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      scope: 1,
      category: 1,
      workFlowData: {},
      monthYearList: [],
      yearList: [],
      monthList: [],
      dataExport: {
        yearStart: null,
        yearEnd: null,
        monthStart: null,
        monthEnd: null,
        startAt: null,
        endAt: null,
        methodExport: 1,
        companies: [],
        organizational: [],
        methods: [],
      },
      dataList: {
        organizational: [],
        companies: [],
        methods: [],
        companyBranchIds: [],
      },
      msgSelectDuration: '',
      isCollapsed: [],
      isCollapsedLayer1: [],
      showPullDown: false,
      selectList: [],
      checked: false,
      getSelectedData: [],
      getAllText: 'すべて選択',
      errorDialog: false,
      errorMessage: '',
      filterIndex: [],
      reRenderKey: {
        organizational: 0,
        companies: 0,
        methods: 0,
      },
      disabledLayer: false,
      type: 1,
      typeExports: [
        {
          value: 1,
          name: this.$t("b_export_report.pulldown_unit_ghg_emissions"),
        },
        {
          value: 2,
          name: this.$t("b_export_report.pulldown_unit_detail"),
        },
      ],
      startMonth: null,
      startYearList: [],
      endYearList: [],
      startMonthList: [],
      endMonthList: [],
    };
  },
  async mounted() {
    await this.getDataStartMonth()
    const currentYear = new Date().getFullYear();
    const startYear = 2017;
    this.yearList = Array.from({ length: currentYear - startYear + 2 }, (value, index) => startYear + index).map(item => `${item}年`);
    this.monthYearList = this.monthYearSelectionList;
    this.scope = parseInt(this.$route.params.scope);
    this.category = parseInt(this.$route.params.category);
    this.defaultData();
    this.setDataYearAndMonth()
    // this.convertData();
    this.selectList = [];
    for (let month = 1; month <= 12; month++) {
      const monthName = `${month}月`;
      this.monthList.push(monthName);
    }
  },
  computed: {
    ...mapState("userData", ["isSettingPcaf"]),
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
    btnExport() {
      return this.$t("b_export_report.button_export_popup");
    },
    getTitle() {
      return '製品A（ABCDEF-GH）のデータを出力します。';
    },
    durationStart() {
      const yearStart = this.dataExport.yearStart;
      const monthStart = this.dataExport.monthStart;
      if (yearStart !== null && monthStart !== null) {
        return `${yearStart}-${monthStart}`;
      }
      return '';
    },
    durationEnd() {
      const yearEnd = this.dataExport.yearEnd;
      const monthEnd = this.dataExport.monthEnd;
      if (yearEnd !== null && monthEnd !== null) {
        return `${yearEnd}-${monthEnd}`;
      }
      return '';
    },
    chooseYear() {
      return this.$t('b_export_report.placeholder_select_year')
    },
    chooseMonth() {
      return this.$t('b_export_report.placeholder_select_month')
    },
    placeholderOrganizational() {
      return this.$t('b_export_report.placeholder_organizational_classification')
    },
    placeholderCorporation() {
      return this.$t('b_export_report.placeholder_select_a_corporation')
    },
    btnPopup() {
      return this.$t('b_export_report.button_export_popup')
    },
    placeholderSearchCompany() {
      return this.$t('b_export_report.placeholder_search_for_corporations')
    }
  },
  watch: {
    durationStart(newVal) {
      this.checkDuration(newVal);
    },
    durationEnd(newVal) {
      this.checkDuration(newVal);
    },
    'dataExport.organizational': {
      handler() {
        this.addFilterIndex('organizational');
        if (this.dataExport.organizational.length === 0) {
          this.addFilterIndex('organizational', true);
        }
        this.updateFilter();
      },
      deep: true,
    },
    'dataExport.companies': {
      handler() {
        this.addFilterIndex('company');
        if (this.dataExport.companies.length === 0) {
          this.addFilterIndex('company', true);
        }
        this.updateFilter();
      },
      deep: true,
    },
    'dataExport.monthStart': {
      handler() {
        this.startYearList = calcYearFromStartMonthCustome(this.startMonth, this.dataExport.yearStart)
        const indexYear = this.startYearList.findIndex(item => item.value === this.dataExport.yearStart)
        if(indexYear < 0) {
          this.dataExport.yearStart = null
        }
        this.checkDuration('startAt');
      }
    },
    'dataExport.yearStart': {
      handler() {
        this.startMonthList = getMonthListCustome(this.dataExport.yearStart, this.startMonth)
        const indexMonth =  this.startMonthList.findIndex(item => item.value === this.dataExport.monthStart)
        if(indexMonth < 0) {
          this.dataExport.monthStart = null
        }
        this.checkDuration('startAt');
      }
    },
    'dataExport.monthEnd': {
      handler() {
        this.endYearList = calcYearFromStartMonthCustome(this.startMonth, this.dataExport.yearEnd)
        const indexYear = this.endYearList.findIndex(item => item.value === this.dataExport.yearEnd)
        if(indexYear < 0) {
          this.dataExport.yearEnd = null
        }
        this.checkDuration('endAt');
      }
    },
    'dataExport.yearEnd': {
      handler() {
        this.endMonthList = getMonthListCustome(this.dataExport.yearEnd, this.startMonth)
        const indexMonth =  this.endMonthList.findIndex(item => item.value === this.dataExport.monthEnd)
        if(indexMonth < 0) {
          this.dataExport.monthEnd = null
        }
        this.checkDuration('endAt');
      }
    },
    monthYearSelectionList() {
      this.monthYearList = this.monthYearSelectionList;
    },
  },
  methods: {
    validateData() {
      return !(this.durationStart !== '' && this.durationEnd !== '');
    },
    handleClickOutSite() {
      this.getDialog = false;
    },
    checkDuration() {
      if(this.durationStart !== '' && this.durationEnd !== '') {
        const dateStart = this.durationStart?.split(/年|-|月/).filter(Boolean);
        const dateEnd = this.durationEnd?.split(/年|-|月/).filter(Boolean);       
        const dateStartAt = new Date(`${dateStart[0]}-${dateStart[1]}`);
        const dateEndAt = new Date(`${dateEnd[0]}-${dateEnd[1]}`);
        if (dateStartAt > dateEndAt) {
          if(dateStartAt?.getFullYear() > dateEndAt?.getFullYear()){
            this.errorMessage = this.$t('popup.popup_month_before_end_date');
            this.errorDialog = true;
            return false;
          }else {
            this.errorMessage = this.$t('popup.popup_month_after_start_date');
            this.errorDialog = true;
            return false;
          }
        }
        return true;
      }
      return false;
    },
    defaultData() {
      this.dataList.organizational.push({
        text: GET_ALL,
        hasCheckbox: true,
      });
      this.branchData?.organizational_division
        ?.filter((item) => item.status === true)
        ?.map((item) => {
          this.dataList.organizational.push({
            text: item.value,
            hasCheckbox: true,
            branch_ids: item.branch_ids,
            value: item.key,
            id: item.key,
          });
        });
      this.dataList.companies.push({
        text: GET_ALL,
        hasCheckbox: true,
      });
      this.branchData.company_name
        ?.filter((item) => item.status === true)
        ?.map((item) => {
          this.dataList.companies.push({
            text: item.value,
            hasCheckbox: true,
          });
        });
        this.branchData.companyBranchIds?.map((item) => {
        this.dataList.companyBranchIds.push({
          organizational_division: item.organizational_division,
          company_name: item.company_name,
          branch_ids: item.branch_ids,
          organizational_division_id: item.organizational_division_id
        });
      });
    },
    convertData() {
      let scopeCategoriesDataPattern = scopeCategoriesData
      if(this.isSettingPcaf) {
        const indexGhg = scopeCategoriesDataPattern.findIndex(item => item.categories === 15)
        scopeCategoriesDataPattern[indexGhg].methods = methodGhg
      }
      let newScopeCategories = scopeCategoriesDataPattern.find(
        (item) => item.scope === this.scope && item.categories === this.category,
      );
      this.dataList.methods = [
        {
          text: this.getAllText,
          hasCheckbox: true,
        },
      ];
      this.dataList.methods.push(newScopeCategories);
      if (!newScopeCategories?.methods) {
        this.disabledLayer = true;
        this.dataExport.methods.push(`${newScopeCategories.text}`);
      } else {
        this.dataList.methods.map((item) => {
          let existItemLayer1 = item?.methods?.find((method) => method.method === this.method);
          if (this.methodLayer2) {
            existItemLayer1 = item?.methods?.find(
              (method) => method.method === this.method && method.methodLayer2 === this.methodLayer2,
            );
          }
          if (existItemLayer1) {
            this.dataExport.methods.push(`${item.text}_${existItemLayer1.text}`);
          } else {
            item?.methods?.map((itemLayer1) => {
              let existItemLayer2 = itemLayer1?.methods?.find((method) => method.method === this.method);
              if (this.methodLayer2) {
                existItemLayer2 = itemLayer1?.methods?.find(
                  (method) => method.method === this.method && method.methodLayer2 === this.methodLayer2,
                );
              }
              if (existItemLayer2) {
                this.dataExport.methods.push(`${item.text}_${itemLayer1.text}_${existItemLayer2.text}`);
              }
            });
          }
        });
      }
      this.reRenderKey.methods++;
    },
    toggleCollapse(index) {
      this.$set(this.isCollapsed, index, !this.isCollapsed[index]);
    },
    getMethodLayer1(index) {
      return this.dataList.methods[index]?.methods;
    },
    getMethodLayer2(layer1Index, index) {
      return this.dataList.methods[index]?.methods[layer1Index]?.methods;
    },
    formatDuration(time) {
      return moment(time, 'YYYY-M').format('YYYY-MM');
    },
    async handlerExportData() {
      if (!this.checkDuration()) return;
      const {yearStart, yearEnd, monthStart, monthEnd} = this.dataExport 
      let payload = {
        contractor_id: this.$store.state.userData.contractor,
        start_at : this.formatDuration(this.durationStart),
        end_at : this.formatDuration(this.durationEnd),
        list_product_master_ids: [this.productMasterId],
        organizational_division_branch_ids: [],
        company_name_branch_ids: [],
      };

      if (this.dataExport.companies.length === 0 && this.dataExport.organizational.length !== 0) {
        this.dataExport.organizational.map((item) => {
          const branchIds = this.dataList.organizational.find((organi) => organi.text === item);
          payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(
            branchIds?.branch_ids,
          );
        });
      } else if (this.dataExport.organizational.length === 0 && this.dataExport.companies.length !== 0) {
        this.dataExport.companies.map((item) => {
          this.dataList.companyBranchIds
            .filter((company) => company.company_name === item)
            .map((branchId) => {
              payload.company_name_branch_ids = payload.company_name_branch_ids.concat(branchId?.branch_ids);
            });
        });
      } else {
        if (this.filterIndex.length > 0) {
          if (this.filterIndex[0] === 'organizational') {
            let newArray = [...this.dataList.companyBranchIds];
            let newOrgari = [];
            for (let i = newArray.length - 1; i >= 0; i--) {
              if (
                this.dataExport.companies.includes(newArray[i].company_name) &&
                this.dataExport.organizational.includes(newArray[i].organizational_division)
              ) {
                newOrgari.push(newArray[i].organizational_division);
                payload.company_name_branch_ids = payload.company_name_branch_ids.concat(newArray[i].branch_ids);
                payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(
                  newArray[i].branch_ids,
                );
                newArray.splice(i, 1);
              } else if (!this.dataExport.organizational.includes(newArray[i].organizational_division)) {
                newArray.splice(i, 1);
              }
            }
            newArray.map((item) => {
              if (!newOrgari.includes(item.organizational_division)) {
                payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(
                  item.branch_ids,
                );
              }
            });
          } else {
            let newArray = [...this.dataList.companyBranchIds];
            let newCompaies = [];
            for (let i = newArray.length - 1; i >= 0; i--) {
              if (
                this.dataExport.companies.includes(newArray[i].company_name) &&
                this.dataExport.organizational.includes(newArray[i].organizational_division)
              ) {
                newCompaies.push(newArray[i].company_name);
                payload.company_name_branch_ids = payload.company_name_branch_ids.concat(newArray[i].branch_ids);
                payload.organizational_division_branch_ids = payload.organizational_division_branch_ids.concat(
                  newArray[i].branch_ids,
                );
                newArray.splice(i, 1);
              } else if (!this.dataExport.companies.includes(newArray[i].company_name)) {
                newArray.splice(i, 1);
              }
            }
            newArray.map((item) => {
              if (!newCompaies.includes(item.company_name)) {
                payload.company_name_branch_ids = payload.company_name_branch_ids.concat(item.branch_ids);
              }
            });
          }
        }
      }
      // let urlPayload = '?';
      // const arrayKey = ['list_product_master_ids','organizational_division_branch_ids', 'company_name_branch_ids'];
      // for (let key in payload) {
      //   if (arrayKey.includes(key)) {
      //     if (payload[key].length) {
      //       if (key === 'company_name_branch_ids') {
      //         urlPayload += `${key}=[${payload[key]}]`;
      //       } else {
      //         urlPayload += `${key}=[${payload[key]}]&`;
      //       }
      //     }
      //   } else {
      //     urlPayload += `${key}=${payload[key]}&`;
      //   }
      // }
      // if (urlPayload.charAt(urlPayload.length - 1) === '&') {
      //   // Remove & if position is last string
      //   urlPayload = urlPayload.slice(0, -1);
      // }
      this.getDialog = false;
      await getExportReport(payload, this.type === 2)
        .then((res) => {
          this.getCsvExport(res);
        })
        .catch((error) => {
          console.warn(error);
        });
    },
    getCsvExport(content) {
      let fullDate  = moment().format("YYYYMMDDHHmmss");
      let excelName = ''
      let url = null
      if(this.type === 2) {  //handle csv
        excelName = `raw_report_${fullDate}.csv`;
        url = window.URL.createObjectURL(new Blob([content]));
      } else { //handle xlsx
        excelName = `Product_report_${fullDate}.xlsx`;
        url = window.URL.createObjectURL(
          new Blob([content], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
        );
      }
      
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', excelName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    addFilterIndex(type, isRemove = false) {
      const existIndex = this.filterIndex.findIndex((item) => {
        return item === type;
      });
      if (isRemove) {
        if (this.filterIndex[0] === type) {
          this.dataExport.companies.length = 0;
          this.dataExport.organizational.length = 0;
          this.filterIndex.length = 0;
          this.dataList.companyBranchIds.map((item) => {
            this.addOrganiToList(item);
            this.addCompanyToList(item);
          });
          this.organiListSort();
          type === 'organizational' ? this.reRenderKey.companies++ : this.reRenderKey.organizational++;
        } else {
          this.filterIndex.splice(existIndex, 1);
        }
      }
      if (existIndex < 0) {
        this.filterIndex.push(type);
      }
    },
    updateFilter() {
      if (this.filterIndex.length === 0) {
        this.dataList.companyBranchIds.map((item) => {
          this.addOrganiToList(item);
          this.addCompanyToList(item);
        });
        this.organiListSort();
      } else {
        if (this.filterIndex[0] === 'organizational') {
          this.dataList.companies.length = 0;
          this.dataList.companies.push({
            text: GET_ALL,
            hasCheckbox: true,
          });
          this.dataExport.organizational.map((organi) => {
            this.dataList.companyBranchIds
              .filter((companyItem) => companyItem.organizational_division === organi)
              .map((item) => {
                this.addCompanyToList(item);
              });
          });
          const companies = this.dataList.companies.map((company) => company.text);
          this.dataExport.companies.map((item, index) => {
            if (!companies.includes(item)) {
              this.dataExport.companies.splice(index, 1);
            }
          });
        } else {
          // this.filterIndex[0] === 'company'
          this.dataList.organizational.length = 0;
          this.dataList.organizational.push({
            text: GET_ALL,
            hasCheckbox: true,
          });
          this.dataExport.companies.map((company) => {
            this.dataList.companyBranchIds
              .filter((organiItem) => organiItem.company_name === company)
              .map((item) => {
                this.addOrganiToList(item);
              });
          });
          const organiDivisions = this.dataList.organizational.map((organi) => organi.text);
          this.dataExport.organizational.map((item, index) => {
            if (!organiDivisions.includes(item)) {
              this.dataExport.organizational.splice(index, 1);
            }
          });
          this.organiListSort();
        }
      }
    },
    addOrganiToList(item) {
      const existIndex = this.dataList.organizational.findIndex(
        (organi) => organi.text === item.organizational_division,
      );
      if (existIndex < 0) {
        this.dataList.organizational.push({
          text: item.organizational_division,
          hasCheckbox: true,
          branch_ids: item.branch_ids,
          id: item.organizational_division_id,
        });
      }
    },
    addCompanyToList(item) {
      const existIndex = this.dataList.companies.findIndex((company) => company.text === item.company_name);
      if (existIndex < 0) {
        this.dataList.companies.push({
          text: item.company_name,
          hasCheckbox: true,
        });
      }
    },
    addProductToList(item) {
      const existIndex = this.dataList.companies.findIndex((company) => company.text === item.company_name);
      if (existIndex < 0) {
        this.dataList.companies.push({
          text: item.company_name,
          hasCheckbox: true,
        });
      }
    },
    toggleDropdown() {
      this.showPullDown = !this.showPullDown;
    },
    organiListSort() {
      this.dataList.organizational.sort((a, b) => {
        return a.id - b.id;
      });
    },
    async getDataStartMonth() {
      try {
        const res = await getStartMonth(this.$store.state.userData.contractor)
        this.startMonth = res.data?.start_month
      } catch (error) {
        console.warn(error);
      }
    },
    setDataYearAndMonth() {
      //startYearList, endYearList
      this.startYearList = calcYearFromStartMonthCustome(this.startMonth, 2017)
      this.endYearList = [ ...this.startYearList ]
      this.startMonthList = this.endMonthList = getMonthListCustome(this.dataExport.yearStartAt, this.startMonth)
    }
  },
};
</script>
<style lang="scss">
.modal-fullscreen {
  background: $bgExtraLight;
  .listMenu-btn-text {
    .v-text-field.v-text-field--solo .v-label {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoMid;
    }
  }
}
</style>
<style lang="scss" scoped>
.modal-fullscreen {
  position: relative;
  max-width: 100%;
  &.width-100pc {
    max-width: 100%;
    width: 100%;
  }
  .content-main {
    height: 100%;
    @include tablet {
      display: grid;
      grid-template-rows: auto auto auto 1fr;
      align-items: center;
      padding-bottom: 48px;
    }
    .head-category-popup {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 20px;

      .log-confirm_text {
        height: 32px;
        // font-family: 'Source Han Sans JP';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 32px;
        letter-spacing: 0.03em;
        color: $goldMid;
      }
      .list-emission-close {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 8px;
        gap: 8px;
        width: 66px;
        height: 62px;
        background: #f7f7f2;
        box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
          0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
          0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
        border-radius: 4px;
        cursor: pointer;
        &__text {
          font-style: normal;
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          text-align: center;
          letter-spacing: 0.03em;
          color: $monoBlack;
        }
        .close-active {
          display: none;
        }
        .close {
          display: block;
        }
        &:hover {
          background: $goldLight;
          .close-active {
            display: block;
          }
          .close {
            display: none;
          }
          .list-emission-close__text {
            color: $monoWhite;
          }
        }
        &:active {
          background: $monoOffWhite;
          border: 2px solid $blueMid;
          .close-active {
            display: none;
          }
          .close {
            display: block;
          }
          .list-emission-close__text {
            color: $monoBlack;
          }
        }
      }
    }
    .head-emission-category-border {
      width: 100%;
      height: 1px;
      background: rgba(121, 134, 134, 0.12);
    }
    .page-header {
      margin-top: 48px;
      margin-bottom: 48px;
      color: $monoBlack;
      font-weight: 400;
      font-size: 15px;
      line-height: 28px;
      letter-spacing: 0.05em;
      padding: 0 40px;
      word-break: break-all;
    }
    .export-report-popup {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      max-width: 560px;
      //height: 592px;
      margin: 0 auto;
      padding: 32px 0;
      background: $bgLight;
      .selection__item {
        width: 100%;
      }
      .description-text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 16px 32px;
        // font-family: 'Source Han Sans JP';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        background: $monoOffWhite;
        position: absolute;
        .selected-count {
          color: $monoMid;
          font-size: 13px;
          font-weight: 400;
          letter-spacing: 0.05em;
        }
      }
    }
  }
  .dialog-title {
    font-size: 18px;
  }
  .tooltip-none {
    font-size: 14px;
    padding: 20px 0px;
    title {
      .title-icon {
        display: none;
      }
    }
  }
  .selection__item {
    padding: 0 32px;
    .select-item .v-input__slot .v-input__append-inner {
      display: none;
    }
    .pull-down-text-color {
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
        0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
        0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      transition: 0.2s ease-in-out;

      .item {
        padding: 5px 10px;
        border-bottom: 1px solid #ccc;
        .input-checkbox {
          margin-right: 8px;
        }
      }
    }
  }
  .title-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 24px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoBlack;
    margin: 0 32px 8px;
    .title-require {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px 4px 2px;
      // width: 31px;
      height: 20px;
      background: $goldMid;
      border-radius: 4px;
      color: $monoWhite;
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.03em;
    }
  }
  .export-pull-down {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    width: 100%;
    padding: 0 32px;
    gap: 16px;
    &.margin-pull-down {
      margin-bottom: 16px;
    }
    .subtracted-divider {
      color: $monoMid;
      margin: 0 16px;
    }
  }
  .err-item {
    color: $redMid;
    font-size: 13px;
    margin: 0;
  }
  .export-icon {
    width: 36px;
    margin: 24px auto;
    display: flex;
    justify-items: center;
  }
  .warp-submit {
    width: 100%;
    padding: 0 32px;
    .submit-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 20px;
      background: $goldMid;
      width: 100%;
      height: 40px;
      box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
        0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
        0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
      border-radius: 4px;
      color: $monoWhite;
      &:hover {
        /* Gold/Light */

        background: $goldLight;
        box-shadow: 0px 4px 14px rgba(160, 181, 186, 0.167237), 0px 1.20588px 4.22059px rgba(160, 181, 186, 0.24),
          0px 0.500862px 1.75302px rgba(160, 181, 186, 0.312763), 0px 0.181152px 0.634032px rgba(160, 181, 186, 0.48);
      }
      &:focus-within {
        border: 2px solid $blueMid;
        padding: 6px 0px 16px;
        color: $monoWhite;
      }
    }
  }
  .strikethrough {
    position: absolute;
    left: 0;
    right: 0;
    color: #f7f7f2;
    opacity: 0.5;
  }
}
.row {
  gap: 16px;
}
.line {
  // font-family: Source Han Sans JP;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.75px;
  text-align: left;
  color: $monoMid;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: rotate(-90deg);
  width: 28px;
  height: 15px;
}
.custom-select {
  width: calc(50% - 16px);
}
.box-shadow-pull-down.select-corporation-btn {
  ::v-deep #dropdownInputText {
    &:hover {
      background: $goldLight;
      box-shadow: 0px 0.18115200102329254px 0.6340319514274597px 0px rgba(160, 181, 186, 0.48), 0px 0.5008620619773865px 1.7530173063278198px 0px rgba(160, 181, 186, 0.31), 0px 1.2058829069137573px 4.220590114593506px 0px rgba(160, 181, 186, 0.24), 0px 4px 14px 0px rgba(160, 181, 186, 0.17);
      .placeholder,.input-text {
        color: $monoWhite;
      }
    }
  }
  &::v-deep.pulldown-wrapper.disabled {
    #dropdownInputText {
      &:hover {
        background: $monoLight;
        box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
      }
      .placeholder,.input-text {
        color: $monoMid;
      }
      &.focus-pull-down {
        border: none;
      }
    }
  }
}
@include desktop {
  .modal-fullscreen {
    .content-main {
      .head-category-popup {
        padding: 16px 40px;
      }
    }
  }
}

@media (max-width: 768px) {
  .head-category-popup {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 2;
    background: #eff5f5;
    width: 100%;
    border-bottom: solid 1px rgba(121, 134, 134, 0.12);;
  }

  .page-header {
    margin-top: 142px !important;
  }
}
</style>
