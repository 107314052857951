import * as wjGrid from '@mescius/wijmo.grid';
import { AutoComplete } from "@mescius/wijmo.input";
import i18n from '@/lang/i18n.js';
import { getWidthByTextContent } from '@/utils/calcTextWidth';

const Information = {
  header(listUnit, listCurency, isReadOnly = false) {
    let minWidthValue;
    switch (i18n.locale) {
      case 'vi':
        minWidthValue = 175;
        break;
        case 'es':
          minWidthValue = 250;  
          break;
      default:
        minWidthValue = 100;
        break;
    }
    return [
      {
        header: "連番",
        binding: "id", // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: true,
        visible: false
      },
      {
        header: i18n.t('b_register_product.table_production_quantity'),
        align: "center",
        columns: [
          {
            header: i18n.t('b_register_product.table_quantity'),
            binding: "quantity",
            minWidth: getWidthByTextContent(i18n.t('b_register_product.table_quantity')),
            maxWidth: 500,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: "hide-filter",
            isReadOnly: isReadOnly
          },
          {
            header: i18n.t('b_register_product.table_quantity_unit'),
            binding: "quantity_unit",
            minWidth: getWidthByTextContent(i18n.t('b_register_product.table_quantity_unit')),
            maxWidth: 500,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            cssClassAll: "hide-filter",
            isRequired: false,
            dataMap: new wjGrid.DataMap(listUnit, 'id', 'name'),
            isReadOnly: isReadOnly,
            editor: new AutoComplete(document.createElement("div"), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: listUnit,
              selectedValuePath: 'id',
              displayMemberPath: 'name',
              maxItems: 1000,
              minLength: 1,
            }),
          },
        ]
      },
      {
        header: i18n.t('b_register_product.table_production_value'),
        align: "center",
        columns: [
          {
            header: i18n.t('b_register_product.table_amount'),
            binding: "amount",
            minWidth: getWidthByTextContent(i18n.t('b_register_product.table_amount')),
            maxWidth: 500,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            isReadOnly: isReadOnly,
            cssClassAll: "hide-filter",
          },
          {
            header: i18n.t('b_register_product.table_amount_unit'),
            binding: "amount_unit",
            minWidth: getWidthByTextContent(i18n.t('b_register_product.table_amount_unit')),
            maxWidth: 500,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            isRequired: false,
            dataMap: new wjGrid.DataMap(listCurency, 'id', 'name'),
            editor: new AutoComplete(document.createElement("div"), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: listCurency,
              selectedValuePath: 'id',
              displayMemberPath: 'name',
              maxItems: 1000,
              minLength: 1,
            }),
            isReadOnly: isReadOnly,
            cssClassAll: "hide-filter"
          },
        ]
      },
      {
        header: i18n.t('b_register_product.table_production_amount_per_product'),
        align: "center",
        columns: [
          {
            header: i18n.t('b_register_product.table_amount'),
            binding: "amount_per_product",
            minWidth: minWidthValue,
            maxWidth: 500,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: "hide-filter",
            isReadOnly: isReadOnly,
          },
          {
            header: i18n.t('b_register_product.table_amount_unit'),
            binding: "amount_unit",
            minWidth: getWidthByTextContent(i18n.t('b_register_product.table_amount_unit')),
            maxWidth: 500,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            isRequired: false,
            dataMap: new wjGrid.DataMap(listCurency, 'id', 'name'),
            editor: new AutoComplete(document.createElement("div"), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: listCurency,
              selectedValuePath: 'id',
              displayMemberPath: 'name',
              maxItems: 1000,
              minLength: 1,
            }),
            isReadOnly: isReadOnly,
            cssClassAll: "hide-filter",
          },
        ]
      },
      {
        header: i18n.t('b_register_product.label_production_quantity_of_all_products'),
        align: "center",
        columns: [
          {
            header: i18n.t('b_register_product.table_amount'),
            binding: "total_quantity",
            minWidth: minWidthValue,
            maxWidth: 500,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: "hide-filter",
            isReadOnly: isReadOnly,
          },
          {
            header: i18n.t('b_register_product.table_amount_unit'),
            binding: "quantity_unit",
            minWidth: getWidthByTextContent(i18n.t('b_register_product.table_amount_unit')),
            maxWidth: 500,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            isRequired: false,
            dataMap: new wjGrid.DataMap(listUnit, 'id', 'name'),
            editor: new AutoComplete(document.createElement("div"), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: listUnit,
              selectedValuePath: 'id',
              displayMemberPath: 'name',
              maxItems: 1000,
              minLength: 1,
            }),
            isReadOnly: isReadOnly,
            cssClassAll: "hide-filter",
          },
        ]
      },
      {
        header: i18n.t('b_register_product.table_production_amount_for_all_products'),
        align: "center",
        columns: [
          {
            header: i18n.t('b_register_product.table_amount'),
            binding: "total_amount",
            minWidth: minWidthValue,
            maxWidth: 500,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            align: 'right',
            cssClassAll: "hide-filter",
            isReadOnly: isReadOnly,
          },
          {
            header: i18n.t('b_register_product.table_amount_unit'),
            binding: "amount_unit",
            minWidth: getWidthByTextContent(i18n.t('b_register_product.table_amount_unit')),
            maxWidth: 500,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            isRequired: false,
            dataMap: new wjGrid.DataMap(listCurency, 'id', 'name'),
            editor: new AutoComplete(document.createElement("div"), {
              placeholder: i18n.t('56_pattern.placeholder'),
              itemsSource: listCurency,
              selectedValuePath: 'id',
              displayMemberPath: 'name',
              maxItems: 1000,
              minLength: 1,
            }),
            isReadOnly: isReadOnly,
            cssClassAll: "hide-filter",
          },
        ]
      },
    ];
  },
}
export default Information;