<template>
  <div class="category-table product-table" :class="{'create-data-table': isCreatedDataStatus}" id="category-table" @click="handleTableClick">
    <wj-flex-grid-search ref="theSearch" placeholder="検索語句を入力" id="search-input">
      <v-btn class="search-btn">
        <v-icon>mdi-magnify</v-icon>
      </v-btn>
    </wj-flex-grid-search>
    <div
      v-if="isShowToolbar"
      id="grid-group"
      class="grid-group"
      :class="[isFullScreen && 'grid-fullscreen', isOverText && 'is-over-text', isSettingScreen && 'grid-setting-screen', needCompress && 'compressed', isHasDataRegister && 'is-view-data', isViewProductFullMode && 'is-type-view-product-approved']">
      <div class="top-side">
        <div class="left">
          <div class="toolbar-fullscreen">
          <div class="fullscreen-item" :class="fullscreenWidth">
            <img src="@/assets/icons/icon-fullscreen.svg" alt="">
            <p class="mb-0 fullscreen-text">{{$t("register_data.button_display_on_full_screen")}}</p>
            <v-switch class="mt-0 pt-0 fullscreen-switch-btn" v-model="isFullScreens" color="#327998"></v-switch>
          </div>
        </div>
        </div>
        <div class="right" v-if="curentPermission !== 'view'">
           <undo-redo-comment-data-table />
        </div>
      </div>
      <div class="comment-side">
        <comment-data-table
            @onHandleShowCommentHistory="onHandleShowCommentHistory" 
            :totalComment="totalComment" 
          />
      </div>
      <div class="duration-side fullmode-duration" v-if="isFullScreen && !isSettingScreen">
          <div class="year-select durations-pulldown-table">
            <label class="durations-label">{{ $t("b_register_product.label_registration_date") }}</label>
            <div v-if="!isEditMonth" class="duration-text disabled">
              <span>{{ selectedYearText }}</span>
            </div>
            <select-item
              v-if="isEditMonth"
              class="durations-select-item duration-header"
              label="登録期間"
              :items="yearList"
              v-model="fullModeSelectYear"
              :selectEport= true
              :isFullScreens = "isFullScreens"
            />
          </div>
          <div class="month-select durations-pulldown-table">
            <div>
              <v-select
              v-if="isEditMonth"
              :items="monthList"
              item-text="text"
              item-value="value"
              v-model="fullModeSelectMonthChange"
              label=""
              placeholder=""
              hide-details
              dense
              solo
              no-data-text="''"
              class="durations-select-item select-item select-month new-register duration-header fullscreen-month-select"
              :disabled="!isEditMonth"
              :menu-props="{ contentClass: 'selecting select-durations fullmode product' }"
            >
              <template slot="item" slot-scope="data">
                {{ data.item.text }}
                <v-spacer></v-spacer>
                <span v-if="isHasDataRegister && data.item.duration_registered" class="data-is-registered"></span>
              </template>
            </v-select>
            <div v-else class="duration-text disabled"><span>{{ selectedMonthText }}</span></div>
            </div>
          </div>
          <div class="total-result">           
            <div class="total-emissions">
              <div class="total-emissions-item">
                <div class="total-emissions-item--label">{{ $t('b_register_product.label_emission') }}</div>
                <div class="total-emissions-item--value">{{ formatNumber(totalEmissions) || '---' }}<span class="unit">t-CO2</span></div>
              </div>
              <div class="total-emissions-item">
                <div class="total-emissions-item--label amount">{{ $t('b_register_product.label_exhaust_amount_per_product') }}</div>
                <div class="total-emissions-item--value">{{ formatNumber(amountPerProduct) || '---' }}<span class="unit">t-CO2</span></div>
              </div>
            </div>
          </div>
      </div>
      <div class="mid-side" v-if="!hideHeaderRegisterData() && $route.query.id && !isViewProduct" >
        <search-data-table
          :dataTitle.sync="dataTitle" 
          :isReadOnly="isReadOnlyTitle" 
        />
      </div>
      <div class="bottom-side" :class="{'height-fit-content': fullscreenWidth }" v-if="$route.query.id">
        <action-header-table 
          @onHandleActionTable="onHandleActionTable"
          @needCompressed="onNeedCompressed"
          @onHandleShowExportPopup="onHandleShowExportPopup"
          :curentPermission="curentPermission"
          :workFlowData="dataWorkflow"
          :isHasData="isHasData"
          :isEditedData="isEditedData"
          :isDisableActionTemplate="isDisableActionTemplate"
        />
      </div>
    </div>
    <div id="temp-table" :class="[isEmptySource && 'empty-source', isFullScreen && 'empty-process-fullscreen']" >
      <div id="undoable-table" >
        <div class="table-header-custome no-mb">
          <div class="table-header-custome-name">{{ isDetail ? `1. ${$t('b_register_product.label_information_and_organization')}` : `1. ${$t('b_register_product.title_organizational_information')}` }}</div>
        </div>
        <ProductInfo v-if="isDisableEditProduct" :product="product" id="table-header-input" />
        <div v-else class="table-header-input" id="table-header-input">
          <div class="table-type-input">
            <v-radio-group v-model="type" @change="handleTypeChange" :disabled="isDisableTypeChange">
            <v-radio
              v-for="(item, i) in inputTypes"
              :key="i"
              :label="item"
              :value="item"
              :ripple="false"
            ></v-radio>
          </v-radio-group>
          </div>
          <div class="table-name-input">
            <div class="table-name-input_label">
              {{ $t('b_register_product.label_product_name') }}
              <span class="table-name-input_label--require">{{ $t('b_register_product.label_essential') }}</span>
            </div>
            <InputField 
              v-if="isCreateProduct" 
              :inputValue="productName" 
              :isHasToolTip="isChangedProductName"
              :contentTooltip="contentToolTipProductName"
              @update:inputValue="handleUpdateProductName" 
            />
            <v-select
              v-else
              solo
              flat
              :items="listProductMaster"
              v-model="productName"
              item-text="name"
              item-value="name"
              no-data-text=""
              class="select-type list-menu--select select-item search-item-select"
              style="width: 100%;"
              @change="handleSelectProduct"
              :menu-props="{ contentClass: 'select-menu product-select' }"
            ></v-select>
          </div>
          <div class="table-name-input">
            <div class="table-name-input_label">{{ $t('b_register_product.label_product_code') }}</div>
            <InputField 
              :isReadOnly="!isCreateProduct" 
              :isHasToolTip="isChangedProductCode" 
              :inputValue="productCode" 
              :contentTooltip="contentToolTipProductCode"
              @update:inputValue="handleUpdateProductCode" 
            />
          </div>
        </div>
        <wj-flex-grid
          :isDisabled="isDisabled"
          id="theProductOrganizationGrid"
          ref="theProductOrganizationGrid"
          class="wrap-table column-flexible"
          :allowDragging="false"
          :showMarquee="true"
          :initialized="initProductOrganizationGrid"
          :itemsSource="dataProductOrganizationSource"
          :allowAddNew="false"
          :allowDelete="allowDelete"
          :columnGroups="gridProductOrganizationColumns"
          :autoRowHeights="false"
          :allowResizing="'ColumnsAllCells'"
          keyActionTab="CycleOut"
          :allowMerging="'Cells'"
          showSelectedHeaders="All"
          :quickAutoSize="true"
          :isReadOnly="isReadOnly"
          :autoGenerateColumns="false"
          :imeEnabled=true
          :selectionChanged="changeSelectionCell"
          :loadedRows="loadedRows"
          :cellEditEnded="autoSizeColumn"
        >
          <wj-flex-grid-filter
            :filterColumns="filterColumns"
            :showSortButtons="false"
            :filterApplied="onFilterApplied"
            :filterChanged="onFilterChanged"
          ></wj-flex-grid-filter>
        </wj-flex-grid>

        <div class="table-header-custome minus-top">
          <div class="table-header-custome-name">2. {{ $t('b_register_product.title_production_information') }}</div>
        </div>
        <wj-flex-grid
          :isDisabled="isDisabled"
          id="theProductInformationGrid"
          ref="theProductInformationGrid"
          class="wrap-table column-flexible"
          :allowDragging="false"
          :showMarquee="true"
          :initialized="initProductInformationGrid"
          :itemsSource="dataProductInformationSource"
          :allowAddNew="false"
          :allowDelete="allowDelete"
          :columnGroups="gridProductInformationColumns"
          :autoRowHeights="false"
          :allowResizing="'ColumnsAllCells'"
          keyActionTab="CycleOut"
          :allowMerging="'Cells'"
          showSelectedHeaders="All"
          :quickAutoSize="true"
          :isReadOnly="isReadOnly"
          :autoGenerateColumns="false"
          :imeEnabled=true
          :selectionChanged="changeSelectionCell"
          :loadedRows="loadedRows"
          :cellEditEnded="autoSizeColumn"
        >
          <wj-flex-grid-filter
            :filterColumns="filterColumns"
            :showSortButtons="false"
            :filterApplied="onFilterApplied"
            :filterChanged="onFilterChanged"
          ></wj-flex-grid-filter>
        </wj-flex-grid>
        <div class="table-header-custome minus-top">
          <div class="table-header-custome-name">3. {{ $t('b_register_product.title_process_information') }}<span v-if="curentPermission !== 'view'" class="require-item">{{ $t('b_register_product.label_essential') }}</span></div>
          <div class="table-header-custome-select" v-if="canAddProcess" :class="[curentPermission === 'view' && 'disable', isShowMethodSelect ? 'add-new-focus-button' : '']" @click.stop="isShowMethodSelect = !isShowMethodSelect"  @mouseenter="isActivePullDownIcon = true" @mouseleave="isActivePullDownIcon = false">
            <img class="table-header-custome-select--icon" v-if="curentPermission !== 'view'" src="@/assets/images/products/selectEmission.svg" alt="">
            <img class="table-header-custome-select--icon" v-else src="@/assets/images/products/selectEmission_disable.svg" alt=""> 
            <div class="table-header-custome-select--label">{{ $t('b_register_product.button_new_addition') }}</div>
            <div class="table-header-custome-select--pulldown" :class="isShowMethodSelect && 'open-pulldown'">
              <img class="icon-detail" :src="iconPullDown" alt="">
            </div>

            <div v-if="isShowMethodSelect" class="table-header-custome-pulldown" :style="isShowMethodService ? 'margin-top: 151px !important' : 'margin-top: 110px !important'">
              <template v-for="item in processMethodList">
                <div @click="handleSelectMethod(item)" class="table-header-custome-pulldown__item" v-if="isShowMethodService && item.value === 3" :key="`${item.value}-method`">{{ item.text }}</div>
                <div @click="handleSelectMethod(item)" class="table-header-custome-pulldown__item" v-else-if="item.value !== 3" :key="`${item.value}-method-item`">{{ item.text }}</div>
              </template>
          </div>
          </div>
          
        </div>
        <wj-flex-grid
          :isDisabled="isDisabled"
          ref="theProductProcessGrid"
          id="theProductProcessGrid"
          class="wrap-table column-flexible process-table"
          :style="getStyleTable"
          :allowDragging="false"
          :showMarquee="true"
          :initialized="initProductProcessGrid"
          :itemsSource="dataProductProcessSource"
          :allowAddNew="false"
          :allowDelete="allowDelete"
          :columnGroups="gridProductProcessColumns"
          :autoRowHeights="false"
          :allowResizing="'ColumnsAllCells'"
          keyActionTab="CycleOut"
          :allowMerging="'Cells'"
          showSelectedHeaders="All"
          :quickAutoSize="true"
          :isReadOnly="isReadOnly"
          :autoGenerateColumns="false"
          :imeEnabled=true
          :selectionChanged="changeSelectionCell"
          :loadedRows="loadedRows"
          :cellEditEnded="autoSizeColumn"
        >
          <wj-flex-grid-filter
            :filterColumns="filterColumns"
            :showSortButtons="true"
            :filterApplied="onFilterApplied"
            :filterChanged="onFilterChanged"
            :editingFilter="editingFilter"
          ></wj-flex-grid-filter>
        </wj-flex-grid>
        <div v-if="isEmptySource" class="empty-process">
          <div class="empty-process-number">1</div>
          <div class="content">
            <img src="@/assets/images/dialog/notification.svg" alt="">
            <div class="label">{{ $t('b_register_product.description_text_not_value') }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="action-table">
      <common-button label="新規登録" type="colored" />
      <common-button class="back-btn" v-ripple="false">
        <img src="@/assets/images/dialog/return.svg" class="icon-back" />
        <img src="@/assets/images/dialog/return_active.svg" alt="" class="icon-back_active" />
        <span class="common-btn-text ml-2">新規登録</span>
      </common-button>
    </div> -->

    <comment-log-popup :dialog="commentPopup" :contents="commentHistoryList" @close="commentPopup = false" />
  </div>
</template>

<script>
import "@mescius/wijmo.styles/wijmo.css";
import "@mescius/wijmo.vue2.grid";
import "@mescius/wijmo.input";
import "@mescius/wijmo.vue2.grid.filter";
import * as wjcGrid from "@mescius/wijmo.grid";
import "@mescius/wijmo.vue2.grid.detail";
// import "@mescius/wijmo.cultures/wijmo.culture.ja";
import * as wjcCore from "@mescius/wijmo";
import { mapActions, mapState } from "vuex";
import "@mescius/wijmo.vue2.grid.search";
import { Control } from "@mescius/wijmo";
import CommonButton from "@/components/commonApp/Button.vue";
import ActionHeaderTable from "@/components/products/table/action-table.vue";
import SearchDataTable from "@/components/commonApp/ActionTable/SearchDataTable";
import UndoRedoCommentDataTable from "@/components/commonApp/ActionTable/UndoRedoCommentDataTable";
import CommentDataTable from "@/components/commonApp/ActionTable/CommentDataTable";
import SelectItem from '@/components/utils/selectItem.vue';
import ProductInfo from "@/components/products/common/product-info.vue";
import { getProductListCommentHistory } from "@/api/product";

import { ROUTES } from '@/router/constants';
import InputField from '@/components/products/input/InputField.vue';
import CommentLogPopup from '@/components/dialogs/log-confirm-popup';
import { getListCommentHistory } from '@/api/registerData';
import { formatDateTimeDataTable } from '@/utils/datetimeFormat'
import { handleResizeGrid } from '@/utils/registerData'
import { PRODUCT_INPUT_TYPE, PRODUCT_LIST } from '@/constants/products/define-data'
import moment from 'moment';
import { $_helper_isNumberType, formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import { STATUS_FIELD } from '@/constants/status';
import {addThoundSandComma, addThoundSandCommaWithTwoDigit} from '@/utils/convertNumber';
import { getClassesFullScreenButton } from '@/utils/multiLanguage'
import '@mescius/wijmo.styles/wijmo.css';
import '@mescius/wijmo.styles/wijmo.css';
import '@mescius/wijmo.vue2.input';
import '@mescius/wijmo.vue2.chart';
import '@mescius/wijmo.vue2.grid';
import { Clipboard } from '@mescius/wijmo';
import { ACTION_CODE } from '@/constants/rightClickAction.js';
import { getAllService } from '@/api/linked-service-management';
import i18n from '@/lang/i18n.js';

export default {
  data() {
    return {
      isFullScreens: false,
      startIndex: 0,
      endIndex: 0,
      sourceCollection: null,
      theSearch: null,
      theGrid: null,
      theProductOrganizationGrid: null,
      theProductInformationGrid: null,
      theProductProcessGrid: null,
      dataTable: null,
      idLast: null,
      currentSelection: null,
      flexGrid: null,
      selectionMode: null,
      windowWidth: window.innerWidth,
      isClickFilter: false,
      dataTitle: null,
      commentPopup    : false,
      commentHistoryList: [],
      needCompress: false,
      heightToolBar: 0,
      listWidth: [],
      listColumns: [],
      isShowMethodSelect: false,
      processMethodList: [
        { text: this.$t('b_register_product.label_application_input_from_registered_emissions'), value: 1 },
        { text: this.$t('b_register_product.label_individual_entry_of_each_item'), value: 2 },
        { text: this.$t('b_linked_service.lable_option_linked'), value: 3 },
      ],
      type: PRODUCT_INPUT_TYPE.CREATE_NEW,
      inputTypes: [PRODUCT_INPUT_TYPE.CREATE_NEW, PRODUCT_INPUT_TYPE.CREATE_FROM_PRODUCT],
      productCode: '',
      productName: '',
      products: PRODUCT_LIST,
      isActivePullDownIcon: false,
      fullscreenWidth: getClassesFullScreenButton(),
      isOverText: null,
      isOverText: null,
      isShowMethodService: false,
      isCheckLang: i18n.locale
    };
  },
  components: {
    CommonButton,
    ActionHeaderTable,
    SearchDataTable,
    UndoRedoCommentDataTable,
    CommentDataTable,
    CommentLogPopup,
    SelectItem,
    InputField,
    ProductInfo
  },
  props: {
    yearSelect: {
      default: null,
    },
    monthSelect: {
      default: null,
    },
    isCreateNewData: {
      type: Function,
      default: () => {
      }
    },
    isCreatedDataStatus: {
      type: Boolean,
      default: false
    },
    isGetAllData: {
      type: Boolean,
      default: true
    },
    durationList: {
      type: Array,
      default: () => []
    },
    monthYearSelectionList: {
      type: Array,
      default: () => []
    },
    selectedYearText: {
      type: String,
      default: '',
    },
    selectedMonthText: {
      type: String,
      default: '',
    },
    dataPattern: {
      type: Object,
      default: () => {
      }
    },
    dataProductOrganizationSource: {
      type: Object,
      default: () => {
      }
    },
    dataProductInformationSource: {
      type: Object,
      default: () => {
      }
    },
    allowRightClick: {
      type: Boolean,
      default: true
    },
    dataProductProcessSource: {
      type: Object,
      default: () => {
      }
    },
    initProductOrganizationGrid: {
      type: Function,
      default: () => {
      }
    },
    initProductInformationGrid: {
      type: Function,
      default: () => {
      }
    },
    initProductProcessGrid: {
      type: Function,
      default: () => {
      }
    },
    gridProductOrganizationColumns: {
      type: Array,
      default: () => []
    },
    gridProductInformationColumns: {
      type: Array,
      default: () => []
    },
    gridProductProcessColumns: {
      type: Array,
      default: () => []
    },
    filterColumns: {
      type: Array,
      default: () => []
    },
    allowDelete: {
      type: Boolean,
      default: false
    },
    allowAddNew: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    totalData: {
      type: Number,
      default: 0
    },
    rowFocus: {
      type: Number,
      default: 0
    },
    isReadOnly: {
      type: Boolean,
      default: false
    },
    isSetting: {
      type: Boolean,
      default: false
    },
    isHasData: {
      type: Boolean,
      default: false
    },
    isShowToolbar: {
      type: Boolean,
      default: true
    },
    isEditedData: {
      type: Number,
      default: 0
    },
    isReadOnlyTitle: {
      type: Boolean,
      default: false
    },
    pattern: {
      type: Object,
      default: () => {}
    },
    isResizeCustome: {
      type: Boolean,
      default: false
    },
    isEmptySource: {
      type: Boolean,
      default: false
    },
    isDetail: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object,
      default: () => {}
    },
    isProductSubmit: {
      type: Boolean,
      default: false
    },
    dataWorkflow: {
      type: Object,
      default: () => {}
    },
    listProductMaster: {
      type: Array,
      default: () => []
    },
    totalComment: {
      type: Number,
      default: 0
    },
    curentPermission: {
      type: String,
      default: ''
    },
    isEditMonth: {
      type: Boolean,
      default: false,
    },
    is_new_registration: {
      type: Boolean,
      default: false,
    },
    changedColumns: {
      type: [Object, Array],
      default: () => {}
    },
    isEditDuration: {
      type: Boolean,
      default: false,
    },
    isDisableActionTemplate: {
      type: Boolean,
      default: false,
    },
    yearList: {
      type: Array,
      default: () => []
    },
    monthList: {
      type: Array,
      default: () => []
    },
    totalEmissions: {
      type: [String, Number],
      default: ''
    },
    amountPerProduct: {
      type: [String, Number],
      default: ''
    },
  },
  // connect search box and grid 
  mounted() {
    import(`@mescius/wijmo.cultures/wijmo.culture.${this.$i18n.locale}`) 
    
    wijmo.culture.FlexGridFilter.null = this.$t("register_data.checkbox_blank");
    this.theProductOrganizationGrid = Control.getControl(this.$refs.theProductOrganizationGrid.$el);
    this.theProductInformationGrid = Control.getControl(this.$refs.theProductInformationGrid.$el);
    this.theProductProcessGrid = Control.getControl(this.$refs.theProductProcessGrid.$el);
    this.$emit("flexGridInitialDone", this.theProductOrganizationGrid, this.theProductInformationGrid, this.theProductProcessGrid);
    this.theSearch = Control.getControl(this.$refs.theSearch.$el);
    this.actionRemoveListOfEditedCells([]);
    this.theSearch.grid = this.theGrid;
    this.setRowIndex(this.theProductProcessGrid);
    // focus invalidate cell
    // this.theGrid.validateEdits = false;
    this.actionStartUpdate({
      undo: false,
      redo: false
    });
    this.actionUpdateStatusBtn({
      undo: false,
      redo: false
    });
    this.updateActionDelete(false);
    this.actionUpdateDataAction({});
    this.actionUpdate({});
    this.actionUpdateFilter(false);
    // this.actionUpdateIsFullScreen(false);
    this.isFullScreens = this.isFullScreen;
    window.onresize = () => {
      this.windowWidth = window.innerWidth;
    };
    this.hideHeaderRegisterData();
    // this.dataTitle = this?.dataWorkflow?.title;
    this.checkShowOptionService();
    this.isOverText = this.getWidthAllActionTable();
    window.addEventListener('resize', this.handlerCalcHeight);
    window.addEventListener('click', this.handleOutsideClick);
  },
  watch: {
    // set width for larger screens 2000
    gridProductOrganizationColumns: function(newValue) {
      if (this.windowWidth > 2000) {
        newValue.forEach((ele) => {
          ele.width = "*";
          if (ele.columns) {
            ele.columns.forEach((k) => {
              k.width = "*";
            });
          }
        });
      }
      const seft = this
    },

    isFullScreens: function(newState) {
      // waiting DOM updated => calc height
      this.$nextTick(() => {
        this.handlerCalcHeight()
      });
     
      this.actionUpdateIsFullScreen(newState);
      this.$emit("changeFullScreen", newState);
    },

    productName() {
      this.$emit('updateProductName', this.productName)
    },

    product() {
      this.productCode = this.product?.productCode
      this.productName = this.product?.productName
    },

    is_new_registration: {
      handler(newVal) {
        this.type = newVal ? this.inputTypes[0] : this.inputTypes[1]
      }
    },

    dataWorkflow() {
      this.dataTitle = this?.dataWorkflow?.title;
    },
    dataTitle() {
      this.$emit('updateDatatitle', this.dataTitle)
    }
  },
  computed: {
    ...mapState("commonApp", ["loadMore", "isFilter"]),
    ...mapState("registerData", ["listOfEditedCells", "isError", "isFullScreen"]),
    isHasDataRegister() {
      // Handle condition to display dot
      return this.$route.fullPath.includes('/emissions/view/');
    },
    displayCommentOnDetails() {
        return [ROUTES.LIST_EMISSION_SUBMITTED, ROUTES.LIST_APPROVAL, ROUTES.LIST_MENU_EMISSION].includes(
          this.$route.params?.scope,
        ) || (this.$route.path === ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_NEW_REGISTER && this.$route.query?.type !== 'template');
    },
    fullModeSelectMonthChange: {
      get() {
        return this.monthSelect;
      },
      set(newOption) {
        this.$emit("update:value", newOption);
      }
    },
    fullModeSelectYear: {
      get() {
        return this.yearSelect;
      },
      set(newOption) {
        this.$emit("update:input", newOption);
      }
    },
    isSettingScreen() {
      return [
        ROUTES.EMISSIONS + '/' + ROUTES.EMISSIONS_BY_BASE_CATEGORY,
        ROUTES.EMISSIONS + '/' + ROUTES.EMISSIONS_BY_BASE_PERIOD,
        ROUTES.USER_MANAGEMENT,
        ROUTES.VIEWER_MANAGEMENT,
        ROUTES.FACILITY_MANAGEMENT,
        ROUTES.CREATE_DATA_CUSTOMIZE].includes(this.$route.path);
    },
    getStyleTable() {
      if(!this.isFullScreens){
        return ''
      } else {
        // return `max-height: 400px !important; margin-bottom: -5px`;
        return '';
        const headerInputHeight = document.getElementById('table-header-input')?.offsetHeight;
        const facilityGridHeight = document.getElementById('theProductOrganizationGrid')?.offsetHeight;
        const informationGridHeight = document.getElementById('theProductInformationGrid')?.offsetHeight;
        const heightButtonAction = this.$route.query?.id ? 0 : 56
        // return `max-height: calc(100vh - (88px + ${this.heightToolBar}px + 132px + ${facilityGridHeight}px + ${informationGridHeight}px + ${headerInputHeight}px + ${heightButtonAction}px)) !important; margin-bottom: -5px`
      }
    },
    isCreateProduct() {
      return this.type === PRODUCT_INPUT_TYPE.CREATE_NEW
    },
    iconPullDown() {
      if (!this.isActivePullDownIcon) {
        return require('@/assets/images/products/pulldownIcon.svg');
      }
      return require('@/assets/images/products/pulldownIcon_active.svg');
    },
    isDisableTypeChange() {
      return this.$route.query?.id ? true : false
    },
    isDisableEditProduct() {
      return this.isProductSubmit || this.curentPermission !== 'edit' && this.$route.query?.id
    },
    isChangedProductName() {
      return this.changedColumns?.product_master?.name ? true : false
    },
    contentToolTipProductName() {
      return this.getTootipContent(this.changedColumns?.product_master?.name)
    },
    isChangedProductCode() {
      return this.changedColumns?.product_master?.code ? true : false
    },
    contentToolTipProductCode() {
      return this.getTootipContent(this.changedColumns?.product_master?.code)
    },
    canAddProcess() {
      return ![STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED, STATUS_FIELD.STATUS_CANCEL_SUBMMITED, STATUS_FIELD.STATUS_SUBMIT].includes(this.dataWorkflow.status)
    },
    isViewProduct() {
      return this.$route.query?.type === 'view'
    },
    isViewProductFullMode() {
      return this.$route.query?.type === 'view' && !this.isFullScreen;
    }
  },
  methods: {
    ...mapActions("commonApp", ["loadMoreAction", "actionUpdateFilter"]),
    ...mapActions("actionsTable", ["actionStartUpdate"]),
    ...mapActions("actionsTable", ["actionUpdateDataAction"]),
    ...mapActions("actionsTable", ["actionUpdate", "actionUpdateStatusBtn", "updateActionDelete"]),
    ...mapActions("registerData", ["actionUpdateIsFullScreen", "actionRemoveListOfEditedCells"]),

    checkShowOptionService() {
      getAllService().then((res) => {
        this.isShowMethodService = !!res.data.length;
      });
    },

    formatNumber(num) {
      if(num === 0) {
        return '---'
      }
      return addThoundSandCommaWithTwoDigit(formatBigNumber(num, 35).replace(/[^0-9\.\-]+/g, ''), '', false, true);
    },
    getTootipContent(item) {
      if(!item?.updated_at || !item?.updated_by) return ''
      let updatedAt = moment.utc(item.updatedAt).add(9, 'hours').format('YYYY/MM/DD HH:mm:ss');
      if(this.isCheckLang === 'vi') {
        return `${this.$t("register_data.description_modified_by")} ${this.$t("register_data.description_administrator")} ${item.updated_by}\n` + updatedAt
      } else {
        return item.updated_by + ` ${this.$t("register_data.description_modified_by")} \n` + updatedAt
      }
    },
    onNeedCompressed(needCompress){
      this.needCompress= needCompress;
    },
    getWidthByTextContent(string, isHeader = false){
      let padding = 23
      let text = document.createElement("span");
      document.body.appendChild(text);
      text.style.width = 'auto';
      text.style.position = 'absolute';
      text.style.whiteSpace = 'no-wrap';
      text.style.font = "Source Han Sans";
      text.style.fontSize = isHeader ? 13 : 14 + "px";
      if(isHeader) {
        text.style.fontWeight = "500";
      }
      text.innerHTML = string;
      const width = text.clientWidth;
      document.body.removeChild(text);
      return width + padding;
    },
    getWidthAllActionTable() {
      let topSide = document.getElementsByClassName("top-side")[0].offsetWidth + 18;
      let commentSide = document.getElementsByClassName("comment-side")[0]?.offsetWidth || 0;
      let bottomSide = document.getElementsByClassName("bottom-side")[0]?.offsetWidth || 0;
      let totalWidth = topSide + 8 + 8 + commentSide + 40 + bottomSide;
      if(window.innerWidth - totalWidth < 337) {
        return true;
      }
    },
    hideHeaderRegisterData() {
      return this.$route.name !== "ProductsNewRegisterData";
    },
    async onHandleShowCommentHistory() {
      if(Object.keys(this.dataWorkflow).length) {
        const payload = {
          id: this.dataWorkflow.id
        }
      const commentList = await getProductListCommentHistory(payload);
      this.commentHistoryList = commentList.data.map(commentHistory => {
          return {
            ...commentHistory,
            date: formatDateTimeDataTable(commentHistory.created_at),
            name: commentHistory.user.name,
            content: commentHistory.content,
          }
        })
      }
      this.commentPopup  = true;
    },
    onHandleActionTable(action, checkHasUserAccessTemplateDetail) {
      const product = {
        productName: this.productName,
        productCode: this.productCode,
      }
      this.$emit("onHandleActionTable", action, product, checkHasUserAccessTemplateDetail);
    },
    onHandleShowExportPopup(value) {
      this.$emit('onHandleShowExportPopup', value);
    },
    loadedRows: function(theGrid) {
      return
      const self = this;
      setTimeout(() => {
        self.resetAutoSizingGrid(theGrid);
      }, 200)
    },
    resetAutoSizingGrid(theGrid) {
      return
      let listColumn = []
      this.gridProductOrganizationColumns?.forEach(column => {
        if(column?.columns) {
          column.columns.forEach(subColumn => {
            listColumn.push(subColumn)
          })
        } else {
          listColumn.push(column)
        }
      })
      listColumn.forEach((col, index) => {
        if (!(col?.width && col?.width === "*")) {
          theGrid.autoSizeColumn(index)
        }
      })
      
      theGrid.autoSizeRows(0, 0, true);
      theGrid.columnHeaders.rows.minSize = 34;
    },
    autoSizeColumn: function(theGrid) {
      return
      let self = this;
      setTimeout(() => {
        self.resetAutoSizingGrid(theGrid);
      }, 50)
    },
    changeSelectionCell() {
      this.$emit("onChangeSelected");
    },

    onFilterApplied(s, _) {
      let isFiltering = !s._filters.every((column) => !column.isActive);
      this.actionUpdateFilter(isFiltering);
      if (this.isClickFilter) {
        this.theGrid.scrollIntoView(0, -1); // scroll to first row
      }
    },

    setRowIndex(s) {
      s.itemFormatter = (panel, r, c, cell) => {
        if (panel.cellType === wjcGrid.CellType.RowHeader && !this.isFilter) {
          cell.textContent = (r + 1).toString();
          cell.draggable = true;
        }
        if (panel.cellType === wjcGrid.CellType.RowHeader && this.isFilter) {
          cell.style.justifyContent = "center";
          cell.textContent = (r + 1).toString();
        }
      };
    },

    onFilterChanged() {
      if (this.theGrid?.collectionView !== null) {
        this.isClickFilter = true;
      }
    },

    handlerCalcHeight() {
      this.isOverText = this.getWidthAllActionTable();
      const element = document.getElementById('grid-group');
      this.heightToolBar = element.offsetHeight - 5; // 5 is top css
    },
    handleSelectMethod(method) {
      this.$emit('openSelectEmissionRegister', method.value)
      this.isShowMethodSelect = !this.isShowMethodSelect
    },
    handleUpdateProductName(newName){
      this.productName = newName
    },
    handleUpdateProductCode(newProduct){
      this.productCode = newProduct
      this.$emit('updateProductCode', newProduct)
    },
    handleSelectProduct(newVal) {
      const product = this.listProductMaster.find(item => item.name === newVal)
      this.productCode = product.code
      this.productName = product.name
      this.$emit('handleSelectProduct', product.id)
      //TODO: call api get product detail
    },
    handleTypeChange(newType) {
      this.productName = ''
      this.productCode = ''

      this.$emit('updateIsRegisterNew', newType === PRODUCT_INPUT_TYPE.CREATE_NEW)
    },
    handleNextTable() {
      this.theProductInformationGrid.selection = new wjcGrid.CellRange(0, 0);
      this.theProductInformationGrid.startEditing(true, 0, 0);
      this.scrollTo(0);
    },
    handleTableClick() {
      if(this.isShowMethodSelect) {
        this.isShowMethodSelect = false
      }
    },
    handleOutsideClick() {
      this.handleTableClick()
    },
    editingFilter(s, e) {
      console.log(e.getColumn().binding);
      this.$emit('onHandlerSelecteFilterColumn', e.getColumn().binding);
      const formatItems = ['activity_amount', 'wsu_value', 'emissions'];
      if (!formatItems.includes(e.getColumn().binding)) return;
      let vals = [];
      let valueFilter = s.getColumnFilter(e.getColumn().binding, true).valueFilter;
      valueFilter.uniqueValues = null;
      valueFilter.getUniqueValues().forEach(item => {
        if ($_helper_isNumberType(item.text)) {
              vals.push(item.text);
          }
      });
      vals.sort((a, b) => {
        const numA = Number(a.replace(/,/g, ''));
        const numB = Number(b.replace(/,/g, ''));
        return numA - numB;
      })
      valueFilter.uniqueValues = vals;
      valueFilter.sortValues = false;
    },
  },
  beforeDestroy() {
    this.actionUpdateIsFullScreen(false);
    window.removeEventListener('resize', this.handlerCalcHeight)
    window.removeEventListener('click', this.handleOutsideClick);
  },
};
</script>

<style lang="scss">
$table: 1024px;

.fullmode-duration {
  display: flex;
  //TODO
  // justify-content: space-between;
  gap: 8px;
  align-items: center;

  .durations-pulldown-table {
    display: flex;
    flex-direction: row;
    .duration-header {
        height: 28px;
    }
    height: 28px;
    .duration-text {
      width: 79px;
      min-width: 86px;
    }
    .durations-select-item {
      min-width: 86px;
      max-width: 86px;
      &.fullscreen-month-select {
        min-width: 58px;
      .v-input__control .v-input__slot {
        min-width: 58px;
        max-width: fit-content;
        .v-select__slot {
          justify-content: end;
        .v-select__selections {
          flex: unset;
          min-width: 18px;
          .v-select__selection.v-select__selection--comma {
            margin: 0;
            overflow: visible;
          }
          input {
            display: none;
          }
        }
        .v-input__append-inner {
          margin: 0;
        }
      }
      }
      &.select-item.v-input--is-focused .v-input__control .v-input__slot {
        background: $monoOffWhite !important;
      }
    }
      max-width: 79px;
      &.new-register {
        height: 28px;
      }
    }
    .durations-select-item {
      &.new-register {
        height: 28px;
      }
    }
    align-items: center;
    padding: 0px;
    gap: 8px;

    .durations-label {
      font-family: $mainFont;
      color: $monoBlack;

      /* Labels/Small */
      font-size: 11px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px; /* 163.636% */
      letter-spacing: 0.33px;
    }

    .data-is-registered::after {
      content: '';
      width: 8px;
      height: 8px;
      background: #bfd4d9;
      border-radius: 50%;
      position: relative;
      display: inline-block;
    }

    .duration-text {
      &.disabled {
        border: 1px solid rgba(42, 42, 48, 0.1);
      }
      border-radius: 4px;
      // width: 100%;
      //
      height: 28px;
      display: flex;
      align-items: center;

      span {
        padding: 8px 16px;
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        /* identical to box height, or 164% */

        letter-spacing: 0.03em;

        /* Mono/Black */

        color: #404D50;
        &.item-selected {
          color: $red;
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}

.table-header-custome-pulldown {
  min-width: 355px;
}
.select-item .v-input__slot {
  height: 28px !important;
}
.data-is-registered {
  &::after {
    content: '';
    width: 8px;
    height: 8px;
    background: #bfd4d9;
    border-radius: 50%;
    position: relative;
    display: inline-block;
  }
}
/* Tooltip */
.tooltip-custom {
  width: 18px;
  height: 18px;
  background: rgba(121, 134, 134, 0.12) !important;
  border-radius: 50%;
  text-align: center;
  margin-left: 6px !important;

  &:hover {
    background: $goldMid !important;
    color: #fff;
    cursor: pointer;
  }
}

.edit-layer-name,
.switch-layer {
  &:hover {
    color: #fff;
    cursor: pointer;
  }
}
.icon-right {
  margin-left: auto;
  padding-top: 3px;
}

.edit-layer-name {
  width: 20px !important;
  height: 20px !important;
  margin-right: 3px !important;
}
.switch-layer {
  width: 27px !important;
  height: 16px !important;
}


#s3c11 {
  position: absolute;
  top: -25px;
  left: 210px;
}

#s3c10 {
  position: absolute;
  top: -25px;
  left: 220px;
}

.wj-cell.wj-header.wj-header-alt.wj-wrap.wj-filter-off {
  border-top: 1px solid rgba(42, 42, 48, 0.1) !important;
}

.wj-clipboard {
  top: -150% !important;
}

#category-table {
  padding-bottom: -10px !important;
  &:not(.full-screen) {
    position: relative;
    @include tablet {
      z-index: 97;
    }
  }
  box-shadow: 0px 1.5773361921310425px 1.8540618419647217px 0px rgba(160, 181, 186, 0.37), 
      0px 3.790558099746704px 4.455568313598633px 0px rgba(160, 181, 186, 0.27), 
      0px 7.137284755706787px 8.389439582824707px 0px rgba(160, 181, 186, 0.22), 
      0px 12.731696128845215px 14.965327262878418px 0px rgba(160, 181, 186, 0.15), 
      0px 23.8132381439209px 27.990997314453125px 0px rgba(160, 181, 186, 0.10);
  margin-bottom: 208px;
}

.override-btn {
  bottom: 40px;
  right: 40px;

  @media (max-width: $tablet) {
    bottom: 151px;
    right: 37px;
  }

  @media (max-width: $desktop) {
    bottom: 86px;
    right: 20px;
  }
  @media (max-width: 450px) {
    bottom: 150px !important;
  }
}

#btn-focus {
  right: 40px;
}
@media (max-width: 480px) {
  #btn-focus {
    right: 20px;
  }
}
.loadClass {
  border-bottom: 1px solid rgba(0, 0, 0, 0.2) !important;

  span {
    display: none !important;
  }
}

:root {
  --width-table: 1366px;
}

.wj-listbox {
  overflow: auto;

  &::-webkit-scrollbar {
    height: 8px !important;
    background: #f7f7f2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bfd4d9;
    border-radius: 6px;
    z-index: 1000;
    border-left: 4px solid #f7f7f2;
    border-right: 4px solid #f7f7f2;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #bfd4d9;
  }
}

.wj-state-selected {
  background: none !important;
}

.wj-listbox[wj-part='dropdown'] {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: $monoBlack;
  max-height: 323px !important;

  &::-webkit-scrollbar {
    height: 8px !important;
    background: #f7f7f2;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #bfd4d9;
    border-radius: 6px;
    z-index: 1000;
    border-left: 4px solid #f7f7f2;
    border-right: 4px solid #f7f7f2;
    min-height: 50px !important;
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #bfd4d9;
  }

  background: $monoOffWhite !important;

  .wj-state-selected {
    //background: $bgLight !important;
    color: $monoBlack !important;
    display: flex;
    align-items: center;
    &::after {
      content: '';
      background-image: url('../../assets/icons/pulldown/selected-icon.svg');
      background-size: 9px 6px;
      width: 9px;
      height: 6px;
      margin-left: 9.5px;
    }
  }

  .wj-listbox-item {
    max-width: 100% !important;
    border-top: 1px solid $dividerBlack;
    border-right: 1px solid $dividerBlack;
    min-height: 40px !important;
  }
}
.drop-down-custom {
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
  0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
  0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;

  .wj-listbox-item {
    border-top: 1px solid $dividerBlack !important;
    border-right: 1px solid $dividerBlack !important;
  }

  .wj-state-selected,
  .wj-state-last-selected {
    //background: #e3eded !important;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: #404d50;
    display: flex;
    align-items: center;
    &::after {
      content: '';
      background-image: url('../../assets/icons/pulldown/selected-icon.svg');
      background-size: 9px 6px;
      width: 9px;
      height: 6px;
      margin-left: 9.5px;
    }
  }
}
.create-data-table {
  .wj-rowheaders {
    .wj-cell.wj-header  {
      display: flex;
      justify-content: center;
      border-top: none !important;

      &.wj-state-multi-selected {
        // border-right: 2px solid $blueMid !important;
        background: $blueMid !important;
        color: #fff !important;
        // pointer-events: none;
      }

      &::before {
        content: none !important;
      }
    }
  }
 
}
.category-table {
  width: calc(100% + 40px);
  box-shadow: 0px 23.8132px 27.991px rgba(160, 181, 186, 0.1), 0px 12.7317px 14.9653px rgba(160, 181, 186, 0.15), 0px 7.13728px 8.38944px rgba(160, 181, 186, 0.22056), 0px 3.79056px 4.45557px rgba(160, 181, 186, 0.265976), 0px 1.57734px 1.85406px rgba(160, 181, 186, 0.37);
  #load {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $goldMid;
    cursor: pointer;
    height: 100%;
    margin-top: -5px !important;
    z-index: 5;

    &:hover {
      color: $goldLight;
    }
  }

  ::-webkit-scrollbar {
    height: 15px;
    background: #e3eded;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #bfd4d9;
    border-radius: 8px;
    z-index: 1000;
    border: 3px solid #e3eded;
    border-right: 4px solid #e3eded;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #bfd4d9;
  }

  ::-webkit-scrollbar-corner {
    background: $bgLight !important;
  }

  ::-webkit-scrollbar-button:horizontal:start:decrement {
    border-top: 1px solid $dividerBlack !important;
    width: 20px;
    display: block;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAICAYAAAA1BOUGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACESURBVHgBbY0hDoNAEEXnD00xreoFUL1AVRUkcCkcDkW4B0dAYzkCARQKiyDsLgvJEiA7ZpL/X94HWS5Jc4/k8sIlLIrn0nQBmHVJNc60ZOErIncPdPkwtGDh3fU8ta0mMdu2D22cZV8W6seK3kbrmLIqy/Efhb0j4QL4kKIBNt1m2f4Klm0sPajfNPkAAAAASUVORK5CYII=');

    &:hover {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgNiA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNiAwTDAgNEw2IDhWMFoiIGZpbGw9IiM3Qzg5OEMiLz4KPC9zdmc+Cg==');
    }
  }

  ::-webkit-scrollbar-button:horizontal:start:increment {
    border-top: 1px solid $dividerBlack !important;
    width: 20px;
    display: block;
    border-left: 1px solid $dividerBlack;
    border-right: 1px solid $dividerBlack;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAICAYAAAA1BOUGAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAACDSURBVHgBbY3NCYRADIUzY5Bl/wrYAqzDBrYVYWGPHjypIJ7EZrx5twgb8CKIDJJEPIzMgB8EAnn5HmR5HR0DF2hS8qJA4rSo4qxtn97RLkqriJf161rQTYqAtXzwfR80XCLhRLR5nwxgKAz68vcfPa0wj/Pj1jdJYs5OQjGM2Nm0yw7wKzFL5K8QXQAAAABJRU5ErkJggg==');

    &:hover {
      background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNiIgaGVpZ2h0PSI4IiB2aWV3Qm94PSIwIDAgNiA4IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8cGF0aCBkPSJNNC43NjgzN2UtMDcgOEw2IDRMMS4xMzI1MWUtMDYgLTguMjY2ODZlLTA4TDQuNzY4MzdlLTA3IDhaIiBmaWxsPSIjN0M4OThDIi8+Cjwvc3ZnPgo=');
    }
  }

  /* Apply for checkbox & button */
  .wj-align-center {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .wj-cell-check {
    margin: unset !important;
  }

  .wj-header.wj-colgroup.wj-align-center {
    border-bottom: none !important;

    &::before {
      content: none !important;
    }
  }

  .wi-cell {
    &.wj-header {
      &.emission {
        background: $redExtraLight !important;
        color: $goldMid !important;
      }
    }
  }

  .wj-header.wj-colgroup {
    &.emission {
      background: $redExtraLight !important;
      color: $goldMid !important;
    }

    &::before {
      content: none !important;
    }
  }

  .wj-header.wj-align-right {
    &.emission {
      background: $redExtraLight !important;
      color: $goldMid !important;
    }
  }

  .wj-topleft {
    .wj-cell.wj-header {
      &::before {
        content: none;
      }

      &:hover {
        &::before {
          content: none;
        }
      }

      &.wj-header-alt {
        border-top: 1px solid rgba(42, 42, 48, 0.1) !important;
      }
    }
  }

  .wj-cell.wj-header {
    background-color: $bgLight !important;
    color: #7c898c !important;
    font-weight: 500 !important;
    font-size: 13px;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-end;
    border-left: none !important;
    border-top: 1px solid $dividerBlack !important;
    overflow: unset;

    &.no-tooltip {
      &::before {
        content: unset;
      }
    }

    .wj-elem-filter {
      margin-left: -4px !important;
      margin-right: 3px !important;
    }

    &.wj-colgroup.wj-filter-on {
      height: 68px !important;
      padding: 0 8px 8px !important;

      .wj-glyph-filter {
        background-image: url('../../assets/icons/filterOn.svg');
      }
    }
  }

  .wj-cell.wj-header:not(.single-row) {
    align-items: end !important;
  }

  .single-row {
    align-items: center !important;
  }

  .wj-cell.wj-header[draggable] {
    display: flex;
    justify-content: center;
    border-top: none !important;

    &.wj-state-multi-selected {
      border-right: 2px solid $blueMid !important;
      // background: $blueMid !important;
      // color: #fff !important;
      // pointer-events: none;
    }

    &::before {
      content: none !important;
    }
  }

  #temp-table {
    overflow: hidden;
    border: none !important;    
  }
  &.product-table {
    #temp-table {
      margin-top: 5px;
    }
  }

  .wrap-table.wj-flexgrid [wj-part='root'] {
    overflow-y: scroll !important;
    max-height: 465px !important;
    &::-webkit-scrollbar:vertical { 
        width: 15px;
    }
    &::-webkit-scrollbar-track:vertical { 
      border-top: 1px solid rgba(42, 42, 48, 0.1);
      border-left: 1px solid rgba(42, 42, 48, 0.1);
    }
    &::-webkit-scrollbar-track:horizontal { 
      border-top: 1px solid rgba(42, 42, 48, 0.1);
    }
    &::-webkit-scrollbar-thumb:horizontal {
      border-radius: 8px;
      border-top: 4px solid !important;
      border-image: linear-gradient(to bottom, rgba(42, 42, 48, 0.1), #e3eded 2px) 10 0 round !important;
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
    &::-webkit-scrollbar-thumb:vertical {
      border-left: 4px solid !important;
      border-image: linear-gradient(to bottom, rgba(42, 42, 48, 0.1), #e3eded 2px) 6 0 round !important;
      background-origin: border-box;
      background-clip: content-box, border-box;
    }
  }

  .wrap-table {
    border: none !important;
    border-radius: 0 !important;
    background: transparent;
    position: relative;
    overflow: hidden;
    max-height: 465px !important;

    &.process-table {
      max-height: 433px !important;
      .wrap-table.wj-flexgrid [wj-part='root'] {
        max-height: 433px !important;
      }
    }

    .wj-glyph-filter {
      background-image: url('../../assets/icons/filterDefault.svg');
      background-position: center;
      margin-top: 2px;
      width: 20px !important;
      height: 20px !important;
      border: none !important;

      &:after {
        display: none;
      }

      &:hover {
        background-image: url('../../assets/icons/filterHover.svg');
      }
    }

    .wj-filter-on {
      .wj-glyph-filter {
        // transform: scale(1.25);
        background-image: url('../../assets/icons/filterOn.svg');
      }
    }
  }

  .custom-colors {
    .wj-glyph-filter {
      color: red;
      font-size: 125%;
    }
  }

  #search-input {
    display: none;
    margin: 15px 0;
    position: relative;

    .search-btn {
      position: absolute;
      right: 0;
      height: 100%;
      z-index: 99;
      background: transparent;
    }

    .wj-input-group-btn {
      display: none !important;
    }
  }

  .wj-cell.wj-header.wj-state-invalid {
    background: $redExtraLight !important;
  }

  .wj-rowheaders {
    .wj-row {
      .wj-cell {
        &.wj-header {
          border-top: 1px solid rgba(42, 42, 48, 0.1) !important;
        }
      }
    }
  }
}

.wj-flexgrid {


  .wj-colheaders {
    .wj-cell {
      padding: 5px 8px !important;
    }
  }

  .wj-glyph-down {
    border-top: 10px solid $monoDark;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }

  input[type='checkbox'] {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    width: 16px !important;
    height: 16px !important;
    background: #ffffff;
    border: 0.5px solid rgba(0, 0, 0, 0.25);
    box-shadow: inset 0px 0px 4px 2px rgba(0, 0, 0, 0.08);
    border-radius: 4px;
  }

  input[type='checkbox']:checked {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    box-shadow: none;
    text-align: center;
    width: 16px !important;
    height: 16px !important;
    background: $blueMid;
    border-radius: 4px;
  }

  input[type='checkbox']:checked:after {
    content: url('../../assets/icons/checkedbox.svg');
    color: white;
  }

  .wj-state-invalid[aria-required] {
    /* border: none !important; */
    color: $redMid !important;
  }

  .wj-state-invalid {
    border: none !important;
    border-right: 1px solid rgba(0, 0, 0, 0.2) !important;
  }

  .wj-marker {
    width: 1000px !important;
    background-color: rgb(1, 255, 65) !important;
  }

  .wj-cell {
    background: $monoOffWhite !important;
    color: $monoBlack !important;
    font-size: 14px;
    border-right: 1px solid $dividerBlack !important;
    border-bottom: 1px solid $dividerBlack !important;
    align-items: center;

    &.cell-disable {
      background: #eef0f0 !important;
      color: #99a6a9 !important;
    }

    // white-space: pre-wrap;
  }

  .wj-cell-maker {
    display: flex;
    max-height: 23px;
    justify-content: center;
    background: $monoLight;
    border-radius: 4px;
    padding: 1px 6px 3px;
    text-align: center;
    font-size: 13px;
    font-family: 'Source Han Sans';
    font-style: normal;
    font-weight: 500;
    line-height: 20px;

    cursor: pointer;

    button {
      text-align: center;
    }

    &:hover {
      color: $monoWhite;
      background: #0072a3;
    }
  }
  .wj-cell[aria-readonly]:not(.wj-state-multi-selected) {
    background: $monoOffWhite;
    color: $monoMid !important;
  }
  .wj-cell[aria-readonly] {
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 24px;
    border-right: unset !important;
    border-bottom: unset !important;
    align-items: center;
    gap: 6px;
    padding: 5px 8px 5px !important;
    border-right: 1px solid $dividerBlack !important;
    border-bottom: 1px solid $dividerBlack !important;
    &.wj-state-multi-selected {
      color:$monoMid !important;
    }
    &.btn-db {
      display: flex;
      justify-items: center;
      align-items: flex-start;
      color: $monoBlack !important;
      padding: 4px !important;
      &:not(.wj-state-multi-selected) {
        background: $monoOffWhite !important;
      }
    }

    &.btn-disabled {
      color: $monoMid !important;
      pointer-events: none;
    }

    &.loadClass {
      background: #f7f7f2 !important;
    }
    &.wj-state-active:not(.wj-state-multi-selected,.emission) {
      background: $monoOffWhite !important;
    }
    &.wj-state-active {
      border: unset !important;
      border-right: 1px solid $dividerBlack !important;
      border-bottom: 1px solid $dividerBlack !important;

      &::after {
        background: unset;
      }
    }
    &.wj-has-changed:not(.wj-state-multi-selected) {
      background: $monoOffWhite !important;
    }
    &.wj-has-changed {
      color: $goldHeavy !important;
      &.wj-state-multi-selected {
        background-color: $blueExtraLight !important;
      }
    }

    &.casbee-detail {
      background: $monoOffWhite !important;
      .wj-cell-maker {
        .casbee-btn_custom {
          width: 100%;
          color: $goldMid;
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
        }
        &:hover {
          background: #0072A3;
          border: unset;
          .casbee-btn_custom {
            color: $monoWhite;
          }
        }
      }
    }
  }

  .wj-cells .wj-state-active {
    background: $monoOffWhite !important;
    color: #000;
    border: 1px solid $blueMid;
    // bug 5871
    &.auto-increment {
      // background: #eef0f0 !important;
      // border: unset !important;
      // outline: unset !important;
      // border-right: 1px solid rgba(42, 42, 48, 0.1) !important;
      // border-bottom: 1px solid rgba(42, 42, 48, 0.1) !important;
    }
  }

  .custom-btn {
    padding: 2px 10px;
    background: #e4e6e7;
    border-radius: 4px;
  }
  .wj-cell:not(.wj-has-changed, .wj-duplicated-data,.wj-state-multi-selected, .wj-header) {
    &.auto-increment {
      background: $monoOffWhite !important;
    }
    &.emission {
      background: $redUltraLight !important;
    }
  }
  .wj-cell:not(.wj-has-changed, .wj-duplicated-data) {
    padding: 5px 8px !important;
    &.empty {
      .wj-btn {
        display: none !important;
      }
    }

    &.auto-increment {
      color: $monoMid !important;
    }

    &.emission {
      color: $goldMid !important;
    }

    &.wj-header {
      &.emission {
        background: $redExtraLight !important;
        color: $goldMid !important;
      }
    }
  }
  .wj-cell.wj-has-changed {
    padding: 5px 8px !important;

    &.empty {
      .wj-btn {
        display: none !important;
      }
    }
    &.auto-increment:not(.wj-state-multi-selected) {
      background: $monoOffWhite !important;
    }
    &.auto-increment {
      color: $goldHeavy !important;
    }

    &.emission {
      background: $redUltraLight !important;
      color: $goldMid !important;
    }

    &.wj-header {
      &.emission {
        background: $redExtraLight !important;
        color: $goldMid !important;
      }
    }
  }
}

.facility-table {
  .wj-colheaders {
    .wj-cell {
      padding: 0 8px !important;
    }
  }
}

.center-table {
  .wj-colheaders {
    .wj-cell {
      padding: 0 8px !important;
    }
  }
}

.wj-flexgrid {
  overflow: invisible !important;

  .wj-colheaders .wj-header.wj-state-multi-selected {
    box-sizing: padding-box !important;
    // border-bottom: 2px solid $blueMid !important;
    &::after {
      content: " ";
      border-bottom: 2px solid $blueMid !important;
      height: 2px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .wj-cells .wj-cell .wj-state-active {
    background: transparent;
    color: #000;
    border: 2px solid rgb(15, 228, 69);

    &::after {
      content: '';
      width: 5px;
      height: 5px;
      background: rgb(7, 115, 204);
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }

  .wj-colheaders {
    .wj-row {
      .wj-cell {
        &.wj-header {
          // border-bottom: unset !important;
        }
      }
    }
  }

  // style for table readonly
  &.wj-state-readonly {
    .wj-colheaders .wj-header.wj-state-multi-selected {
      border-bottom: 1px solid rgba(42, 42, 48, 0.1) !important;
    }
  }
}

.table-toolbar {
  display: flex;
  flex-direction: column;
  padding: 8px 20px;
  background: #e3eded;
  margin-bottom: -5px;
  justify-content: space-between;
  gap: 8px;

  .button-action-table-first {
    display: flex;
  }

  .toolbar-fullscreen {
    .fullscreen-item {
      display: flex;
      gap: 4px;
      margin-right: 10px;

      .fullscreen-text {
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: #404d50;
      }

      .fullscreen-switch-btn {
        height: 16px;

        .v-input__control {
          height: 16px;

          .v-input--selection-controls__input {
            height: 20px !important;
            margin-right: 0 !important;
            width: 27px !important;

            input {
              height: 16px !important;
            }

            .v-input--switch__track {
              width: 27px !important;
              background: #7C898C !important;
            }

            .v-input--switch__thumb {
              width: 16px !important;
              height: 16px !important;
              margin-top: 2px !important;
              box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24) !important;
            }
            
            &:hover {
              .v-input--selection-controls__ripple:before {
                background: $goldMid;
              }
            }
          }
        }

        &.v-input--is-label-active {
          .v-input__control {
            .v-input--selection-controls__input {
              .v-input--switch__track {
                background: $blueMid !important;
              }

              .v-input--switch__thumb {
                margin-left: -9px !important;
              }
              
              &:hover {
                .v-input--selection-controls__ripple:before {
                  background: $blueMid;
                }
              }
            }
          }
        }
      }

      &:first-child {
        padding: 4px 6px 4px 4px;
        background: #f7f7f2;
        border: 1px solid rgba(42, 42, 48, 0.1);
        border-radius: 4px;
        max-width: 132px;
      }
    }
  }

  .toolbar-undo {
    // width: calc(100% - 140px);
    display: flex;
    align-items: center;
  }
}

.table-header-custome {
  height: 44px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  gap: 16px;
  background: #6B838F;
  // margin-bottom: -5px;
  &.no-mb {
    margin-bottom: 0;
  }
  &-name {
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
    color: $monoWhite;
    .require-item {
      margin-left: 8px;
      display: inline-block;
      padding: 0px 4px 2px;

      // width: 31px;
      height: 20px;
      background: $monoWhite;
      border-radius: 4px;
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      text-align: center;
      letter-spacing: 0.03em;
      color: $goldMid;
    }
  }
  &-select {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0px 0px 0px 10px;
    // width: 107px;
    height: 28px;
    background: $monoOffWhite;
    border-radius: 4px;
    border: 1px solid rgba(42, 42, 48, 0.1);
    cursor: pointer;
    // position: relative;
    &.focus-button {
      border: 2px solid $blueMid;
    }
    &--label {
      font-weight: 700;
      font-size: 11px;
      line-height: 18px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-left: 6px;
      width: 51px;
      height: 28px;
      min-width: max-content;
    }
    &--pulldown {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px;
      width: 30px;
      height: 28px;
      &.open-pulldown {
        transform: rotate(180deg);
      }
    }

    &.disable {
      background: $monoLight;
      cursor: unset;
      pointer-events: none;
      .table-header-custome-select--label {
        color: $monoMid;
      }
    }
    &:hover {
      background-color: $monoWhite;
    }
  }
  &-pulldown {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    right: 0;
    width: fit-content;
    margin-top: 112px;
    margin-right: 20px;
    // height: 80px;
    background: #F7F7F2;
    box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);    border-radius: 4px;    border-radius: 4px;
    z-index: 100;
    &__item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 7px 16px 9px;
      gap: 4px;
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      cursor: pointer;
      &:hover {
        background: $bgLight;
      }
      &:first-child {
        &:hover {
          border-top-left-radius: 4px ;
          border-top-right-radius: 4px;
        }
      }
      &:last-child {
        &:hover {
          border-bottom-left-radius: 4px ;
          border-bottom-right-radius: 4px;
        }
      }
    }
  }
  &.minus-top:not(.empty-process-fullscreen) {
    margin-top: -5px;
  }
}

.table-header-input {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px;
  gap: 16px;
  width: 100%;
  background: $bgLight;
  margin-bottom: -5px;
  .table-name-input {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    height: 72px;
    width: 100%;
    &_label {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      display: flex;
      flex-direction: row;
      align-items: center;
      &--require {
        margin-left: 12px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0px 4px 2px;
        // width: 31px;
        height: 20px;
        border-radius: 4px;
        background: $goldMid;
        font-weight: 500;
        font-size: 11px;
        line-height: 18px;
        letter-spacing: 0.03em;
        color: $monoWhite;
      }
    }
    &_select {
      width: 100%;
    }
  }
  .table-type-input {
    width: 100%;
    .v-input__control {
      .v-input__slot .v-radio {
        .v-input--selection-controls__input {
          margin-top: 0px !important;
        }
      }
    }  
  }
}

.empty-process {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  width: 100%;
  height: 148px;
  background: $bgLight;
  margin-top: -7px;
  border-top: 1px solid rgba(42, 42, 48, 0.1);
  position: relative;
  box-shadow: 0px 23.8132px 27.991px rgba(160, 181, 186, 0.1), 0px 12.7317px 14.9653px rgba(160, 181, 186, 0.15), 0px 7.13728px 8.38944px rgba(160, 181, 186, 0.22056), 0px 3.79056px 4.45557px rgba(160, 181, 186, 0.265976), 0px 1.57734px 1.85406px rgba(160, 181, 186, 0.37);
  margin-top: 0;

  &-number {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 40px;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: $monoDark;
    height: 100%;
    border-right: 1px solid rgba(42, 42, 48, 0.1);;
  }
  .content {
    width: 100%;
    height: 148px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px 20px;
    gap: 16px;

    .label {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoDark;
    }
  }
}

.box-shadow {
  width: 100%;
  height: 2px;
  background: $bgLight;
  box-shadow: 0px 23.8132px 27.991px rgba(160, 181, 186, 0.1), 0px 12.7317px 14.9653px rgba(160, 181, 186, 0.15), 0px 7.13728px 8.38944px rgba(160, 181, 186, 0.22056), 0px 3.79056px 4.45557px rgba(160, 181, 186, 0.265976), 0px 1.57734px 1.85406px rgba(160, 181, 186, 0.37);
}

.action-table {
  position: absolute;
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 20px;
  gap: 16px;
  width: 100%;
  background: $bgMid;
  height: 128px;
  box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
  .common-btn {
    width: 100%;
    .icon-back_active {
      display: none;
    }
    &:hover {
      .icon-back {
        display: none;
      }
      .icon-back_active {
        display: block;
      }
    }
  }
}

.product-table {
  .wrap-table {
    .wj-cell {
      &.hide-filter {
        &.wj-header {
          .wj-btn-glyph {
            display: none;
          }
        }
      }
    }
  }
}

#_dropdown {
    z-index: 98 !important;
}

.wj-dropdown:not(.wj-state-collapsed) {
  .wj-btn {
    border: none !important;
    &:hover {
      background: none !important;
    }
  }
  .wj-glyph-down {
    rotate: 180deg; 
  }
}

.wj-dropdown-panel {
  box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 
    0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 
    0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 
    0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 
    0px 36px 33px 0px rgba(160, 181, 186, 0.07);
  margin-top: 4px;  
  .wj-listbox-item {
    cursor: pointer;
    padding: 7px 16px 9px !important;
    font-weight: 500;
    &:hover {
      background: #e3eded !important;
    }
  }
}

.wj-dropdown-panel.wj-control.wj-content.wj-columnfiltereditor.wj-state-empty.wj-state-focused {
    z-index: 98 !important;
}
.v-dialog__content{
  .wj-dropdown-panel.wj-control.wj-content.wj-columnfiltereditor.wj-state-empty.wj-state-focused {
    z-index: 1500 !important;
  }
}
#theProductInformationGrid {
  .wj-colheaders {
    .wj-row {
      &:last-child {
        .wj-header {
          .tooltip-custom {
            display: none;
          }
        }
      }
    }
  }
}
#theProductOrganizationGrid, #theProductInformationGrid {
  margin-top: -5px;
  margin-bottom: -5px;
  .wj-rowheaders {
    .wj-header {
      .wj-glyph-pencil {
        display: none;
      }
    }
  }
}
#theProductProcessGrid {
  max-height: 400px !important;
  margin-top: -5px;
}
#theProductOrganizationGrid, #theProductInformationGrid, #theProductProcessGrid {
  margin-top: -5px;
  margin-top: 0;
  margin-bottom: 0px;
}
#theProductOrganizationGrid {
  margin-top: 0;
  margin-top: 5px;
}
.full-screen {
  #theProductOrganizationGrid, #theProductInformationGrid, #theProductProcessGrid {
    margin-top: 0px;
    //margin-bottom: -5px;
  }
  #theProductOrganizationGrid {
    margin-top: 5px;
  }
}
.wj-columnfiltereditor {
  .wj-listbox {
    .wj-listbox-item {
      max-width: 100% !important;
      width: 100% !important;
    } 
  }
}
@media (max-width: 1023px) {
  .category-table {
    margin-left: -20px !important;
    overflow: hidden;
    box-shadow: 0px 1.5773361921310425px 1.8540618419647217px 0px rgba(160, 181, 186, 0.37), 
      0px 3.790558099746704px 4.455568313598633px 0px rgba(160, 181, 186, 0.27), 
      0px 7.137284755706787px 8.389439582824707px 0px rgba(160, 181, 186, 0.22), 
      0px 12.731696128845215px 14.965327262878418px 0px rgba(160, 181, 186, 0.15), 
      0px 23.8132381439209px 27.990997314453125px 0px rgba(160, 181, 186, 0.10);
  }
}

@include desktop {
  #category-table {
    margin-bottom: 0;
  }

  .action-table {
    position: relative;
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 32px;
    gap: 8px;
    height: 56px;
    width: 100%;
    background: $bgMid;
    margin-bottom: -80px;
    .common-btn {
      width: 350px;
      &.back-btn {
        display: none;
      }
    }
  }
  .table-toolbar {
    flex-direction: row;
    padding: 8px 40px;
    height: 44px;
  }
  .category-table {
    width: 100%;
  }
  .table-header-input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 16px 40px;
    gap: 20px;
    height: 104px;
    width: 100%;
    background: $bgLight;
    margin-bottom: -5px;
    .table-name-input {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 0px;
      gap: 8px;
      height: 72px;
      width: calc((100% - 40px) / 3);
      &_label {
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        letter-spacing: 0.03em;
        color: $monoBlack;
        white-space: nowrap;
      }
    }
    .table-type-input {
      width: calc((100% - 40px) / 3);
    }
  }
  #theProductProcessGrid {
    max-height: 365px !important;
    margin-bottom: 0px;
  }
  .table-header-custome {
    padding: 8px 40px;
    .table-header-custome-pulldown { 
      margin-right: 40px;
    }
  }
  .width-fit-content {
    width: fit-content !important;
  }
}

.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
  box-shadow: none;
}
.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 40px;
}
.duration-header {
  &.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 28px;
  }
}

.wj-cell.wj-has-changed {
  color: $goldHeavy !important;
}

.wj-cell.wj-has-changed-mark, .wj-cell.wj-has-unread-mark {
  overflow: hidden !important;
}

.wj-cell.wj-has-changed-mark:after, .wj-cell.wj-has-unread-mark:after {
  position: absolute;
  content: "";
  width: 0;
  left: 0;
  top: -10px;
  border: 10px solid transparent;
  border-left: 10px solid #E0A329;
}

.wj-cell.wj-has-unread-mark:after {
  border-left: 10px solid #132D39;
}

.wj-tooltip:has(.wj-cell-changed) {
  padding: 2px 10px 4px;
  background: #E0A329;
  color: #FFF;
  border: 1px solid $goldHeavy;
  box-shadow: unset;
}
.wj-cell.wj-has-changed[aria-readonly]:not(.wj-state-multi-selected) {
  background: $monoExtraLight !important;
}
.wj-cell.wj-has-changed[aria-readonly] {
  color: $goldHeavy !important;
  font-weight: 400 !important;
  font-size: 14px;
  line-height: 24px;
  border-right: unset !important;
  border-bottom: unset !important;
  align-items: center;
  gap: 6px;
  padding: 5px 8px 5px !important;
  border-right: 1px solid $dividerBlack !important;
  border-bottom: 1px solid $dividerBlack !important;

  &.btn-db {
    display: flex;
    justify-items: center;
    align-items: flex-start;
    background: $monoOffWhite !important;
    color: $monoBlack !important;
    padding: 4px !important;
    &.attach-file {
      &.wj-state-multi-selected {
        background: $blueExtraLight !important;
      }
    }
  }

  &.btn-disabled {
    color: $monoMid !important;
    pointer-events: none;
  }

  &.loadClass {
    background: #f7f7f2 !important;
  }
  &.wj-state-active:not(.wj-state-multi-selected,.emission) {
      background: $monoOffWhite !important;
    }
  &.wj-state-active {
    border: unset !important;
    border-right: 1px solid $dividerBlack !important;
    border-bottom: 1px solid $dividerBlack !important;

    &::after {
      background: unset;
    }
  }
}
// duplicate data
.wj-cell {
  &.wj-duplicated-data {
    color: $redMid !important;
    &.auto-increment {
      color: $redMid !important;
    }
  }
  &.wj-duplicated-data[aria-readonly] {
    background: $monoExtraLight !important;
    color: $redMid !important;
    font-weight: 400 !important;
    font-size: 14px;
    line-height: 24px;
    border-right: unset !important;
    border-bottom: unset !important;
    align-items: center;
    gap: 6px;
    padding: 5px 8px 5px !important;
    border-right: 1px solid $dividerBlack !important;
    border-bottom: 1px solid $dividerBlack !important;
  }
}
 
.grid-group{
  background: $bgLight;
  //margin-bottom: -5px;
  display: grid;
    grid-template-areas: 
    "top top top"
    "mid"
    "bot bot";
    .top-side{
    display: flex;
    // grid-gap: 8px;
    display: flex;
    padding: 8px 0px 8px 40px;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    .left{
      min-width: 120px;
      .fullscreen-item {
        display: flex;
        gap: 4px;

        .fullscreen-text {
          font-weight: 700;
          font-size: 11px;
          line-height: 18px;
          letter-spacing: 0.03em;
          color: $monoBlack; 
          white-space: nowrap;
          width: 67px;
          text-align: center;
        }

        .fullscreen-switch-btn {
          height: 16px;

          .v-input__control {
            height: 16px;

            .v-input--selection-controls__input {
              height: 20px !important;
              margin-right: 0 !important;
              width: 27px !important;

              input {
                height: 16px !important;
              }

              .v-input--switch__track {
                width: 27px !important;
                height: 10px;
                background: $monoDark !important;
                right: 0;
                left: 0;
                top: calc(50% - 5px);
              }

              .v-input--switch__thumb {
                width: 16px !important;
                height: 16px !important;
                margin-top: 2px !important;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24) !important;
              }
            }
          }

          &.v-input--is-label-active {
            .v-input__control {
              .v-input--selection-controls__input {
                .v-input--switch__track {
                  background: $blueMid !important;
                }
                .v-input--switch__thumb {
                  margin-left: -9px !important;
                }
                .v-input--selection-controls__ripple {
                  left: -24px !important;
                }
              }
            }
          }
        }

        &:first-child {
          padding: 4px 6px 4px 4px;
          background: $monoOffWhite;
          border: 1px solid rgba(42, 42, 48, 0.1);
          border-radius: 4px;
          max-width: 132px;
          height: 28px;
        }
      }
    }
    .right{
      //TODO
      // margin-right: 24px;
      // flex-grow: 1;
      display: flex;
      justify-content: space-between;
    }
  }
  .mid-side{
    grid-area: mid;
    padding: 8px 0px;
  }
  .bottom-side{ 
    grid-area: bot;
    display: flex;
    justify-content: space-between;
  }
  .comment-side {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    align-items: center;

  }
  .duration-side {
    grid-area: duration;
    .total-emissions {
      display: flex;
      flex-direction: column;
      margin-right: 8px;
      gap: 8px;
      &-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
        &--label {
          font-size: 11px;
          font-style: normal;
          font-weight: 700;
          line-height: 18px;
          letter-spacing: 0.33px;
          color: $monoBlack;
          min-width: 36px;
          white-space: nowrap;
          &.amount {
            min-width: 114px;
          }
        }
        &--value {
          font-family: $subFont;
          font-size: 24px;
          line-height: 36px;
          color: $goldMid;
          width: 100%;
          text-align: right;
          word-break: break-all;
          letter-spacing: 0.72px;

          span {
            font-size: 14px;
            line-height: 24px;
            min-width: 40px;
            display: inline-block;
            letter-spacing: 0.7px;
            margin-left: 8px;
          }
        }
      }
    }
  }
  .total-result {
    grid-area: total;
    justify-self: end
  }
}

@media (max-width: 1023px) {
.fullmode-duration {
  .durations-pulldown-table {
    .durations-label {
      display: none;
    }
  }
  .duration-header {
    height: 28px;
  }
  }
  .grid-group{
  &.compressed{
    grid-template-areas: 
    "top mid comment"
    "bot bot bot";
    // grid-template-columns: 1fr auto;
    .bottom-side{ 
      display: flex;
      justify-content: flex-end;
      grid-gap: 8px;
    }
  }
  grid-gap: 8px;
  grid-template-columns: auto 1fr;
  grid-template-areas: 
      "top mid comment"
      "bot bot bot";
  .comment-btn {
    margin-left: auto;
  }
  .top-side {
    padding: 8px 0px 0px 20px;
  }
  .mid-side {
    padding-bottom: 0px;
    &.not-comment-area {
      margin-right: 12px;
    }
  }
  .bottom-side {
      display: flex;
      justify-content: flex-end;
      grid-gap: 8px;
      margin-bottom: 8px;
  }
  .comment-side {
    padding-bottom: 0px;
  }
  &.grid-fullscreen {
    gap: 8px;
    grid-template-areas: 
    "duration duration duration"
      "top mid comment"
      "bot bot bot";
    &.compressed {
      grid-template-areas: 
        "top comment"
        "duration duration duration"
        "mid mid mid"
        "bot bot bot";
        &.is-view-data {
          // grid-template-areas: 
          // "top top top"
          // "duration duration duration"
          // "bot bot bot";
        }
    }
    .duration-side {
      grid-template-columns: auto auto 1fr;
      grid-template-areas: 
        "year month method"
        "total total total";
      .total-result {
        justify-self: unset;
        .emissions {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .top-side {
      .left {
        min-width: 61px !important;
      .fullscreen-item {
        display: flex;
        gap: 4px;

        .fullscreen-text {
          display: none;
        }

        .fullscreen-switch-btn {
          height: 16px;

          .v-input__control {
            height: 16px;

            .v-input--selection-controls__input {
              height: 20px !important;
              margin-right: 0 !important;
              width: 27px !important;

              input {
                height: 16px !important;
              }

              .v-input--switch__track {
                width: 27px !important;
                background: $monoDark !important;
              }

              .v-input--switch__thumb {
                width: 16px !important;
                height: 16px !important;
                margin-top: 2px !important;
                box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.24) !important;
              }
            }
          }

          &.v-input--is-label-active {
            .v-input__control {
              .v-input--selection-controls__input {
                .v-input--switch__track {
                  background: $blueMid !important;
                }

                .v-input--switch__thumb {
                  margin-left: -9px !important;
                }
              }
            }
          }
        }

        &:first-child {
          padding: 4px 6px 4px 4px;
          background: $monoOffWhite;
          border: 1px solid rgba(42, 42, 48, 0.1);
          border-radius: 4px;
          max-width: 61px;
          align-items: center;
        }
      }
      }
    }

  }
  &.grid-setting-screen {
  grid-template-areas: 
    "top mid bot"
}
}
}



@media (min-width: 1024px) {
  .grid-group{
    align-items: center;
    grid-template-areas: "top mid comment bot";
    grid-template-columns: auto 1fr auto;
    &.is-over-text {
        grid-template-areas: 
        "top mid comment"
        "bot bot bot";
      .bottom-side {
        margin-left: 40px;
        margin-bottom: 8px;
      }
    }
    &.grid-fullscreen {
      align-items: center;
      grid-template-areas: 
      "duration duration duration duration"
      "top mid comment bot";
      &.compressed {
        grid-template-areas: 
        "top duration comment"
        "mid mid mid mid"
        "bot bot bot";
        grid-gap: 4px;
        // &.is-view-data {
        //   grid-template-areas: 
        //   "top duration"
        //   "bot bot";
        // }
      }
      .method {
        ::v-deep .common-btn{
          height: 24px !important;
        }
      }
      .top-side {
          .right {
            margin-right: 8px;
          }
      }
      .comment-side {
        margin-left: 8px;
        margin-right: 0;
      }
      .bottom-side {
        margin-left: 40px;
      }
    }
    &.grid-setting-screen {
      grid-template-areas: 
        "top mid bot"
    }
    &.is-type-view-product-approved {
      gap: 8px;
      grid-template-areas: 
        "top mid bot"
    }
    .bottom-side {
      margin-right: 40px;
    }
    .duration-side {
      padding: 8px 40px;
      .total-emissions {
        flex-direction: row;
      }
    }
  }
}

.add-new-focus-button {
  border: 2px solid $blueMid;
}
</style>

<style scoped lang="scss">
@media (min-width: 1024px) {
  .grid-group{
    &.grid-fullscreen {
      .bottom-side {
        margin-left: 40px !important;
      }
    }
  }
}
