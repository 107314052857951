<template>
  <div>
    <!-- retain-focus: for bug 10421  => work in vuetify 2.6.12  -->
    <v-dialog
      v-model="dialog"
      @click:outside="handleClickOutSite"
      ref="dialog"
      :width="width"
			:max-width="width"
      :content-class="`product-dialog-popup select-emission input-free ${dialog ? 'fullscreen-dialog-transition-open-to' : 'fullscreen-dialog-transition-leave-to'}`"
      :transition="getTransiTion"
      :retain-focus="false"
      overlay-color="rgba(0, 0, 0)"
      overlay-opacity="0.5"
    >
      <div class="head-dialog-popup">
        <div class="head-close-btn" @click="close">
          <img class="head-image-dialog-popup head-btn-close" src="@/assets/images/dialog/close.svg" />
          <img class="head-image-dialog-popup head-btn-close_active" src="@/assets/images/dialog/close_active.svg" />
        </div>
        <span class="head-title">{{ title }}</span>
      </div>
      <div class="content-dialog-popup" @click="focusButton = false">
        <div class="content-title">{{ title }}</div>
        <div class="content no-scroll">
          <!-- TODO: update UI layer -->
          <div class="content-search">
            <div class="content-search-filter">
              <div class="search-item duration">
                <div class="search-item-label">{{ $t('b_register_product.table_duration_basic_unit') }}</div>
                <div class="search-item-detail">
                  <div class="search-item-date">
                    <v-select
                      solo
                      flat
                      :items="yearList.startYear"
                      v-model="periodData.startYear"
                      item-text="text"
                      item-value="value"
                      :placeholder="$t('calculation_term.placeholder_unselect')"
                      no-data-text="''"
                      class="select-type list-menu--select select-item content-item-block-select"
                      :menu-props="{ contentClass: 'select-menu product-select pulldown-year' }"
                      ref="yearFrom"
									    @blur="handleCloseSelect"
                    ></v-select>
                    <v-select
                      solo
                      flat
                      :items="startMonthList"
                      v-model="periodData.startMonth"
                      :placeholder="$t('calculation_term.placeholder_unselect')"
                      no-data-text="''"
                      item-text="text"
                      item-value="value"
                      class="select-type list-menu--select select-item content-item-block-select"
                      :menu-props="{ contentClass: 'select-menu product-select pulldown-year' }"
                      ref="monthFrom"
									    @blur="handleCloseSelect"
                    ></v-select>
                  </div>
                  <div class="search-item-underline">ー</div>
                  <div class="search-item-date">
                    <v-select
                      solo
                      flat
                      :items="yearList.endYear"
                      v-model="periodData.endYear"
                      item-text="text"
                      item-value="value"
                      :placeholder="$t('calculation_term.placeholder_unselect')"
                      no-data-text="''"
                      class="select-type list-menu--select select-item content-item-block-select"
                      :menu-props="{ contentClass: 'select-menu product-select pulldown-year' }"
                      ref="yearTo"
									    @blur="handleCloseSelect"
                    ></v-select>
                    <v-select
                      solo
                      flat
                      :items="endMonthList"
                      v-model="periodData.endMonth"
                      :placeholder="$t('calculation_term.placeholder_unselect')"
                      item-text="text"
                      item-value="value"
                      no-data-text="''"
                      class="select-type list-menu--select select-item content-item-block-select"
                      :menu-props="{ contentClass: 'select-menu product-select pulldown-year' }"
                      ref="fromTo"
									    @blur="handleCloseSelect"
                    ></v-select>
                  </div>
                </div>
              </div>

              <div class="search-item">
                <div class="search-item-label">{{ $t('b_register_product.label_scope_category') }}</div>
                <v-select
                  solo
                  flat
                  :items="scopeCategoriesList"
                  v-model="scopeCategoriesModel"
                  :label="$t('b_register_product.pulldown_select_select_approver')"
                  no-data-text="''"
                  class="select-type list-menu--select select-item content-item-block-select search-item-select"
                  :menu-props="{ contentClass: 'select-menu product-select pulldown-year' }"
                  ref="scope"
									@blur="handleCloseSelect"
                ></v-select>
              </div>
            </div>
            <CommonButton class="content-search-btn" :label="$t('b_register_product.button_search')" type="colored" @action="handleSearchEmissions" />
          </div>
          <div class="content-table search-box">
            <DataTable
              :data-source="listData"
              :grid-columns="gridColumns"
              :init-grid="initializeGrid"
              :showFocus="false"
              :allowAddNew="false"
              :isShowToolbar="false"
              :currentPage="curentPage"
            />
          </div>
        </div>
      </div>
      <div class="action-dialog-popup select-emission select-unit emission-selected-wrapper">
        <div class="left-action"></div>
        <div class="main-action">
          <div class="content-paging">
            <div class="content-result">{{ $t('b_register_product.label_search_results') }}: {{listEmissionData.length}} {{ $t('b_register_product.label_item') }}</div>
            <div class="content-paging-detail">
              <div class="prev" :class="!isPrev && 'disable'" @click="handlePrev">
                <svg v-if="isPrev" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.51563 11L11.5156 16L11.5156 6L4.51563 11Z" stroke="#A9A04B" stroke-linejoin="round" />
                  <path d="M8.48438 11L15.4844 16L15.4844 6L8.48438 11Z" stroke="#A9A04B" stroke-linejoin="round" />
                </svg>
                <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.51563 11L11.5156 16L11.5156 6L4.51563 11Z" stroke="#99A6A9" stroke-linejoin="round" />
                  <path d="M8.48438 11L15.4844 16L15.4844 6L8.48438 11Z" stroke="#99A6A9" stroke-linejoin="round" />
                </svg>
              </div>
              <v-select
                solo
                flat
                :items="listPage"
                v-model="curentPage"
                :label="$t('b_register_product.pulldown_select_select_approver')"
                no-data-text="''"
                class="select-type list-menu--select select-item content-item-block-select"
                :class="{'focus-button' : focusButton}"
                :menu-props="{ contentClass: 'select-menu product-select product input-free select-pagination top-unset', location: 'top' }"
                ref="pagination"
                @click="focusButton = !focusButton"
								@blur="handleCloseSelect"
              ></v-select>
              <div class="next" :class="!isNext && 'disable'" @click="handleNext">
                <svg v-if="isNext" width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.4844 11L8.48438 6V16L15.4844 11Z" stroke="#A9A04B" stroke-linejoin="round" />
                  <path d="M11.5156 11L4.51562 6V16L11.5156 11Z" stroke="#A9A04B" stroke-linejoin="round" />
                </svg>
                <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M15.4844 11L8.48438 6V16L15.4844 11Z" stroke="#99A6A9" stroke-linejoin="round" />
                  <path d="M11.5156 11L4.51562 6V16L11.5156 11Z" stroke="#99A6A9" stroke-linejoin="round" />
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import InputField from '@/components/products/input/InputField.vue';
import CommonButton from '@/components/utils/button.vue';
import DataTable from '@/components/category/data-table';
import { CollectionView } from '@mescius/wijmo';
import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import { mapState, mapActions } from 'vuex';
import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import { KEYS_CODE } from '@/constants/keyboard'
import { searchEmissions } from '@/api/product';
import moment from 'moment';
import { scopeCategoriesData, oldmethod, newPcafMethod } from '@/constants/export-report';
import { ORGANIZATIONAL_DATA } from '@/constants/registerData.js';
import { getScopeCateriesNameFromPatternId } from '@/utils/registerData';
import { formatValue } from '@/concerns/newRegisterData/wijmo.helper';
import {addThoundSandComma} from '@/utils/convertNumber';
import { getMonthListCustome, calcYearFromStartMonthCustome } from '@/utils/registerData';
import { getMinWidthOrganization, getWidthOfDetailButton } from '@/utils/multiLanguage';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import { getDataTypeApi } from '@/api/pcaf'
import { setDataTypeForPcafMethod } from '@/utils/pcaf';

export default {
  components: { InputField, CommonButton, DataTable },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    listLayer: {
      type: Array,
      default: () => []
    },
    durationList: {
      type: Array,
      default: () => []
    },
    startMonth: {
      type: Number,
      default: 4,
    }
  },
  data() {
    return {
      width: '',
      title: this.$t('b_register_product.title_select_the_registered_original_unit'),
      search: null,
      flex: null,
      listEmissionData: [],
      gridColumns: [],
      listData: null,
      pageSize: 20,
      total: 0,
      curentPage: 1,
      listPage: [],
      yearList: {
        startYear: [],
        endYear: [],
      },
      monthList: [],
      periodData: {
        startYear: null,
        endYear: null,
        startMonth: null,
        endMonth: null,
      },
      beginYear: 2017,
      scopeCategoriesList: [],
      scopeCategoriesModel: null,
      isSearchEmissions: false,
      focusButton: false,
      endMonthList: [],
      startMonthList: [],
      allDataType: {}
    };
  },
  async mounted() {
    this.getWidth();
    if(this.isSettingPcaf) {
      await this.handleGetDataType()
    }
    window.addEventListener('resize', this.getWidth);
    this.prepareMonthYearPulldown();
    this.prepareScopeCategoriesPulldown();
  },
  methods: {
    ...mapActions('commonApp', ['actionUpdateIsLoadingTable']),
    handleCloseSelect() {
			for (let item in this.$refs) {
				if(this.$refs[item]) {
					this.$refs[item].isMenuActive = false
				}
			}
		},
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    },
    handleClickOutSite() {
      this.$emit('close');
    },
    getWidth() {
      if (window.innerWidth > 1024) {
        this.width = this.isExpand ? 'calc(100% - (236px + 56px))' : 'calc(100% - (72px + 56px))';
        return
      }
      this.width = '100%';
    },
    handleUpdateSearch(newVal) {
      this.search = newVal;
    },
    defineTable() {
      this.gridColumns = this.getGridColumns();
      this.listData = new CollectionView(this.listEmissionData, {
        pageSize: this.pageSize,
      });
      if(this.flex) {
        this.flex.columnGroups = this.getGridColumns();
        setMinMaxSizeColumns(this.flex, this.listEmissionData);
      }
    },
    handleIdClick(context) {
      this.$emit('selectEmissions', context.item)
    },
    getGridColumns() {
      const layerIndex = {
        1: 'business_name',
        2: 'country',
        3: 'layer_3',
        4: 'layer_4',
        5: 'layer_5',
        6: 'layer_6',
      }
      const layers = this.listLayer.map((item) => {
        return {
          header: item.layer_name,
          visible: true,
          binding: layerIndex[item.layer_index],
          maxWidth: 980,
          minWidth: getWidthByTextContent(item.layer_name) + 10,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          isReadOnly: true,
        };
      });
      return [
        {
          header: ' ',
          binding: 'id',
          // This button must be larger than the others in size. 'es lang'
          minWidth: this.$i18n.locale === 'es' ? 80 : (this.$i18n.locale === 'vi' ? 76 : getWidthOfDetailButton()),
          maxWidth: this.$i18n.locale === 'es' ? 80 : (this.$i18n.locale === 'vi' ? 76 : getWidthOfDetailButton()),
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'btn-detail hide-filter product-detail',
          cellTemplate: CellMaker.makeButton({
            text: this.$t('b_register_product.button_choice'),
            click: (event, context) => this.handleIdClick(context)
          })
        },
        {
          header: this.$t('b_register_product.table_duration'),
          binding: 'duration',
          minWidth: getWidthByTextContent(this.$t('b_register_product.table_duration')) + 5,
          maxWidth: 400,
          allowSorting: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_register_product.table_organizational_information'),
          align: 'justify',
          collapseTo: 'organizational_division',
          columns: [
            {
              header: this.$t('b_register_product.table_organizational_division'),
              binding: 'organizational_division',
              minWidth: getMinWidthOrganization(),
              maxWidth: 400,
              allowSorting: false,
              isRequired: false,
              isReadOnly: true,
              cssClassAll: 'double-row',
              wordWrap: true,
            },
            {
              header: this.$t('b_register_product.table_company_name'),
              binding: 'company_name',
              minWidth: getWidthByTextContent(this.$t('b_register_product.table_company_name')) + 20,
              maxWidth: 400,
              allowSorting: false,
              isRequired: false,
              isReadOnly: true,
              cssClassAll: 'double-row',
              wordWrap: true,
            },
            ...layers,
          ],
        },
        {
          header: this.$t('b_register_product.table_scope'),
          binding: 'scope',
          minWidth: getWidthByTextContent(this.$t('b_register_product.table_scope')),
          maxWidth: 980,
          allowSorting: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_register_product.table_unit'),
          binding: 'unit',
          minWidth: getWidthByTextContent(this.$t('b_register_product.table_unit')),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_register_product.table_wsu_value'),
          binding: 'wsu_value',
          minWidth: getWidthByTextContent(this.$t('b_register_product.table_wsu_value')),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          align: 'right',
        },
        {
          header:  this.$t('b_register_product.table_wsu_unit'),
          binding: 'wsu_unit',
          minWidth: getWidthByTextContent(this.$t('b_register_product.table_wsu_unit')),
          maxWidth: 400,
          allowSorting: false,
          isReadOnly: true,
          cssClassAll: '',
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_register_product.table_emissions'),
          binding: 'emissions',
          minWidth: getWidthByTextContent(this.$t('b_register_product.table_emissions')) + 5,
          maxWidth: 980,
          allowSorting: false,
          isReadOnly: true,
          cssClassAll: '',
          multiLine: true,
          wordWrap: true,
          align: 'right',
        },
      ];
    },
    getMonth(month) {
      return Number(month) < 10 ? `0${month}` : month
    },
    getValueSource(item) {
      const valueSource = item?.dbCustomize?.value_source || item?.dbMaster?.value_source || item?.dbSourceDetail?.value_source || item?.serviceProductPackageLink?.value_source || ''
      return item?.is_pcaf ? item?.emissions_factor || 1 : valueSource
    },
    async getTableData(payload) {
      this.isSearchEmissions = true;
      this.actionUpdateIsLoadingTable(true)
      await searchEmissions(payload).then((res) => {
        this.isSearchEmissions = false;
        this.total = Math.ceil(res.data.length / this.pageSize);
        this.total = this.total === 0 ? 1 : this.total;
        this.listPage = [];
        this.listEmissionData = [];
        let scopeCategoriesDataPattern = scopeCategoriesData
        const indexGhg = scopeCategoriesDataPattern.findIndex(item => item.categories === 15)
        scopeCategoriesDataPattern[indexGhg].methods = this.planType?.is_pcaf ? newPcafMethod : oldmethod
        res.data.forEach((item) => {
          const subStringRange = item.emissions?.toString()?.includes('-') ? 26 : 25
          let emissions = [8, 36, 40, 46].includes(item.row_num_pattern) ? item.formula_emissions : item?.value_emissions;
          // emissions = emissions === null ? null : formatValue(item.value_emissions)?.substring(0, subStringRange).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') || '0'
          emissions = emissions === null ? null : addThoundSandComma(formatValue(item.value_emissions)?.substring(0, subStringRange)) || '0'
          this.listEmissionData.push({
            id: item.id,
            data_id: item.id,
            status: item.status === 1 ? true : false,
            title: item.title,
            scope: getScopeCateriesNameFromPatternId(scopeCategoriesDataPattern, item.row_num_pattern, item.type_classify, item.type_data),
            last_modified: formatDateTimeDataTable(item.updated_at),
            organizational_division: ORGANIZATIONAL_DATA[item?.branch?.organizational_division],
            company_name: item?.branch?.company_name,
            business_name: item?.branch?.business_name || '',
            country: item?.branch?.country || '',
            layer_3: item?.branch?.layer_3 || '',
            layer_4: item?.branch?.layer_4 || '',
            layer_5: item?.branch?.layer_5 || '',
            layer_6: item?.branch?.layer_6 || '',
            duration: this.$i18n.locale !== 'ja' ? `${item.year}/${item.month}` : this.$d(new Date( `${item.year}-${this.getMonth(item.month)}`), 'short'),
            unit: item?.dbCustomize?.unit || item?.dbMaster?.unit || item?.dbSourceDetail?.unit || item?.serviceProductPackageLink?.unit || '',
            wsu_value: this.getValueSource(item),
            wsu_unit: item?.dbCustomize?.unit_source || item?.dbMaster?.unit_source || item?.dbSourceDetail?.unit_source || item?.serviceProductPackageLink?.unit_source || '',
            emissions: emissions,
          });
        });
        for (let index = 1; index <= this.total; index++) {
          this.listPage.push({
            text: `${index} / ${this.total}`,
            value: index
          })

        }
        this.curentPage = 1;
        this.defineTable();
        this.actionUpdateIsLoadingTable(false)
      }).catch(() => {
        this.actionUpdateIsLoadingTable(false)
      });
    },
    initializeGrid(flexgrid) {
      this.flex = flexgrid;
      this.flex.columnGroups = this.getGridColumns();
      document.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW].includes(
            event.keyCode,
          )
        ) {
          event.preventDefault();
        }
      });

      flexgrid.hostElement.addEventListener(
        'keydown',
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false,
      );
      flexgrid.hostElement.addEventListener('mouseover', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      flexgrid.hostElement.addEventListener('mouseout', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });
      this.flex.onSelectionChanged(null);
    },
    handlePrev() {
      if(this.curentPage <= 1) return
      this.curentPage = this.curentPage - 1
    },
    handleNext() {
      if(this.curentPage >= this.total) return
      this.curentPage = this.curentPage + 1
    },
    prepareMonthYearPulldown() {
      this.yearList.endYear = calcYearFromStartMonthCustome(this.startMonth, 2017);
      this.yearList.startYear = calcYearFromStartMonthCustome(this.startMonth, 2017);
      this.startMonthList = getMonthListCustome(this.yearList.endYear[0].value, this.startMonth);
      this.endMonthList = getMonthListCustome(this.yearList.endYear[this.yearList.endYear.length - 1].value, this.startMonth);

      // set default select
      this.periodData = {
        startMonth: this.startMonthList[0].text,
        startYear: this.yearList.startYear[0].text,
        endMonth: this.endMonthList[this.endMonthList.length - 1].text,
        endYear: this.yearList.startYear[this.yearList.startYear.length - 1].text,
      }
    },
    prepareScopeCategoriesPulldown() {
      this.scopeCategoriesList.push({
        text: this.$t('b_register_product.pulldown_select_select_all'),
        value: null,
      });
      let scopeCategoriesDataPattern = scopeCategoriesData
      const indexGhg = scopeCategoriesDataPattern.findIndex(item => item.categories === 15)
      let pcafMethod = newPcafMethod
      if(this.planType?.is_pcaf && !this.allDataType.length) {
        pcafMethod = setDataTypeForPcafMethod(newPcafMethod, this.allDataType)
      }
      scopeCategoriesDataPattern[indexGhg].methods = this.planType?.is_pcaf ? pcafMethod : oldmethod

      scopeCategoriesDataPattern.forEach(item => {
        if (!item.scope) return
        if (!item?.methods) {
          this.scopeCategoriesList.push({
            text: item.text_ik,
            value: item.row_num_pattern,
          });
        } else if (item.methods) {
          if(item.categories === 15 && this.planType?.is_pcaf) {
            item.methods.forEach(method => {
              method.methods.forEach(subMethod => {
                const customtext = `${item.text_ik}_${subMethod.text_select}`
                this.scopeCategoriesList.push({
                  text: customtext,
                  value: {
                    row_num_pattern: subMethod.row_num_pattern,
                    data_type: subMethod.data_type,
                    type_classify: subMethod?.type_classify
                  },
                });
              })
            })
          } else {
            item.methods.forEach(itemLayer1 => {
              if (!itemLayer1.methods) {
                let customtext = `${item.text_ik}_${itemLayer1.text_ik}`
                if(itemLayer1.row_num_pattern === 37 && this.$i18n.locale === 'ja') {
                  customtext = `${item.text_ik}${itemLayer1.text_ik}`
                } else if(itemLayer1.row_num_pattern === 8 && this.$i18n.locale === 'ja') {
                  customtext = 'Scope 3-4. ' + `${itemLayer1.text_ik}`
                }
                this.scopeCategoriesList.push({
                  text: customtext,
                  value: itemLayer1.row_num_pattern,
                });
              } else if (itemLayer1.methods) {
                itemLayer1.methods.forEach(itemLayer2 => {
                  let customtext = `${item.text_ik}_${itemLayer1.text_ik}_${itemLayer2.text_ik}`
                  if(
                      (itemLayer2.row_num_pattern === 34
                    || itemLayer2.row_num_pattern === 35
                    || itemLayer2.row_num_pattern === 36) && this.$i18n.locale === 'ja'
                  ) {
                    customtext = `${item.text_ik}${itemLayer2.text_ik}`
                  } else if (
                    (itemLayer2.row_num_pattern === 9
                    || itemLayer2.row_num_pattern === 10
                    || itemLayer2.row_num_pattern === 11
                    || itemLayer2.row_num_pattern === 28
                    || itemLayer2.row_num_pattern === 29
                    || itemLayer2.row_num_pattern === 30) && this.$i18n.locale === 'ja'
                  ) {
                    customtext = `${item.text_ik}_${itemLayer2.text_ik}`
                  }
                  this.scopeCategoriesList.push({
                    text: customtext,
                    value: itemLayer2.row_num_pattern,
                  });
                })
              }
            })
          }
        }
      })
      // set default select scope / category
      this.scopeCategoriesModel = this.scopeCategoriesList[0].value;
    },
    handleSearchEmissions() {
      if (this.isSearchEmissions) return
      const payload = {
        start_at: [
          moment(this.periodData.startYear, 'YYYY').format('YYYY'),
          moment(this.periodData.startMonth, 'MM').format('MM'),
        ].join('-'),
        end_at: [
          moment(this.periodData.endYear, 'YYYY').format('YYYY'),
          moment(this.periodData.endMonth, 'MM').format('MM'),
        ].join('-'),
        row_num_pattern: this.scopeCategoriesModel,
      }
      const rowNumData = payload.row_num_pattern
      if(typeof rowNumData === 'object') { // update payload for PCAF 
        payload.type_classify = rowNumData?.type_classify,
        payload.type_data = rowNumData?.data_type,
        payload.row_num_pattern = rowNumData?.row_num_pattern
      }
      this.getTableData(payload);
    },
    async handleGetDataType() {
      try {
        const responseDataType = await getDataTypeApi()
        this.allDataType = responseDataType.data
      } catch (error) {
        console.warn(error);
      }
    },
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    ...mapState("userData", ["isSettingPcaf", "planType"]),
    isPrev() {
      return this.curentPage !== 1
    },
    isNext() {
      return this.curentPage < this.total
      // total <= 1 || curentPage === total
    },
    getTransiTion() {
      if (window.innerWidth > 1024){
        return 'slide-x-reverse-transition'
      } else {
        return 'dialog-bottom-transition'
      }
    }
  },
  watch: {
    curentPage() {
      this.listData.moveToPage(this.curentPage - 1)
    },
    dialog: {
      handler(value) {
        if (value) {
          // reset data filter
          this.scopeCategoriesModel = null;
          let currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          if (this.startMonth > currentMonth) {
            currentYear = currentYear - 1;
          }
          const lastDate = moment(`${currentYear}/${this.startMonth}`, 'YYYY/M').add(11, 'months');
          this.periodData = {
            startMonth: this.startMonth,
            startYear: this.beginYear,
            endMonth: +lastDate.format('M'),
            endYear: +lastDate.format('YYYY'),
          }
          const payload = {
            start_at: [
              moment(this.periodData.startYear, 'YYYY').format('YYYY'),
              moment(this.periodData.startMonth, 'MM').format('MM'),
            ].join('-'),
            end_at: [
              moment(this.periodData.endYear, 'YYYY').format('YYYY'),
              moment(this.periodData.endMonth, 'MM').format('MM'),
            ].join('-'),
            row_num_pattern: null,
          }
          Promise.all([this.getTableData(payload)]).catch(() => {});
        }
      }
    },
    'periodData.startYear': {
      handler(newVal) {
        const startYear = this.yearList.startYear.find(item => item.value === newVal)?.value
        this.startMonthList = getMonthListCustome(startYear, this.startMonth);
      }
    },
    'periodData.endYear': {
      handler(newVal) {
        const startYear = this.yearList.endYear.find(item => item.value === newVal)?.value
        this.endMonthList = getMonthListCustome(startYear, this.startMonth);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWidth);
  },
};
</script>
<style lang="scss">
.v-menu__content {
  &.select-menu {
    &.product-select {
      &.input-free {
        &.top-unset {
          margin-top: 0 !important;
          top:unset !important;
          bottom: 52px !important;
        }
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.action-dialog-popup {
  .focus-button {
    border: 2px solid $blueMid;
  }
}
</style>
