<template>
  <div>
    <!-- retain-focus: for bug 10421  => work in vuetify 2.6.12  -->
    <v-dialog
      v-model="dialog"
      @click:outside="handleClickOutSite"
      ref="dialog"
      :width="width"
      :max-width="width"
      :content-class="`product-dialog-popup select-emission register-popup ${dialog ? 'fullscreen-dialog-transition-open-to' : 'fullscreen-dialog-transition-leave-to'}`"
      :transition="getTransiTion"
      :retain-focus="false"
      overlay-color="rgba(0, 0, 0)"
      overlay-opacity="0.5"
    >
      <div class="head-dialog-popup">
        <div class="head-close-btn" @click="close">
          <img class="head-image-dialog-popup head-btn-close" src="@/assets/images/dialog/close.svg" />
          <img class="head-image-dialog-popup head-btn-close_active" src="@/assets/images/dialog/close_active.svg" />
        </div>
        <span class="head-title">{{ title }}</span>
      </div>
      <div class="content-dialog-popup" @click="focusButton = false">
        <div class="content-title">{{ title }}</div>
        <div class="content no-scroll">
          <!-- TODO: update UI layer -->
          <div class="content-search">
            <div class="content-search-filter select-emission-register">
              <div class="search-item duration">
                <div class="search-item-label">{{ $t('b_register_product.label_period') }}</div>
                <div class="search-item-detail">
                  <div class="search-item-date">
                    <v-select
                      solo
                      flat
                      :items="yearList.startYear"
                      v-model="periodData.startYear"
                      item-text="text"
                      item-value="value"
                      :placeholder="$t('calculation_term.placeholder_unselect')"
                      no-data-text="''"
                      class="select-type list-menu--select select-item content-item-block-select"
                      :menu-props="{ contentClass: 'select-menu product-select pulldown-year' }"
                      ref="yearFrom"
                      @blur="handleCloseSelect"
                    ></v-select>
                    <v-select
                      solo
                      flat
                      :items="startMonthList"
                      v-model="periodData.startMonth"
                      :placeholder="$t('calculation_term.placeholder_unselect')"
                      item-text="text"
                      item-value="value"
                      no-data-text="''"
                      class="select-type list-menu--select select-item content-item-block-select"
                      :menu-props="{ contentClass: 'select-menu product-select pulldown-year' }"
                      ref="monthFrom"
                      @blur="handleCloseSelect"
                    ></v-select>
                  </div>
                  <div class="search-item-underline">ー</div>
                  <div class="search-item-date">
                    <v-select
                      solo
                      flat
                      :items="yearList.endYear"
                      v-model="periodData.endYear"
                      :placeholder="$t('calculation_term.placeholder_unselect')"
                      no-data-text="''"
                      item-text="text"
                      item-value="value"
                      class="select-type list-menu--select select-item content-item-block-select"
                      :menu-props="{ contentClass: 'select-menu product-select pulldown-year' }"
                      ref="yearTo"
                      @blur="handleCloseSelect"
                    ></v-select>
                    <v-select
                      solo
                      flat
                      :items="endMonthList"
                      v-model="periodData.endMonth"
                      :placeholder="$t('calculation_term.placeholder_unselect')"
                      no-data-text="''"
                      item-text="text"
                      item-value="value"
                      class="select-type list-menu--select select-item content-item-block-select"
                      :menu-props="{ contentClass: 'select-menu product-select pulldown-year' }"
                      ref="fromTo"
                      @blur="handleCloseSelect"
                    ></v-select>
                  </div>
                </div>
              </div>

              <div class="search-item">
                <div class="search-item-label">{{  $t('b_register_product.label_scope_category') }}</div>
                <v-select
                  solo
                  flat
                  :items="scopeCategoriesList"
                  v-model="scopeCategoriesModel"
                  :label="$t('b_register_product.pulldown_select_select_approver')"
                  no-data-text="''"
                  class="select-type list-menu--select select-item content-item-block-select search-item-select"
                  :menu-props="{ contentClass: 'select-menu product-select pulldown-year' }"
                  ref="scope"
                  @blur="handleCloseSelect"
                ></v-select>
              </div>

              <div class="search-item">
                <!-- <div class="search-item-label">登録情報・出典</div> -->
                <div class="label d-flex search-item-label align-center">
                 {{ $t('b_register_product.label_registration_information_source') }}
                  <img
                    width="18"
                    height="18"
                    :src="getSettingIcon('helper-icon.svg')"
                    @mouseover="changeTooltipImage($event)"
                    @mouseleave="changeTooltipImage($event, 'mouseleave')"
                    class="ml-2"
                    alt=""
                  />
                </div>

                <div
                  v-if="showTooltip"
                  class="helper-icon tooltip"
                  v-html="$t('b_register_product.tooltip_description_registration_information_source')"
                ></div>
                <InputField
                  class="search-item-input"
                  :isClearData="false"
                  :inputValue="search"
                  @update:inputValue="handleUpdateSearch"
                />
              </div>
            </div>
            <CommonButton class="content-search-btn" :label="$t('b_register_product.button_search')" type="colored" @action="handleSearchEmissions" />
          </div>
          <div class="content-table">
            <DataTable
              :data-source="listData"
              :grid-columns="gridColumns"
              :init-grid="initializeGrid"
              :rowFocus="1"
              :showFocus="false"
              :allowAddNew="false"
              :isShowToolbar="false"
              :currentPage="curentPage"
            />
          </div>
        </div>
      </div>
      <div class="action-dialog-popup select-emission emission-selected-wrapper">
        <div class="left-action"></div>
        <div class="main-action">
          <div class="content-paging">
            <div class="content-result">{{ $t('b_register_product.label_search_results')}} {{listEmissionData.length}} {{ $t('b_register_product.label_item')}} </div>
            <div class="content-paging-detail">
              <div class="prev" :class="!isPrev && 'disable'" @click="handlePrev">
                <img v-if="isPrev" src="@/assets/icons/product/prev-active.svg" alt="prev-pape" />
                <img v-else src="@/assets/icons/product/prev-disable.svg" alt="prev-pape" />
              </div>
              <v-select
                solo
                flat
                :items="listPage"
                v-model="curentPage"
                :label="$t('b_register_product.pulldown_select_select_approver')"
                no-data-text="''"
                class="select-type list-menu--select select-item content-item-block-select"
                :class="{'focus-button' : focusButton}"
                :menu-props="{ contentClass: 'select-menu product-select product select-pagination top-uset', location: 'top', }"
                ref="pagination"
                @click="focusButton = !focusButton"
                @blur="handleCloseSelect"
              ></v-select>
              <div class="next" :class="!isNext && 'disable'" @click="handleNext">
                <img v-if="isNext" src="@/assets/icons/product/next-active.svg" alt="next-page" />
                <img v-else src="@/assets/icons/product/next-disable.svg" alt="next-page" />
              </div>
            </div>
          </div>

          <div class="content-action">
            <CommonButton class="submit-btn" :label="$t('b_register_product.button_decision')" type="colored" @action="submitDataToParent" />
          </div>
        </div>
      </div>
    </v-dialog>
  </div>
</template>

<script>
import InputField from '@/components/products/input/InputField.vue';
import CommonButton from '@/components/utils/button.vue';
import DataTable from '@/components/category/data-table';
import { CollectionView } from '@mescius/wijmo';
import { formatDateTimeDataTable } from '@/utils/datetimeFormat';
import { mapState, mapActions } from 'vuex';
import { KEYS_CODE } from '@/constants/keyboard';
import { searchEmissions } from '@/api/product';
import moment from 'moment';
import { scopeCategoriesData, oldmethod, pcafMethod, newPcafMethod } from '@/constants/export-report';
import { ORGANIZATIONAL_DATA } from '@/constants/dashboard';
import { getScopeCateriesNameFromPatternId } from '@/utils/registerData';
import {addThoundSandComma} from '@/utils/convertNumber';
import { getMonthListCustome, calcYearFromStartMonthCustome } from '@/utils/registerData';
import { getMinWidthOrganization } from '@/utils/multiLanguage';
import { getWidthByTextContent, setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import { getDataTypeApi } from '@/api/pcaf'
import { setDataTypeForPcafMethod } from '@/utils/pcaf';

export default {
  components: { InputField, CommonButton, DataTable },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: '保存',
    },
    message: {
      type: String,
      default: '保存',
    },
    listLayer: {
      type: Array,
      default: () => []
    },
    durationList: {
      type: Array,
      default: () => []
    },
    currentSelectedData: {
      type: Array,
      default: () => []
    },
    startMonth: {
      type: Number,
      default: 4,
    }
  },
  data() {
    return {
      width: '',
      title: this.$t('b_register_product.title_select_registered_emissions'),
      value: 'acd',
      search: null,
      flex: null,
      listEmissionData: [],
      gridColumns: [],
      listData: null,
      pageSize: 20,
      total: 0,
      curentPage: 1,
      listPage: [],
      showTooltip: false,
      yearList: {
        startYear: [],
        endYear: [],
      },
      monthList: [],
      periodData: {
        startYear: null,
        endYear: null,
        startMonth: null,
        endMonth: null,
      },
      beginYear: 2017,
      scopeCategoriesList: [],
      scopeCategoriesModel: null,
      isSearchEmissions: false,
      selectedData: [],
      focusButton: false,
      endMonthList: [],
      startMonthList: [],
      allDataType: {}
    };
  },
  async mounted() {
    this.getWidth();
    window.addEventListener('resize', this.getWidth);
    this.prepareMonthYearPulldown();
    if(this.isSettingPcaf) {
      await this.handleGetDataType()
    }
    this.prepareScopeCategoriesPulldown();
  },
  methods: {
    ...mapActions('commonApp', ['actionUpdateIsLoadingTable']),
    handleCloseSelect() {
      for (let item in this.$refs) {
        if (this.$refs[item]) {
          this.$refs[item].isMenuActive = false;
        }
      }
    },
    close() {
      this.selectedData = [];
      this.$emit('close');
    },
    handleClickOutSite() {
      this.$emit('close');
    },
    getWidth() {
      if (window.innerWidth > 1024) {
        this.width = this.isExpand ? 'calc(100% - (236px + 56px))' : 'calc(100% - (72px + 56px))';
        return;
      }
      this.width = '100%';
    },
    handleUpdateValue(newVal) {
      this.value = newVal;
    },
    handleUpdateSearch(newVal) {
      this.search = newVal;
    },
    defineTable() {
      this.gridColumns = this.getGridColumns();
      this.listData = new CollectionView(this.listEmissionData, {
        pageSize: this.pageSize,
        trackChanges: true,
      });
      if (this.flex) {
        this.flex.columnGroups = this.getGridColumns();
        setMinMaxSizeColumns(this.flex, this.listEmissionData);
      }
    },
    getGridColumns() {
      const layerIndex = {
        1: 'business_name',
        2: 'country',
        3: 'layer_3',
        4: 'layer_4',
        5: 'layer_5',
        6: 'layer_6',
      }
      const layers = this.listLayer.map((item) => {
        return {
          header: item.layer_name,
          visible: true,
          binding: layerIndex[item.layer_index],
          maxWidth: 980,
          minWidth: getWidthByTextContent(item.layer_name) + 10,
          allowSorting: false,
          isRequired: false,
          wordWrap: true,
          isReadOnly: true,
        };
      });
      return [
        {
          header: '#',
          binding: 'id',
          allowSorting: false,
          isReadOnly: true,
          visible: false,
        },
        {
          header: ' ',
          binding: 'status',
          minWidth: 32,
          maxWidth: 32,
          allowSorting: false,
          isRequired: false,
          multiLine: true,
          wordWrap: true,
          cssClassAll: 'status-product',
        },
        {
          header: this.$t('b_register_product.table_duration'),
          binding: 'duration',
          minWidth: getWidthByTextContent(this.$t('b_register_product.table_duration')) + 5,
          maxWidth: 400,
          allowSorting: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_register_product.table_scope'),
          binding: 'scope',
          minWidth: getWidthByTextContent(this.$t('b_register_product.table_scope')) + 5,
          maxWidth: 980,
          allowSorting: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_register_product.table_calculation_item'),
          binding: 'calculation_item',
          minWidth: getWidthByTextContent(this.$t('b_register_product.table_calculation_item')) + 5,
          maxWidth: 980,
          allowSorting: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_register_product.table_source_item'),
          binding: 'source_item',
          minWidth: getWidthByTextContent(this.$t('b_register_product.table_source_item')),
          maxWidth: 980,
          allowSorting: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_register_product.table_organizational_information'),
          align: 'center',
          collapseTo: 'organizational_division',
          columns: [
            {
              header: this.$t('b_register_product.table_organizational_division'),
              binding: 'organizational_division',
              minWidth: getMinWidthOrganization(),
              maxWidth: 400,
              allowSorting: false,
              isRequired: false,
              isReadOnly: true,
              cssClassAll: 'double-row',
              wordWrap: true,
            },
            {
              header: this.$t('b_register_product.table_company_name'),
              binding: 'company_name',
              minWidth: getWidthByTextContent(this.$t('b_register_product.table_company_name')) + 30,
              maxWidth: 400,
              allowSorting: false,
              isRequired: false,
              isReadOnly: true,
              cssClassAll: 'double-row',
              wordWrap: true,
            },
            ...layers,
          ],
        },
        {
          header: this.$t('b_register_product.table_value_emissions'),
          binding: 'value_emissions',
          minWidth: getWidthByTextContent(this.$t('b_register_product.table_value_emissions')),
          maxWidth: 800,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
          align: 'right',
          visible: false,
        },
        {
          header: this.$t('b_register_product.table_emissions'),
          binding: 'emissions',
          minWidth: getWidthByTextContent(this.$t('b_register_product.table_emissions')) + 5,
          maxWidth: 980,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          align: 'right',
          cssClassAll: '',
          wordWrap: true,
          multiLine: true,
        },
        {
          header: this.$t('b_register_product.table_last_modified'),
          binding: 'last_modified',
          minWidth: getWidthByTextContent(this.$t('b_register_product.table_last_modified')) + 5,
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          multiLine: true,
          wordWrap: true,
        },
      ];
    },
    getCalculationItemByValue(item) {
      const idPatternHasWsuType = [14, 38]
      if(!idPatternHasWsuType.includes(item?.row_num_pattern)) {
        return item?.calculation_item
      } else {
        const types = {
          1: '産業廃棄物',
          2: '一般廃棄物',
          3: '不明',
        }

        return types[Number(item?.calculation_item)]
      }
    },
    getMonth(month) {
      return Number(month) < 10 ? `0${month}` : month
    },
    getTableData(payload) {
      this.isSearchEmissions = true;
      this.actionUpdateIsLoadingTable(true)
      searchEmissions(payload).then((res) => {
        this.isSearchEmissions = false;
        this.total = Math.ceil(res.data.length / this.pageSize);
        this.total = this.total === 0 ? 1 : this.total;
        this.listPage = [];
        this.listEmissionData = [];
        let scopeCategoriesDataPattern = scopeCategoriesData
        const indexGhg = scopeCategoriesDataPattern.findIndex(item => item.categories === 15)
        scopeCategoriesDataPattern[indexGhg].methods = this.planType?.is_pcaf ? newPcafMethod : oldmethod
        res.data.forEach((item) => {
          let isExistItem = this.selectedData.find(selectedIem => selectedIem.id === Number(item.id_by_pattern));
          this.listEmissionData.push({
            id: Number(item.id_by_pattern),
            status: isExistItem ? true : false,
            title: item.title,
            organizational_division: ORGANIZATIONAL_DATA[item?.branch?.organizational_division],
            company_name: item?.branch?.company_name,
            business_name: item?.branch?.business_name || '',
            country: item?.branch?.country || '',
            layer_3: item?.branch?.layer_3 || '',
            layer_4: item?.branch?.layer_4 || '',
            layer_5: item?.branch?.layer_5 || '',
            layer_6: item?.branch?.layer_6 || '',
            duration: this.$i18n.locale !== 'ja' ? `${item.year}/${item.month}` : this.$d(new Date( `${item.year}-${this.getMonth(item.month)}`), 'short'),
            scope: getScopeCateriesNameFromPatternId(scopeCategoriesDataPattern, item.row_num_pattern, item.type_classify, item.type_data),
            last_modified: formatDateTimeDataTable(item.updated_at),
            value_emissions: item.formula_emissions === item.value_emissions ? item.value_emissions : [8, 40, 46].includes(item.row_num_pattern) ? item.value_emissions : [item.formula_emissions, item.value_emissions].join(' = '),
            emissions: addThoundSandComma(item.value_emissions),
            calculation_item: item?.is_pcaf ? null : this.getCalculationItemByValue(item),
            source_item: item.source_item,
            row_num_pattern: item.row_num_pattern,
          });
        });

        for (let index = 1; index <= this.total; index++) {
          this.listPage.push({
            text: `${index} / ${this.total}`,
            value: index,
          });
        }
        this.curentPage = 1;
        this.defineTable();
        this.actionUpdateIsLoadingTable(false)
      }).catch(() => {
        this.actionUpdateIsLoadingTable(false)
      });
    },
    initializeGrid(flexgrid) {
      this.flex = flexgrid;
      this.flex.columnGroups = this.getGridColumns();
      document.addEventListener('keydown', (event) => {
        if (
          (event.metaKey || event.ctrlKey) &&
          [KEYS_CODE.DOWN_ARROW, KEYS_CODE.UP_ARROW, KEYS_CODE.LEFT_ARROW, KEYS_CODE.RIGHT_ARROW].includes(
            event.keyCode,
          )
        ) {
          event.preventDefault();
        }
      });

      flexgrid.hostElement.addEventListener(
        'keydown',
        (event) => {
          if (event.metaKey || event.ctrlKey) {
            if (event.keyCode === KEYS_CODE.DOWN_ARROW) {
              const currentSelection = flexgrid.selection;
              const cellRange = new wjGrid.CellRange(flexgrid.rows.length - 1, currentSelection.col);
              flexgrid.selection = cellRange;

              // re-select after add more
              setTimeout(() => {
                flexgrid.selection = cellRange;
              }, 200);
            } else if (event.keyCode === KEYS_CODE.UP_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(0, currentSelection.col);
            } else if (event.keyCode === KEYS_CODE.RIGHT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(currentSelection.row, flexgrid.columns.length - 1);
            } else if (event.keyCode === KEYS_CODE.LEFT_ARROW) {
              const currentSelection = flexgrid.selection;
              flexgrid.selection = new wjGrid.CellRange(currentSelection.row, 1);
            }
          }
        },
        false,
      );
      flexgrid.hostElement.addEventListener('mouseover', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment_active.svg`);
        }
      });
      flexgrid.hostElement.addEventListener('mouseout', (e) => {
        if (e.target.parentElement.classList.contains('comment-icon')) {
          e.target.src = require(`@/assets/icons/comment.svg`);
        }
      });
      this.flex.onSelectionChanged(null);
    },
    handlePrev() {
      if (this.curentPage <= 1) return;
      this.curentPage = this.curentPage - 1;
    },
    handleNext() {
      if (this.curentPage >= this.total) return;
      this.curentPage = this.curentPage + 1;
    },
    submitDataToParent() {
      this.handleUpdateSelectedData();
      this.$emit('selectEmissions', this.selectedData);
      this.$emit('close');
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return '';
    },
    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = this.getSettingIcon('helper-icon.svg');
        event.target.parentElement.style.cursor = 'default';
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon('helper-icon_active.svg');
        event.target.parentElement.style.cursor = 'pointer';
        this.showTooltip = true;
      }
    },
    prepareMonthYearPulldown() {
      this.yearList.endYear = calcYearFromStartMonthCustome(this.startMonth, 2017);
      this.yearList.startYear = calcYearFromStartMonthCustome(this.startMonth, 2017);
      this.startMonthList = getMonthListCustome(this.yearList.endYear[0].value, this.startMonth);
      this.endMonthList = getMonthListCustome(this.yearList.endYear[this.yearList.endYear.length - 1].value, this.startMonth);

      // set default select
      this.periodData = {
        startMonth: this.startMonthList[0].text,
        startYear: this.yearList.startYear[0].text,
        endMonth: this.endMonthList[this.endMonthList.length - 1].text,
        endYear: this.yearList.startYear[this.yearList.startYear.length - 1].text,
      }
    },
    prepareScopeCategoriesPulldown() {
      this.scopeCategoriesList.push({
        text: this.$t('b_register_product.pulldown_select_select_all'),
        value: null,
      });
      let scopeCategoriesDataPattern = scopeCategoriesData
      //update method pcaf
      const indexGhg = scopeCategoriesDataPattern.findIndex(item => item.categories === 15)
      let pcafMethod = newPcafMethod

      if(this.planType?.is_pcaf && !this.allDataType.length) {
        pcafMethod = setDataTypeForPcafMethod(newPcafMethod, this.allDataType)
      }
      scopeCategoriesDataPattern[indexGhg].methods = this.planType?.is_pcaf ? pcafMethod : oldmethod

      scopeCategoriesDataPattern.forEach(item => {
        if (!item.scope) return
        if (!item?.methods) {
          this.scopeCategoriesList.push({
            text: item.text_ik,
            value: item.row_num_pattern,
          }); 
        } else if (item.methods) {
          if(item.categories === 15 && this.planType?.is_pcaf) {
            item.methods.forEach(method => {
              method.methods.forEach(subMethod => {
                const customtext = `${item.text_ik}_${subMethod.text_select}`
                this.scopeCategoriesList.push({
                  text: customtext,
                  value: {
                    row_num_pattern: subMethod.row_num_pattern,
                    data_type: subMethod.data_type,
                    type_classify: subMethod?.type_classify
                  },
                });
              })
            })
          } else {
            item.methods.forEach(itemLayer1 => {
              if (!itemLayer1.methods) {
                let customtext = `${item.text_ik}_${itemLayer1.text_ik}`
                if(itemLayer1.row_num_pattern === 37 && this.$i18n.locale === 'ja') {
                  customtext = `${item.text_ik}${itemLayer1.text_ik}`
                } else if(itemLayer1.row_num_pattern === 8 && this.$i18n.locale === 'ja') {
                  customtext = 'Scope 3-4. ' + `${itemLayer1.text_ik}`
                }
                this.scopeCategoriesList.push({
                  text: customtext,
                  value: itemLayer1.row_num_pattern,
                });
              } else if (itemLayer1.methods) {
                itemLayer1.methods.forEach(itemLayer2 => {
                  let customtext = `${item.text_ik}_${itemLayer1.text_ik}_${itemLayer2.text_ik}`
                  if(
                      (itemLayer2.row_num_pattern === 34
                    || itemLayer2.row_num_pattern === 35
                    || itemLayer2.row_num_pattern === 36) && this.$i18n.locale === 'ja'
                  ) {
                    customtext = `${item.text_ik}${itemLayer2.text_ik}`
                  } else if (
                    (itemLayer2.row_num_pattern === 9
                    || itemLayer2.row_num_pattern === 10
                    || itemLayer2.row_num_pattern === 11
                    || itemLayer2.row_num_pattern === 28
                    || itemLayer2.row_num_pattern === 29
                    || itemLayer2.row_num_pattern === 30) && this.$i18n.locale === 'ja'
                  ) {
                    customtext = `${item.text_ik}_${itemLayer2.text_ik}`
                  }
                  this.scopeCategoriesList.push({
                    text: customtext,
                    value: itemLayer2.row_num_pattern,
                  });
                })
              }
            })
          }
        }
      })
      // set default select scope / category
      this.scopeCategoriesModel = this.scopeCategoriesList[0].value;
    },
    handleSearchEmissions() {
      if (this.isSearchEmissions) return
      this.handleUpdateSelectedData();
      const payload = {
        start_at: [
          moment(this.periodData.startYear, 'YYYY').format('YYYY'),
          moment(this.periodData.startMonth, 'MM').format('MM'),
        ].join('-'),
        end_at: [
          moment(this.periodData.endYear, 'YYYY').format('YYYY'),
          moment(this.periodData.endMonth, 'MM').format('MM'),
        ].join('-'),
        row_num_pattern: this.scopeCategoriesModel,
        source: this.search,
      }
      const rowNumData = payload.row_num_pattern
      if(typeof rowNumData === 'object') { // update payload for PCAF 
        payload.type_classify = rowNumData?.type_classify,
        payload.type_data = rowNumData?.data_type,
        payload.row_num_pattern = rowNumData?.row_num_pattern
      }
      this.getTableData(payload);
    },
    handleUpdateSelectedData() {
      this.listData?._src?.filter(item => item.status === true).forEach(item => {
        const isExistItem = this.selectedData.find(selectedIem => selectedIem.id === Number(item.id));
        if (!isExistItem) {
          this.selectedData.push(item);
        }
      })
      this.listData?._src?.filter(item => item.status === false).forEach(item => {
        const isExistItem = this.selectedData.findIndex(selectedIem => selectedIem.id === Number(item.id));
        if (isExistItem >= 0) {
          this.selectedData.splice(isExistItem, 1);
        }
      })
    },
    async handleGetDataType() {
      try {
        const responseDataType = await getDataTypeApi()
        this.allDataType = responseDataType.data
      } catch (error) {
        console.warn(error);
      }
    },
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    ...mapState("userData", ["isSettingPcaf","planType"]),
    isPrev() {
      return this.curentPage !== 1;
    },
    isNext() {
      return this.curentPage < this.total;
      // total <= 1 || curentPage === total
    },
    getTransiTion() {
      if (window.innerWidth > 1024) {
        return 'slide-x-reverse-transition';
      } else {
        return 'dialog-bottom-transition';
      }
    },
  },
  watch: {
    curentPage() {
      this.listData.moveToPage(this.curentPage - 1);
    },
    dialog: {
      handler(value) {
        if (value) {
          // reset data filter
          this.scopeCategoriesModel = null;
          this.search = null;
          let currentYear = new Date().getFullYear();
          const currentMonth = new Date().getMonth() + 1;
          if (this.startMonth > currentMonth) {
            currentYear = currentYear - 1;
          }
          const lastDate = moment(`${currentYear}/${this.startMonth}`, 'YYYY/M').add(11, 'months');
          this.periodData = {
            startMonth: this.startMonth,
            startYear: this.beginYear,
            endMonth: +lastDate.format('M'),
            endYear: +lastDate.format('YYYY'),
          }
          this.selectedData = [...this.currentSelectedData];
          const payload = {
            start_at: [
              moment(this.periodData.startYear, 'YYYY').format('YYYY'),
              moment(this.periodData.startMonth, 'MM').format('MM'),
            ].join('-'),
            end_at: [
              moment(this.periodData.endYear, 'YYYY').format('YYYY'),
              moment(this.periodData.endMonth, 'MM').format('MM'),
            ].join('-'),
            row_num_pattern: null,
          }
          Promise.all([this.getTableData(payload)]).catch(() => {});
        }
      }
    },
    'periodData.startYear': {
      handler(newVal) {
        const startYear = this.yearList.startYear.find(item => item.value === +newVal)?.value
        this.startMonthList = getMonthListCustome(startYear, this.startMonth);
      }
    },
    'periodData.endYear': {
      handler(newVal) {
        const startYear = this.yearList.endYear.find(item => item.value === +newVal)?.value
        this.endMonthList = getMonthListCustome(startYear, this.startMonth);
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWidth);
  },
};
</script>
<style lang="scss">
.v-menu__content {
  &.select-menu {
    &.product {
      &.top-uset {
        margin-top: 0 !important;
        top:unset !important;
        bottom: 108px !important;
      }
    }
  }
}
@include desktop {
  .emission-selected-wrapper {
    .content-paging-detail {
      justify-content: center;
      margin-left: -6px;
    }
    .v-input__slot {
      width: 192.68px;
    }
    .v-input {
      flex: unset;
    }
    .submit-btn {
      max-width: 312.68px !important;
    }
  }
}
.action-dialog-popup {
  .focus-button {
    border: 2px solid $blueMid;
    overflow: hidden;
  }
}
</style>
<style lang="scss" scoped>
.search-item {
  position: relative;
  .tooltip {
    position: absolute;
    width: 400px;
    background: $monoWhite;
    display: block;
    justify-content: left;
    border-radius: 4px;
    color: $monoBlack;
    margin: 0 auto;
    font-size: 15px;
    padding: 10px;
    height: unset;
    max-width: max-content;
    left: 0;
    margin-bottom: 45px;
    // left: 0px;
    bottom: 25px;
    z-index: 9999;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  }
  @media (max-width: 768px) {
      .tooltip {
        width: auto;
      }
  }
}
</style>
