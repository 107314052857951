<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="handleClickOutSite"
      ref="dialog"
      :width="width"
			:max-width="width"
      :content-class="`product-dialog-popup apportion-input ${dialog ? 'fullscreen-dialog-transition-open-to' : 'fullscreen-dialog-transition-leave-to'}`"
      :style="getMaxWidthDialogByExpand"
      :transition="getTransiTion"
      overlay-color="rgba(0, 0, 0)"
      overlay-opacity="0.5"
    >
      <div class="head-dialog-popup">
        <div class="head-close-btn" @click="close">
          <img class="head-image-dialog-popup head-btn-close" src="@/assets/images/dialog/close.svg" />
          <img class="head-image-dialog-popup head-btn-close_active" src="@/assets/images/dialog/close_active.svg" />
        </div>
        <span class="head-title">{{ title }}</span>
      </div>
      <div class="content-dialog-popup">
        <div class="content-title">{{ title }}</div>
        <div class="content" id="content">
          <div class="content-error" v-if="isErrorData">{{ getMessageError }}</div>
          <div class="content-item padding-item">
            <div class="content-item_label">
              <span class="content-item_label--text">{{ $t('b_register_product.title_procedure_item_name') }}</span>
              <span class="content-item_label--require">{{ $t('b_register_product.label_essential') }}</span>
            </div>
            <InputField
              :inputValue="itemName"
              :isError="!validItemName"
              :isReadOnly="isDisabled"
              @focus="validItemName = true"
              @update:inputValue="handleUpdateValue"
            />
          </div>

          <div class="content-devider"></div>

          <div class="content-item gap-0">
            <div class="content-item_label content-item_lable--margin">
              <span class="content-item_label--text"> {{ $t('register_data.table_exhaust_original') }}</span>
              <span class="content-item_label--require">{{ $t('b_register_product.label_essential') }}</span>
            </div>

            <v-radio-group v-model="inputType" :disabled="isDisabled" inline class="casbee-radio" @change="handleTypeChange">
              <div class="radio-part product">
                <v-radio :label="$t('b_register_product.radio_select_use_registered_original_units')" :value="'input_direct'" :ripple="false"></v-radio>
                <div class="btn-register">
                  <CommonButton :isDisable="isDisabled" :label="$t('b_register_product.button_select_the_registered_original_unit')" type="colored" @action="dialogSelectEmission = true"/>
                </div>
              </div>
              <div class="radio-part product margin-radio">
                <v-radio :label="$t('b_register_product.radio_select_directly_enter')" :value="'input_emission'" :ripple="false"></v-radio>
              </div>
            </v-radio-group>


            <div class="content-item-muti-block content-item-padding">
              <div class="content-item-block">
                <div class="content-item-block-label">{{ $t('b_register_product.label_values') }}</div>
                <InputField 
                  :isNumberType="true"
                  :inputValue="emissions"   
                  :isReadOnly="inputType === 'input_direct' || isDisabled"
                  :isError="!validEmission || !validEmissionType"
                  @focus="validEmission = true, validEmissionType = true"
                  @update:inputValue="handleUpdateEmissions"
                />
              </div>
              <div class="content-item-block content-item-width unit">
                <div class="content-item-block-label">{{ $t('b_register_product.label_unit') }}</div>
                <div class="item-unit">{{ unit }}</div>
              </div>
            </div>
          </div>
          <div class="content-devider"></div>

          <div class="content-item">
            <div class="content-item_label">
              <span class="content-item_label--text">{{ $t('b_register_product.title_amount_of_activity') }}</span>
              <span class="content-item_label--require">{{ $t('b_register_product.label_essential') }}</span>
            </div>

            <div class="content-item-muti-block">
              <div class="content-item-block">
                <div class="content-item-block-label">{{ $t('b_register_product.label_values') }}</div>
                <InputField 
                  :isNumberType="true"
                  :inputValue="activity_amount"
                  :isError="!validAmount || !validAmountType"
                  :isReadOnly="isDisabled"
                  @focus="validAmount = true, validAmountType = true"
                  @update:inputValue="handleUpdateAmout" 
                />
              </div>
              <div class="content-item-block unit">
                <div class="content-item-block-label">{{ $t('b_register_product.label_unit') }}</div>
                <InputField 
                  :inputValue="activity_amount_unit" 
                  :isError="!validUnit"
                  :isReadOnly="isDisabled"
                  @focus="validUnit = true"
                  @update:inputValue="handleUpdateAmoutUnit" 
                />
              </div>
            </div>
          </div>

          <div class="content-devider"></div>
        </div>
      </div>
      <div class="action-dialog-popup action-height">
        <div class="left-action"></div>
        <div class="main-action">
					<div class="content-emissions">
						<div class="content-emissions-detail">
							<div class="content-emissions-detail--label">{{ $t('b_register_product.label_emission') }}</div>
							<div class="content-emissions-detail--number">
								{{ formatNumber(totalEmission) || '---' }}
								<span class="unit">t-CO2</span>
							</div>
						</div>
					</div>

					<div class="content-action">
						<CommonButton class="submit-btn" :label="$t('b_register_product.button_add_this_item')" type="colored" @action="handleSubmitData" :isDisable="disableButton || isDisabled"  />
					</div>
				</div>
      </div>
      <SelectEmissionInputFree 
        :dialog="dialogSelectEmission"
        :listLayer="listLayer"
        :durationList="durationList"
        :startMonth="startMonth"
        @close="dialogSelectEmission = false"
        @selectEmissions="handleSelectEmissions" 
      />
    </v-dialog>
  </div>
</template>

<script>
import InputField from '@/components/products/input/InputField.vue';
import CommonButton from '@/components/utils/button.vue';
import { mapState } from 'vuex';
import SelectEmissionInputFree from '@/components/products/dialogs/SelectEmissionInputFree.vue';
import { formatValue, math, formatBigNumber, $_helper_isNumberType, fullWidthToHalfWidth } from '@/concerns/newRegisterData/wijmo.helper';
import {addThoundSandComma} from '@/utils/convertNumber';
export default {
  components: { InputField, CommonButton, SelectEmissionInputFree },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    listLayer: {
      type: Array,
      default: () => []
    },
    processSelect: {
      type: Object,
      default: () => {},
    },
    durationList: {
      type: Array,
      default: () => []
    },
    startMonth: {
      type: Number,
      default: 4,
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      width: '',
      title: this.$t('b_register_product.label_individual_entry_of_each_item'),
      itemName: '',
      price: 6000,
      unitList: [
        {
          text: this.$t('b_register_product.input_field_quantity'),
          value: this.$t('b_register_product.input_field_quantity'),
        },
      ],
      unit: 'tCO2',
      dialogSelectEmission: false,
      activity_amount_unit: null,
      activity_amount: null,
      isErrorData: true,
      inputType: 'input_direct',
      emissions: null,
      totalEmission: null,
      isCheck: false,
      validItemName: true,
      validEmission: true,
      validAmount: true,
      validUnit: true,
      validEmissionType: true,
      validAmountType: true,
    };
  },
  mounted() {
    this.getWidth();
    window.addEventListener('resize', this.getWidth);
    this.$nextTick(() => {
      this.inputType = this.isDisabled ? 'input_emission' : this.processSelect?.input_type ? this.processSelect?.input_type : 'input_direct'
    });
    if(Object.keys(this.processSelect).length) {
      this.itemName = this.processSelect?.process_item_name
      this.emissions = ((this.processSelect?.wsu_value))
      this.activity_amount = (this.processSelect?.activity_amount)
      this.activity_amount_unit = this.processSelect?.activity_amount_unit
      this.inputType = this.processSelect?.input_type
      this.totalEmission = Number(formatValue(this.emissions_val)) * Number(formatValue(this.activity_amount))
    }
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    getMaxWidthDialogByExpand() {
      return this.isExpand ? 'max-width: calc(100% - 236px);' : 'max-width: calc(100% - 72px);'
    },
    getMessageError() {
      let messageError = '';
			if(!this.isCheck) return ''
      if (
        !this.validItemName ||
        !this.emissions ||
        !this.activity_amount || 
        !this.activity_amount_unit
      ) {
				messageError += this.$t('validation.error_required')
      }

      if(!this.validEmissionType || !this.validAmountType) {
        messageError +=  this.$t('validation.error_number_required')
      }

      return messageError
    },
    getTransiTion() {
      if (window.innerWidth > 1024){
        return 'slide-x-reverse-transition'
      } else {
        return 'dialog-bottom-transition'
      }
    },
    disableButton() {
      return this.itemName === "" || this.itemName === null ||
        this.emissions === "" || this.emissions === null ||
        this.activity_amount === "" || this.activity_amount === null ||
        this.activity_amount_unit === "" || this.activity_amount_unit === null
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    },
    handleClickOutSite() {
      this.$emit('close');
    },
    getWidth() {
      if (window.innerWidth > 1024) {
        this.width = this.isExpand ? 'calc(100% - 236px)' : 'calc(100% - 70px)';
        return
      }
      this.width = '100%';
    },
    handleUpdateValue(newVal) {
      this.itemName = newVal;
    },
    handleUpdateEmissions(newVal) {
      this.emissions = newVal;
    },
    handleUpdatePrice(newVal) {
      this.price = newVal;
    },
    handleSelectEmissions(dataItem) {
      this.dialogSelectEmission = false
      this.validEmission = true
      this.emissions = formatValue(dataItem?.wsu_value)
    },
    handleUpdateAmoutUnit(newValue) {
      this.activity_amount_unit = newValue
    },
    handleUpdateAmout(newValue) {
      this.activity_amount = newValue
    },
    handleTypeChange() {
      this.emissions = null;
      this.totalEmission = null;
    },
    formatValueNumber() {
      const isNumberEmissions = $_helper_isNumberType(this.emissions);
      const subStringRang = this.emissions?.includes('-') ? 26 : 25;
      this.emissions = isNumberEmissions ?  formatValue(this.emissions)?.substring(0, subStringRang) : ''

      this.activity_amount = fullWidthToHalfWidth(this.activity_amount || '')
      const isNumberActivityAmount = $_helper_isNumberType(this.activity_amount);
      const subStringRangAmout = this.emissions?.includes('-') ? 26 : 25;
      this.activity_amount = isNumberActivityAmount ?  formatValue(this.activity_amount)?.substring(0, subStringRangAmout) : ''
    },
    isValidData() {
			this.isCheck = true
      this.validItemName = true;
      this.validEmission = true;
      this.validEmissionType = true;
      this.validAmountType = true;
      // this.formatValueNumber()

      if (!this.itemName) {
        this.validItemName = false;
      }

      if (!this.activity_amount_unit) {
        this.validUnit = false;
      }

      if (!this.emissions && this.emissions !== 0) {
        this.validEmission = false;
      } else if(!$_helper_isNumberType(this.emissions)) {
        this.validEmissionType = false;
      }

      if (!this.activity_amount && this.activity_amount !== 0) {
        this.validAmount = false;
      } else if(!$_helper_isNumberType(this.activity_amount)) {
        this.validAmountType = false;
      }

      return (
        this.validItemName && 
        this.validEmission && 
        this.validAmount && 
        this.validUnit && 
        this.validEmissionType &&
        this.validAmountType
      )
    },
    handleSubmitData() {
      if (!this.isValidData()) {
        const element = document.getElementById('content');
        element?.scrollTo(0, 0);
        return;
      }
      
      const data = {
        compilation_method: this.$t('b_register_product.label_apointment_input_individual'),
        process_item_name: this.itemName,
        input_type: this.inputType, // input_direct || input_emission
        wsu_value: this.emissions,
        activity_amount: this.activity_amount,
        activity_amount_unit: this.activity_amount_unit,
        emissions: this.totalEmission,
        index: this.processSelect?.index >= 0 ? this.processSelect?.index : -1,
        compilation_method_code: 'input'
      }
      if(this.processSelect?.id) {
        data.id = this.processSelect?.id
      }
      
      this.$emit('submitDataRegisted', data);
      this.$emit('close');
    },
    formatNumber(num) {
      if([0, '0'].includes(num)) {
        return '---'
      }
      return addThoundSandComma(num)
      // return formatBigNumber(num)
      //   .replace(/[^0-9\.\-]+/g, '')
      //   .replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
    },
    calcTotalEmission() {
      const emissions_val = math.bignumber(formatValue(this.emissions))
      const activity_amount_val = math.bignumber(formatValue(this.activity_amount))
      const emissions = formatBigNumber(math.evaluate(`${emissions_val?.toString()} * ${activity_amount_val?.toString()}`), 23)
      this.totalEmission = emissions?.toString()
    }
  },
  watch: {
    emissions() {
      this.calcTotalEmission()
    },
    activity_amount() {
      this.calcTotalEmission()
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWidth);
  },
};
</script>

<style lang="scss" scoped>
.head-close-btn {
      .btn-close_active{
        display: none;
      }
      &:hover{
        background-color: $goldMid;
        .btn-close_active{
          display: block;
        }
        .btn-close{
          display: none;
        }
      }
    }</style>
