import { CellMaker } from "@mescius/wijmo.grid.cellmaker";
import i18n from '@/lang/i18n.js'
import { getWidthByText } from '@/utils/getWidthByText'
import { getWidthOfDetailButton } from '@/utils/multiLanguage';
import { getWidthByTextContent } from '@/utils/calcTextWidth';
const Process = {
  header(handleIdClick, handleAction, isDisableAction) {
    const actionTableBinding = [
      {
        header: "連番",
        binding: "id", // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: false,
        visible: false
      },
      { 
        header: " ",
        binding: "id",
        minWidth: getWidthOfDetailButton(),
        maxWidth: getWidthOfDetailButton(),
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        multiLine: true,
        isReadOnly: false,
        cssClass: "btn-detail-product",
        cssClassAll: "hide-filter btn-detail-product",
        cellTemplate: CellMaker.makeButton({
          text: i18n.t('list_template.button_detail'),
          click: (event, context) => handleIdClick(context)
        })
      },
      { 
        header: " ",
        binding: "index",
        minWidth: 46,
        maxWidth: 46,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        multiLine: true,
        isReadOnly: false,
        cssClass: "btn-action-product",
        cssClassAll: "hide-filter btn-action-product",
        cellTemplate: CellMaker.makeButton({
          text : "<span id=\"${text}\" class=\"data-table-action\">…</span>",
          click: (event, context) => handleAction(context, event)
        })
      },
    ]

    const contentBinding = [
      {
        header: "連番",
        binding: "id", // id
        minWidth: 40,
        maxWidth: 65,
        allowSorting: false,
        isReadOnly: false,
        visible: false
      },
      { 
        header: i18n.t('b_register_product.table_compilation_method'), // compilation method
        binding: "compilation_method",
        minWidth: getWidthByText(i18n.t('b_register_product.table_compilation_method'), [], 35),
        maxWidth: 320,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        multiLine: true,
        isReadOnly: true,
      },
      {
        header: i18n.t('b_register_product.table_process_item_name'), // thông tin process, tên mục
        binding: "process_item_name",
        minWidth: getWidthByText(i18n.t('b_register_product.table_process_item_name'), [], 35),
        maxWidth: 500,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        isReadOnly: true,
      },
      {
        header: i18n.t('b_register_product.title_amount_of_activity'),
        align: 'center',
        columns: [
          {
            header: i18n.t('b_register_product.table_amount'), // activity amount
            binding: "activity_amount",
            minWidth: getWidthByTextContent(i18n.t('b_register_product.table_amount')),
            maxWidth: 320,
            allowSorting: false,
            isRequired: false,
            wordWrap: true,
            isReadOnly: true,
            align: 'right',
          },
          {
            header: i18n.t('b_register_product.table_quantity_unit'),
            binding: "activity_amount_unit",
            minWidth: getWidthByTextContent(i18n.t('b_register_product.table_quantity_unit')),
            maxWidth: 400,
            allowSorting: false,
            isRequired: false,
            isReadOnly: true,
            wordWrap: true,
            cssClassAll: "hide-filter",
          },
        ]
      },    
      {
        header: i18n.t('b_register_product.table_wsu_value_product'), //đơn vị nguồn thải
        binding: "wsu_value",
        cssClass: "",
        minWidth: getWidthByText(i18n.t('b_register_product.table_wsu_value_product'), [], 35),
        maxWidth: 300,
        allowSorting: false,
        isRequired: false,
        isReadOnly: true,
        wordWrap: true,
        align: 'right',
      },
      {
        header: i18n.t('b_register_product.table_emissions'),
        binding: "emissions",
        cssClass: "auto-increment",
        minWidth: 231,
        maxWidth: 300,
        allowSorting: false,
        isRequired: false,
        isReadOnly: true,
        wordWrap: true,
        align: 'right',
      }
    ]

    if(isDisableAction) {
      return contentBinding
    } else {
      return [...actionTableBinding, ...contentBinding ]
    }
    
  },
}
export default Process;