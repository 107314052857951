<template>
  <div class="product-info">
    <div class="product-info_item">
      <div class="product-info_item--label">{{ $t('b_list_product.table_products_name') }}</div>
      <div class="product-info_item--content">{{ getProductName }}</div>
    </div>
    <div class="product-info_item">
      <div class="product-info_item--label">{{ $t('b_list_product.table_product_code') }}</div>
      <div class="product-info_item--content">{{ getProductCode }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductInfo',
  props: {
    product: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    getProductName() {
      return this.product?.productName || ''
    },
    getProductCode() {
      return this.product?.productCode || ''
    }
  }
}
</script>

<style lang="scss" scoped>
.product-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px 20px;
  gap: 16px;
  width: 100%;
  background: $bgLight;
  margin-bottom: -5px;
  &_item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0px;
    gap: 8px;
    width: 100%;
    &--label {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
    }
    &--content {
      // display: flex;
      // flex-direction: row;
      // align-items: flex-start;
      padding: 7px 16px 9px;
      gap: 8px;

      width: 100%;
      height: 40px;
      border: 1px solid $dividerBlack;
      border-radius: 4px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 100%;
      color: $monoBlack;
    }
  }
}
@include desktop {
  .product-info {
    flex-direction: row;
    padding: 16px 40px;
    gap: 20px;
    &_item {
      width: calc((100% - 20px) / 2);
    }
  }
}
</style>