<template>
  <div>
    <v-dialog
      v-model="getDialog"
      :width="width"
      :max-width="width"
      :right="true"
      :content-class="`product-dialog-popup select-emission input-free ${
        dialog ? 'fullscreen-dialog-transition-open-to' : 'fullscreen-dialog-transition-leave-to'
      }`"
      origin="top left"
      ref="dialog"
      :transition="getTransiTion"
      overlay-color="rgba(0, 0, 0)"
      overlay-opacity="0.5"
      scrollable
    >
      <div class="head-dialog-popup">
        <div class="head-close-btn" @click="getDialog = false">
          <img class="head-image-dialog-popup head-btn-close" src="@/assets/images/dialog/close.svg" />
          <img class="head-image-dialog-popup head-btn-close_active" src="@/assets/images/dialog/close_active.svg" />
        </div>
        <span class="head-title">{{ $t('b_linked_service.title_popup_list_service') }}</span>
      </div>
      <div class="content-dialog-popup content-list-linked">
        <div class="content-title">{{ $t('b_linked_service.description_popup_list_service') }}</div>
        <div class="content-list" id="content">
          <div class="title-list">
            <div class="title-list__content">{{ $t('b_linked_service.title_list_service') }}</div>
            <div class="title-list__description">{{ $t('b_linked_service.description_list_service') }}</div>
          </div>
          <div v-for="(service, index) in listService" class="item-service" :key="`${index}-service`">
            <div>
              <div class="item-service__name">{{ $t('b_linked_service.title_service_lca') }}</div>
              <div class="item-service__description">{{ service.service_name }}</div>
            </div>

            <div class="item-service__action" style="">
              <div class="item-service__action--name">
                {{ $t('b_linked_service.lable_item_lca', { service: service.service_name }) }}
              </div>
              <div>
                <CommonButton
                  class="submit-btn"
                  :label="$t('b_linked_service.button_detail')"
                  @action="checkServiceLinked(service)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </v-dialog>

    <ServiceDetail
      :dialog.sync="dialogDetail"
      :idService="idServiceSelected"
      @close="getDialog = false"
      @updateData="submitData"
    ></ServiceDetail>
    <ErrorPopup :dialog="dialogError" :message="messageError" @submit="dialogError = false" typeColor='redColor' />
  </div>
</template>
    
<script>
import InputField from '@/components/products/input/InputField.vue';
import CommonButton from '@/components/utils/button.vue';
import { mapState } from 'vuex';
import SelectEmissionRegistedPopup from '@/components/products/dialogs/SelectEmissionRegistedPopup.vue';
import DefaultPullDownMenu from '@/components/products/common/pull-down-select.vue';
import DataTable from '@/components/category/data-table';
import ServiceDetail from '@/components/products/dialogs/ServiceDetail.vue';
import { getAllService, checkServiceLinked } from '@/api/linked-service-management';
import ErrorPopup from '@/components/dialogs/notification-popup.vue';

export default {
  components: { InputField, CommonButton, SelectEmissionRegistedPopup, DefaultPullDownMenu, DataTable, ServiceDetail, ErrorPopup },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      width: '',
      listService: [],
      dialogDetail: false,
      dialogError: false,
      idServiceSelected: 0,
      messageError: this.$t('b_linked_service.error_search_error'),
    };
  },
  mounted() {
    this.getWidth();
    this.getServiceList();
    window.addEventListener('resize', this.getWidth);
  },
  methods: {
    getWidth() {
      if (window.innerWidth > 1024) {
        this.width = this.isExpand ? 'calc(100% - 236px)' : 'calc(100% - 72px)';
        return;
      }
      this.width = '100%';
    },

    getServiceList() {
      getAllService().then((res) => {
        this.listService = res.data;
      });
    },

    checkServiceLinked(service) {
      checkServiceLinked({ service_id: service.id }).then((res) => {
        if (res.data) {
          this.dialogDetail = true;
          this.idServiceSelected = service.id;
        } else {
          this.dialogError = true;
        }
      });
    },

    submitData(product){
      this.$emit('addProduct', product)
    }
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    getTransiTion() {
      if (window.innerWidth > 1024) {
        return 'slide-x-reverse-transition';
      } else {
        return 'dialog-bottom-transition';
      }
    },
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWidth);
  },
};
</script>
    
<style lang="scss" scoped>
.select-pulldown {
  position: relative;
  box-sizing: content-box;
}

.animated {
  animation-duration: 0.1s;
  animation-fill-mode: both;
}

.content-list-linked {
  height: 100% !important;
}

.content-list {
  width: 100%;
  display: flex;
  padding: 16px 32px 32px 32px;
  flex-direction: column;
  align-items: flex-start;
  // gap: 24px;
  align-self: stretch;

  .title-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;

    &__content {
      color: $monoBlack;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      /* 175% */
      letter-spacing: 1.6px;
    }

    &__description {
      color: $monoBlack;
      font-size: 10px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  .item-service {
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    border-radius: 4px;
    background: #fff;

    &__name {
      color: $monoBlack;
      font-size: 15px;
      font-style: normal;
      font-weight: 700;
      line-height: 28px;
      /* 186.667% */
      letter-spacing: 0.75px;
    }

    &__description {
      color: $monoBlack;
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      /* 186.667% */
      letter-spacing: 0.75px;
    }

    &__action {
      gap: 8px;

      &--name {
        color: $monoBlack;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;
        /* 183.333% */
        letter-spacing: 0.6px;
      }
    }
  }
}

@include desktop {
  .content-list {
    gap: 24px;
  }

  .item-service {
    width: 636px;

    &__action {
      display: flex;
      padding: 8px 16px;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      align-self: stretch;
    }
  }
}

@media only screen and (max-width: 786px) {
  .content-dialog-popup {
    display: block !important;
  }
  .content-list {
    gap: 32px;
    padding: 16px 20px 20px;

    .title-list {
      gap: 24px;
    }

    .item-service {
      width: 288px;
      margin: 0 auto;

      &__action {
        display: flex;
        flex-direction: column;
      }

      &__description {
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px; /* 183.333% */
        letter-spacing: 0.6px;
      }
    }
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}
</style>
  