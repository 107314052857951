<template>
  <div class="container mt-10">
    <div v-if="displayStatus" class="status-data-details">
      <span class="text-status-details" :class="colorStatusDataDetails()" >{{statusDataDetails()}}</span>
      <span v-if="displayAsTemplateDetails()" class="text-status-details" :class="colorStatusDataDetails()" >{{ templateType }}</span>
    </div>
  </div>
</template>

<script>
import { ROUTES } from '@/router/constants';
import { STATUS_FOR_TEMPLATE, STATUS_FIELD } from '@/constants/status';
import { getStatusNameById, getColorStatusById } from "@/utils/status";

export default {
  props: {
    workFlowData: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      templateType: ''
    };
  },
  computed: {
    displayStatus() {
      if(Object.keys(this.workFlowData).length) {
        return true;
      }
      return false;
    },
  },
  mounted() {
    this.displayAsTemplateDetails();
  },
  methods: {
    statusDataDetails() {
      let status = getStatusNameById(this.workFlowData?.status);

      if(this.$route.query?.type === 'approval' && this.workFlowData?.status === STATUS_FIELD.STATUS_SUBMIT ) {
        status = getStatusNameById(STATUS_FIELD.STATUS_SUBMIT_NON_USER);
      }

      if(this.$route.query?.type === 'approval' && this.workFlowData?.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED ) {
        status = getStatusNameById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER);
      }

      if(this.$route.query?.type === 'template') {
        status =  getStatusNameById(STATUS_FOR_TEMPLATE);
      }
      return status;
    },
    colorStatusDataDetails() {
      if(this.$route.query?.type === 'approval' && this.workFlowData?.status === STATUS_FIELD.STATUS_SUBMIT ) {
        return `color-${getColorStatusById(STATUS_FIELD.STATUS_SUBMIT_NON_USER)}`;
      }

      if(this.$route.query?.type === 'approval' && this.workFlowData?.status === STATUS_FIELD.STATUS_CANCEL_SUBMMITED ) {
        return `color-${getColorStatusById(STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER)}`;
      }

      if(this.$route.query?.type === 'template') {
        return `color-${getColorStatusById(STATUS_FOR_TEMPLATE)}`;
      }

      return `color-${getColorStatusById(this.workFlowData?.status)}`;
    },
    displayAsTemplateDetails() {
      if(this.$route.query?.type === 'template') {
        if(this.workFlowData.template_type) {
          this.templateType = this.$t('b_list_template.pulldown_currently_shared');
        } else {
          this.templateType = this.$t('b_list_template.pulldown_template_private');
        }
        return true;
      }
      return false;
    },
  },
};
</script>

  <style lang="scss" scoped>
@import '~vuetify/src/styles/styles.sass';
.status-data-details {
  margin: 0px 0px 24px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 0px;
  gap: 8px;
    .text-status-details {
    padding: 2px 8px 3px;

    height: 23px;
    // font-family: 'Source Han Sans JP';
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 18px;
    text-align: center;
    letter-spacing: 0.03em;
    border-radius: 2px;
  }
}
.color-blue {
  color: #0072A3;
  border: 1px solid #0072A3;
}
.color-red {
  color: $redMid;
  border: 1px solid $redMid;
}

.color-light-green {
  color: $goldMid;
  border: 1px solid $goldMid;
}
.color-mono-black {
  color: $monoBlack;
  border: 1px solid $monoBlack;
}

.title {
  display: flex;
  flex-direction: column;
  // height: 48px;
  .title-icon {
    max-width: 48px;
    width: 48px;
  }
  .title-description {
    text-align: left;
    font-family: $mainFont;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.05em;
    margin-top: 8px;
    margin-bottom: 10px;
    color: $goldMid;
  }
  .title-icon--tooltip {
    cursor: pointer;
    margin-left: 0;
    // margin-bottom: 48px;
    max-width: 20px;
  }
}
.method-detail {
  font-family: $mainFont;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;
  letter-spacing: 0.05em;
  margin-bottom: 48px;

  color: $monoBlack;
}

.helper-icon {
  width: 20px;
  height: 20px;
  // position: relative;
  margin-top: -6px;
  img {
    z-index: 1;
  }
  &.tooltip {
    display: block;
    justify-content: left;
    background: $monoWhite;
    border-radius: 4px;
    color: $monoBlack;
    margin: 0 auto;
    font-size: 15px;
    padding: 10px;
    height: unset;
    width: calc(100% - 80px);
    max-width: max-content;
    position: absolute;
    right: 0px;
    left: 0px;
    bottom: unset;
    z-index: 9999;
    margin-left: 40px;
    box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
      0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
      0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  }
}

@include desktop {
  .title {
    // margin-top: 40px;
    // margin-bottom: 48px;
    flex-direction: row;
    align-items: center;
    // height: 48px;
    .title-icon {
      margin-top: 0;
      margin-right: 16px;
      margin-bottom: auto;
      max-width: unset;
    }
    .title-description {
      margin-top: -2px;
      margin-bottom: 0;
    }
    .title-icon--tooltip {
      cursor: pointer;
      margin-left: 16px;
      margin-bottom: 0;
      padding-top: 4px;
    }
  }
  .helper-icon {
    margin-left: 16px;
  }
  .status-data-details {
    margin-left: 20px;
  }
}
.container {
  &.tooltip-none {
    font-size: 14px;
    padding: 20px 0px;
  .title {
    .title-icon{
      display: none;
    }
    .title-description {
    text-align: left;
    font-family: $mainFont;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 36px;
    letter-spacing: 0.05em;
    margin-top: 8px;
    margin-bottom: 10px;
    color: $monoBlack;
  }
  .helper-icon {
    display: none;
  }
  }
}
} 
</style>
