import * as wjGrid from "@mescius/wijmo.grid";
import { AutoComplete } from "@mescius/wijmo.input";
import i18n from '@/lang/i18n.js'
import { getWidthByTextContent } from '@/utils/calcTextWidth';
const layerIndex = {
  1: 'business_name',
  2: 'country',
  3: 'layer_3',
  4: 'layer_4',
  5: 'layer_5',
  6: 'layer_6',
}

const Organization = {
  header(layerResponse, listOrganizational, isReadOnly = false, branchData = {}) {
    const listBranch = branchData;
    const layers = layerResponse.map((item, index) => {
      const layerItem = {
        header: item.layer_name,
        visible: true,
        binding: layerIndex[item.layer_index],
        maxWidth: 980,
        minWidth: getWidthByTextContent(item.layer_name) + 5, //202
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        isReadOnly: isReadOnly,
        dataMap: new wjGrid.DataMap(listBranch[layerIndex[item.layer_index]].filter(item => item.status === true), 'key', 'value'),
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: listBranch[layerIndex[item.layer_index]].filter(item => item.status === true),
          selectedValuePath: 'key',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1,
        }),
        cssClassAll: "hide-filter",
      };
      if (index === layerResponse?.length - 1) {
        Object.assign(layerItem, {width: '*'})
      }
      return layerItem
    });
    let companyMap = new wjGrid.DataMap(listBranch.company_name, 'value', 'value');
    companyMap.getDisplayValues = (dataItem) => {
      let validCompany = listBranch?.company_name?.filter(
        (company) => company.organizational_division === dataItem?.organizational_division,
      );

      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    companyMap.getDisplay = (dataItem) => {

      let validCompany = listBranch?.company_name?.filter(
        (company) => company.organizational_division === dataItem,
      );

      return validCompany.map((item) => {
        return {
          value: item.value,
        };
      });
    };

    return [
      {
        header: "#",
        binding: "id",
        allowSorting: false,
        isReadOnly: true,
        visible: false
      },
      {
        header: "契約者名",
        binding: "contractor_name",
        allowSorting: false,
        isRequired: false,
        visible: false
      },
      {
        header: i18n.t('b_register_product.table_organizational_division_product'),
        binding: "organizational_division",
        minWidth: getWidthByTextContent(i18n.t('b_register_product.table_organizational_division_product')),
        maxWidth: 217,
        allowSorting: false,
        dataMap: new wjGrid.DataMap(listOrganizational, "id", "value"),
        isRequired: false,
        editor: new AutoComplete(document.createElement("div"), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: listOrganizational,
          selectedValuePath: "id",
          displayMemberPath: "value"
        }),
        wordWrap: true,
        isReadOnly: isReadOnly,
        cssClassAll: "single-row hide-filter"
      },
      {
        header: i18n.t('b_register_product.table_company_name'),
        binding: 'company_name',
        minWidth: getWidthByTextContent(i18n.t('b_register_product.table_company_name')),
        maxWidth: 980,
        allowSorting: false,
        isRequired: false,
        wordWrap: true,
        dataMap: companyMap,
        cssClassAll: "hide-filter",
        isReadOnly: isReadOnly,
        editor: new AutoComplete(document.createElement('div'), {
          placeholder: i18n.t('56_pattern.placeholder'),
          itemsSource: listBranch.company_name,
          selectedValuePath: 'value',
          displayMemberPath: 'value',
          maxItems: 1000,
          minLength: 1,
          selectedIndex: -1
        }),
      },
      ...layers,
      // ...mapLayers,
      // ...hiddenLayers,
    ];
  }
}
export default Organization;