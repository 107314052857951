<template>
  <div class="container-fluid register-page register-product_page" v-click-outside="updateActionPosition" @click="updateActionPosition">
    <StatusDetail :workFlowData="dataWorkflow" v-if="isGetDetailProduct && !isGetAllData" />
    <div class="title-block" v-if="isGetDetailProduct">
      <h1 class="title-block-head">{{ $t('b_register_product.label_product_detail') }}</h1>
      <div class="update-block" v-if="isShowLatestRecore">
        <div class="item d-flex align-center">
          <img class="item-icon" src="@/assets/images/registerData/lastUpdateIcon.svg" />
          <div class="item-detail">
            <div class="item-title">{{$t('common.last_update')}}</div>
            <p class="item-content">{{ getLastTimeUpdate }}</p>
          </div>
        </div>
        <div class="item d-flex align-center">
          <img class="item-icon" src="@/assets/images/registerData/updateByIcon.svg" />
          <div class="item-detail">
            <div class="item-title">{{$t('common.updated_by')}}</div>
            <p class="item-content">{{ latestRecord.user_updated }}</p>
          </div>
        </div>
      </div>
    </div>

    <div v-else class="produst-page-title mb-8">
      <h1 class="produst-page-title__head">{{ $t('b_register_product.title_new_registration_of_product') }}</h1>
      <common-button class="return__header" v-ripple="false" @action="handlerCloseRegister()">
        <img src="@/assets/images/dialog/return.svg" class="icon-back" />
        <img src="@/assets/images/dialog/return_active.svg" alt="" class="icon-back_active" />
        <div>{{ $t('b_register_product.button_return_to_the_registration_list') }}</div>
      </common-button>
    </div>

    <PageDescription v-if="!isGetDetailProduct" class="my-12" />

    <div class="container selections">
      <div v-if="!isFullScreen" class="select-duration">
        <div class="select-duration-item" :class="{ 'select-duration-item-vi': (isCheckLang === 'vi') }">
          <div class="select-duration-item--label">{{ $t('b_register_product.label_registration_date') }}</div>
          <div v-if="!isDisableEditProduct" class="select-duration-item-require">{{ $t('b_register_product.label_essential') }}</div>
        </div>
        <div class="select-duration-item">
          <select-item
            v-if="isEditMonth"
            v-model="yearSelect"
            :items="yearList"
            class="durations-select-item year-select  mt-0"
            label=""
            dense
            solo
            :disabled="!isEditMonth"
          />
          <select-item
            v-if="isEditMonth"
            :disabled="!isEditMonth"
            class="durations-select-item month-select mt-0"
            label=""
            :items="monthList"
            v-model="monthSelect"
          />
          <div v-if="!isEditMonth" class="year-text view-only">{{ getSelectedYearText() }}</div>
          <div v-if="!isEditMonth" class="month-text view-only">{{ getSelectedMonthText() }}</div>
        </div>
      </div>
      <div v-if="!isFullScreen" class="total-result" :class="methodList.length === 1 && 'max-content'">
        <div class="emissions">
          <div class="total-label">{{ $t('b_register_product.label_emission') }}</div>
          <div class="total-emission" v-if="!isLoading">{{ formatNumber(totalEmissions, true) || '---' }}<span>t-CO2</span></div>
          <v-progress-circular
            v-if="isLoading"
            class="total-emission"
            indeterminate
            color="#A9A04B"
          ></v-progress-circular>
        </div>
        <div class="emissions">
          <div class="total-label has-tooltip">
            <div class="label d-flex align-center">
              {{ $t('b_register_product.label_exhaust_amount_per_product') }}
              <img
                :src="getSettingIcon('helper-icon.svg')"
                @mouseover="changeTooltipImage($event)"
                @mouseleave="changeTooltipImage($event, 'mouseleave')"
                class="ml-2"
                width="18px"
                height="18px"
                alt=""
              />
            </div>

            <div
              v-if="showTooltip"
              class="helper-icon tooltip"
              :class="{'tooltip-vi': isCheckLang === 'vi'}"
              v-html="$t('b_register_product.tooltip_description_exhaust_amount_per_product')"
            ></div>
          </div>
          <div class="total-emission" v-if="!isLoading">{{ formatNumber(amount_per_product, true) || '---' }}<span>t-CO2</span></div>
          <v-progress-circular
            v-if="isLoading"
            class="total-emission"
            indeterminate
            color="#A9A04B"
          ></v-progress-circular>
        </div>
      </div>
    </div>

    <IndividualItemsPopup
      v-show="dialogInputFree"
      :dialog="dialogInputFree"
      :listLayer="listLayer"
      :processSelect="processSelect"
      :durationList="durationList"
      :key="keyApportion + 1"
      :startMonth="startMonth"
      :isDisabled="isDisableInputInvidual"
      @close="dialogInputFree = false"
      @submitDataRegisted="handleUpdateDataToTable"
    />
    <ApportionmentInputPopup
      v-show="dialogApportionment"
      :dialog="dialogApportionment"
      :number="quantity"
      :price="amount"
      :numberAll="total_quantity"
      :priceAll="total_amount"
      :totalEmissionSelect="totalEmissionSelect"
      :dataRegistedSelect="dataRegistedSelect"
      :key="keyApportion"
      :processSelect="processSelect"
      :listUnit="listUnit"
      :listCurency="listCurency"
      :defaultQuantityUnit="quantity_unit"
      :defaultAmountUnit="amount_unit"
      :listLayer="listLayer"
      :durationList="durationList"
      :startMonth="startMonth"
      @updateTotalEmission="handleUpdateEmission"
      @close="dialogApportionment = false"
      @submitDataRegisted="handleUpdateDataToTable"
      @handleUpdateEmissionToTable="handleUpdateEmissionToTable"
    />
    <div v-if="dialogSelectEmission && dialogApportionment" class="select-emission-opacity"></div>
    <dataTable
      :initProductOrganizationGrid="initProductOrganizationTable"
      :initProductInformationGrid="initProductInformationTable"
      :initProductProcessGrid="initProductProcessTable"
      :gridProductOrganizationColumns="facilityGrid"
      :gridProductInformationColumns="informationGrid"
      :gridProductProcessColumns="processGrid"
      :dataProductOrganizationSource="productOrganizationCollection"
      :dataProductInformationSource="productInformationCollection"
      :dataProductProcessSource="productProcessCollection"
      :isEmptySource="isEmptySource"
      :isDetail="isGetDetailProduct"
      :isCreatedDataStatus="true"
      :isEditedData="isChangeDataApproved"
      :isProductSubmit="isProductSubmit"
      :isHasData="isHasData"
      :product="product"
      :dataWorkflow="dataWorkflow"
      :listProductMaster="listProductMaster"
      :totalComment="totalComment"
      :curentPermission="permission"
      :isGetAllData="isGetAllData"
      :selectedYearText="getSelectedYearText()"
      :selectedMonthText="getSelectedMonthText()"
      :yearList="yearList"
      :monthList="monthList"
      :yearSelect="yearSelect"
      :monthSelect="monthSelect"
      :input.sync="yearSelect"
      :value.sync="monthSelect"
      :isEditMonth="isEditMonth"
      :isEditDuration="isEditDuration"
      :is_new_registration="is_new_registration"
      :changedColumns="changedColumns"
      :isReadOnlyTitle="isReadOnlyTitle"
      :unCheckPermission="unCheckPermission"
      :isDisableActionTemplate="isDisableActionTemplate"
      :totalEmissions="totalEmissions"
      :amountPerProduct="amount_per_product"
      @updateProductName="handleUpdateProductName"
      @updateProductCode="handleUpdateProductCode"
      @onHandleActionTable="handleAction"
      @flexGridInitialDone="onFlexGridInitialDone"
      @openSelectEmissionRegister="handleOpenPopupSelectRegister"
      @handleSelectProduct="handleSelectProduct"
      @updateIsRegisterNew="updateIsRegisterNew"
      @updateDatatitle="updateDataTitle"
      @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
      @onHandleShowExportPopup="onHandleShowExportPopup"
      :class="[isFullScreen && 'full-screen', isExpand ? 'expanded' : 'no-expanded', isGetDetailProduct ? 'detail-product' : '']"
    />
    <div ref="scrollElement" >
      <div v-if="!isGetDetailProduct" :class="{'fixed-button-bottom': isFixedButton}">
        <div class="action-table" v-if="!isGetDetailProduct" :class="[isFullScreen && 'register-btn-full-screen', isExpand ? 'register-btn-expanded' : 'register-btn-no-expanded']">
          <common-button class="back-btn" style="width: 100%!important;" v-ripple="false" @action="handlerCloseRegister()">
          <img src="@/assets/images/dialog/return.svg" class="icon-back" />
          <img src="@/assets/images/dialog/return_active.svg" alt="" class="icon-back_active" />
          <div>{{$t("b_register_product.button_return_to_the_registration_list")}}</div>
        </common-button>
        <common-button :isDisable="isDisableSubmit" :label="$t('b_register_product.button_sign_up')" type="colored" @action="handleSaveDraftProduct('post')" />
      </div>
    </div>

    </div>


    <SubmitDataPopup
      :dialog="submitDataDialogPopup"
      :listApprovers="listApprovers"
      :isLoadingSubmitting="isLoadingSubmitting"
      :title="dataTitle"
      @submitHandler="submitRegisterDataHandler"
      @closeDialogSubmitData="submitDataDialogPopup = false"
    />

    <QuestionPopup
      :dialog="dialogQuestion"
      :confirm="true"
      :message="$t('popup.popup_delete_product_data')"
      :confirmText="$t('popup.button_delete')"
      @close="dialogQuestion = false"
      classes="register"
      @submit="submitQuestionPopup()"
    />

    <notification-popup
      :dialog="isShowPopupError"
      :message="getMessageError"
      @submit="isShowPopupError = false"
    />

    <QuestionPopup
      :dialog="dialogDeleteProcess"
      :confirm="true"
      :message="$t('popup.popup_delete_product')"
      :confirmText="$t('popup.button_delete')"
      @close="dialogDeleteProcess = false"
      classes="register"
      @submit="handleAcceptDeleteProcess()"
    />

    <notification-popup
      :dialog="dialogSubmitSuccess"
      :message="messageAfterSubmitSuccess"
      @submit="handleClosePopupSubmitSuccess"
      classes="register"
    />

    <notification-popup
      :dialog="dialogNotification"
      :message="messageNotification"
      @submit="handleCloseNotification"
      classes="register"
    />

    <save-template-data-popup
      :dialog="saveTemplateDataDialogPopup"
      :isCloneTemplate="isCloneTemplate"
      @submitHandler="submitTemplateAction"
      @closeDialogSaveTemplate="saveTemplateDataDialogPopup = false"
    />
    <approval-popup
      :dialog="dialogApprovalData"
      :items="listStatusApproval"
      :confirmText="$t('b_register_product.button_decision')"
      @submit="approvalDataHandler"
      @close="dialogApprovalData = false"
    />

    <QuestionPopup
      :dialog="dialogCancleRequest"
      :confirm="true"
      :closeText="$t('popup.button_cancel')"
      :message="$t('popup.popup_cancel_approve_product')"
      :confirmText="$t('popup.button_cancel_request')"
      @close="dialogCancleRequest = false"
      classes="register"
      @submit="submitCancleRequest()"
    />

    <notification-popup
      :dialog="dialogCancleSuccess"
      :message="messageCancleSuccess"
      @submit="handleClosePopupCancleSuccess"
      classes="register"
    />

    <accept-cancel-popup
      :dialog="dialogAcceptCancel"
      :items="listStatusCancel"
      :confirmText="$t('b_register_product.button_decision')"
      @submit="handleAcceptRequestCancelSubmit"
      @close="dialogAcceptCancel = false"
    />
    <!-- case accept cancle -->
    <notification-popup
      :dialog="dialogAcceptCancleSuccess"
      :message="messageAcceptCancle"
      @submit="handleClosePopupAcceptCancleSuccess"
      classes="register"
    />

    <!-- dialogUpdateApprovalData -->
    <QuestionPopup
      :dialog="dialogUpdateApprovalData"
      :confirm="true"
      :closeText="$t('popup.button_close')"
      :message="messageUpdateApprovalData"
      :confirmText="$t('b_register_product.button_keep')"
      @close="dialogUpdateApprovalData = false"
      classes="register"
      @submit="handleUpdateDataApproval()"
    />

    <!-- dialog save template success -->
    <QuestionPopup
      :dialog="dialogSaveTemplateSuccess"
      :confirm="true"
      :closeText="$t('popup.button_cancel')"
      :message="$t('popup.popup_save_template_product')"
      :confirmText="$t('popup.button_display_product')"
      @close="dialogSaveTemplateSuccess = false"
      classes="register"
      :isSaveTemplateButton="true"
      :isCloneTemplate="false"
      @submit="handleCloseDialogSaveTemplateSuccess()"
    />

    <!-- case override template -->
    <QuestionPopup
      :dialog="dialogOverrideTemplate"
      :confirm="true"
      :closeText="$t('popup.button_cancel')"
      :message="$t('popup.popup_save_edit_template_product')"
      :confirmText="$t('b_register_product.button_keep')"
      :isSaveTemplateButton="false"
      :isCloneTemplate="false"
      @close="dialogOverrideTemplate = false"
      classes="register"
      @submit="handleOverrideTemplate()"
    />

    <!-- case override template -->
    <notification-popup
      :dialog="dialogUpdateTemplate"
      :message="$t('popup.popup_overwrite_template_product')"
      @submit="dialogUpdateTemplate = false"
      classes="register"
    />

    <!-- dialogSaveDraftFromTemplate -->
    <QuestionPopup
      :dialog="dialogSaveDraftFromTemplate"
      :confirm="true"
      :closeText="$t('popup.button_cancel')"
      :message="$t('popup.popup_save_as_new_product')"
      :confirmText="$t('popup.button_save_product')"
      :isSaveTemplateButton="false"
      :isCloneTemplate="false"
      @close="dialogSaveDraftFromTemplate = false"
      classes="register"
      @submit="handleSaveDraftFromTemplate()"
    />

    <!-- dialogSaveDraftFromTemplateSuccess -->
    <notification-popup
      :dialog="dialogSaveDraftFromTemplateSuccess"
      :message="$t('popup.popup_save_draft_from_template')"
      @submit="handlerCloseRegister"
      classes="register"
    />

    <!-- approval -->
    <notification-popup
      :dialog="dialogApprovalDataSuccess"
      :message="messageApprovalDataSuccess"
      @submit="handlerClosePopupApprovalSuccess"
      classes="register"
    />

    <error-popup
      :dialog="warningTemplateDataDialogPopup"
      :message="messageWarningTemplate"
      :isErrorImg="true"
      @submit="closeDialogWarningSaveTemplate"
    />

    <ExportReportPopup
      :key="exportDialogKey"
      :dialog.sync="exportDialog"
      :methodLayer2="null"
      :monthYearSelectionList="monthYearSelectionList"
      :listProductMaster="listProductMaster"
      :productMasterId="productMasterId"
      :title="getExportReportTitle"
      :branchData="branchData"
    />

    <error-popup
      :dialog="isShowPopupUnCheckPermission"
      :message="$t('popup.popup_dont_have_permission_product')"
      @submit="isShowPopupUnCheckPermission = false"
    />

    <notification-popup
      :dialog="dialogDeleteDataApproval"
      :message="messageDialogDeleteDataApproval"
      @submit="dialogDeleteDataApproval = false"
    />

    <LinkedService v-if="linkedServiceDialog" :dialog.sync="linkedServiceDialog" @addProduct="onAddProduct"></LinkedService>

    <div class="pull-down-action" :style="getStyleActionPullDown" @click="handleDeleteProcess">
      <div class="pull-down-action-item">
        <img class="pull-down-action-item__icon" src="@/assets/icons/product/delete.svg" alt="">
        <img class="pull-down-action-item__icon active" src="@/assets/icons/product/delete-active.svg" alt="">
        <div class="pull-down-action-item__label">{{ $t('register_data.button_delete') }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { formatDateTime } from '@/utils/datetimeFormat';
import moment from 'moment';
import { ROUTES } from '@/router/constants';
import ApportionmentInputPopup from '@/components/products/dialogs/ApportionmentInputPopup.vue';
import dataTable from '@/components/products/data-table';
import Organization from '@/concerns/products/organization';
import Information from '@/concerns/products/information';
import Process from '@/concerns/products/process';
import SelectItem from '@/components/utils/selectItem.vue';
import { getListDetailBranch } from '@/api/registerData';
import { getListOrganizationalApi } from '@/api/facility';
import { getProductNotification, updateProductNotification } from "@/api/product";
import { handlerAddFilterData, handlerFilterData, getBranchId } from '@/concerns/utils/filter-data';
import { UndoStack } from '@mescius/wijmo.undo';
import IndividualItemsPopup from '@/components/products/dialogs/IndividualItemsPopup.vue';
import PageDescription from '@/components/products/common/page-description.vue';
import CommonButton from '@/components/utils/button.vue';
import StatusDetail from '@/components/products/common/status-detail.vue';
import SubmitDataPopup from '@/components/dialogs/ActionHeaderTable/submit-data-popup';
import QuestionPopup from '@/components/dialogs/question-popup';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import NotificationPopup from '@/components/dialogs/notification-popup';
import SaveTemplateDataPopup from '@/components/dialogs/ActionHeaderTable/save-template-data-popup';
import ApprovalPopup from '@/components/dialogs/approval-popup';
import AcceptCancelPopup from '@/components/dialogs/accept-cancel-popup';
import ExportReportPopup from '@/components/products/dialogs/ExportReportPopup.vue';
import { actionProductWorkflow, getProductDetail, getUnitListApi, getCurencyListApi, getProductMasterApi, updateProductApproved, deleteProductData } from '@/api/product';
import { formatValue, math, formatBigNumber, $_helper_isNumberType, fullWidthToHalfWidth } from '@/concerns/newRegisterData/wijmo.helper';
import { KEYS_CODE } from '@/constants/keyboard'
import { ACTION_HEADER_TABLE, APPROVAL_TYPE, CANCEL_TYPE } from '@/constants/registerData';
import * as wjGrid from '@mescius/wijmo.grid';
import * as wjcCore from '@mescius/wijmo';
import { Control } from "@mescius/wijmo";
import { Tooltip, PopupPosition, CollectionView } from "@mescius/wijmo";
import { toolTipCustom } from "@/utils/tooltipCustom";
import { CellEditEndingEventArgs, CellRange } from "@mescius/wijmo.grid";
import { STATUS_SUBMIT, STATUS_RETURN, STATUS_REPRENSENT_RETURN, STATUS_FIELD, STATUS_CANCEL_SUBMMITED } from '@/constants/status';
import { ROLE } from '@/constants/role';
import {addThoundSandComma, addThoundSandCommaWithTwoDigit} from '@/utils/convertNumber';
import { calcYearFromStartMonthCustome, getMonthListCustome, convertMonthCalendar, prepareBranchData } from '@/utils/registerData';
import { getStartMonth } from '@/api/duration';
import { MOBILE_WIDTH } from '@/constants/screen-size';
import { setMinMaxSizeColumns } from '@/utils/calcTextWidth';
import i18n from '@/lang/i18n';
import LinkedService from '@/components/products/dialogs/LinkedService.vue';
import isEmpty from 'lodash/isEmpty';
const VIEW = 'view'
const EDIT = 'edit'
const NOT_VIEW = 'not-view'
export default {
  components: {
    ApportionmentInputPopup,
    dataTable,
    SelectItem,
    IndividualItemsPopup,
    PageDescription,
    CommonButton,
    StatusDetail,
    SubmitDataPopup,
    QuestionPopup,
    ErrorPopup,
    NotificationPopup,
    SaveTemplateDataPopup,
    ApprovalPopup,
    AcceptCancelPopup,
    ExportReportPopup,
    LinkedService
  },
  data() {
    return {
      breadcrumb: [],
      latestRecord: {
        updated_at_latest: '',
        user_updated: '',
      },
      selectedDurationText: null,
      durationList: [],
      selectMonthYearChange: null,
      monthYearSelectionList: [],
      selectDurationChange: null,
      methodList: [],
      totalEmissions: 0,
      dialogApportionment: false,
      dialogSelectEmission: false,
      dialogQuestion: false,
      key: 0,
      layerData: [],
      productOrganizationFlexgrid: null,
      productInformationFlexgrid: null,
      productProcessFlexgrid: null,
      productOrganizationCollection: null,
      productInformationCollection: null,
      productProcessCollection: null,
      organizationPattern: Organization,
      informationPattern: Information,
      processPattern: Process,
      listLayer: [],
      listOrganizational: [],
      isEmptySource: true,
      undoStack: null,
      canUndo: false,
      canRedo: false,
      showTooltip: false,
      dialogInputFree: false,
      isEditedData: 1,
      isHasData: false,
      tableTypes: ['Organization', 'Information', 'Process'],
      totalEmissionSelect: 0,
      dataRegistedSelect: [],
      keyApportion: 0,
      keyInputFree: 0,
      product: {
        productName: null,
        productCode: null
      },
      quantity: null,
      total_quantity: null,
      amount: null,
      total_amount: null,
      amount_per_product: 0,
      amount_unit: 1,
      quantity_unit: null,
      submitDataDialogPopup: false,
      hdrTips             : new Tooltip({
        position   : PopupPosition.Above,
        showAtMouse: true,
        showDelay  : 600,
        cssClass   : "hdr-tip"
      }),
      processSelect: {},
      status: null,
      dataWorkflow: {},
      processClick: {
        isShowAction: false
      },
      processFormat: [],
      informationFormat: [],
      organizationsFormat: [],
      listUnit: [],
      listCurency: [],
      productMasterId: null,
      listProductMaster: [],
      isRegisterNew: true,
      productId: null,
      isShowPopupError: false,
      listErrorMessage: [],
      dialogDeleteProcess: false,
      dialogNotification: false,
      messageNotification: this.$t('register_data.popup_message_save_change'),
      saveTemplateDataDialogPopup: false,
      isCloneTemplate: false,
      dialogApprovalData: false,
      listStatusApproval: [],
      dialogSubmitSuccess: false,
      messageAfterSubmitSuccess: this.$t('register_data.popup_message_submit_sucess'),
      totalComment: 0,
      oldProductName: null,
      permission: 'edit',
      layerBinding: [],
      isUpdateDraft: false,
      facilityGrid: null,
      processGrid: null,
      informationGrid: null,
      indexRowDelete: 0,
      is_new_registration: true,
      dialogCancleRequest: false,
      dialogCancleSuccess: false,
      messageCancleSuccess: this.$t('popup.button_cancel_approve_product'),
      dialogAcceptCancel: false,
      listStatusCancel: [],
      dialogAcceptCancleSuccess: false,
      messageAcceptCancle: '',
      oldData: {},
      messageUpdateApprovalData: this.$t('register_data.popup_message_update_approved_data'),
      dialogUpdateApprovalData: false,
      productApprovedId: null,
      dialogSaveTemplateSuccess: false,
      dataTitle: '',
      changedColumns: null,
      selectedFilterColumn: null,
      dialogOverrideTemplate: false,
      dialogUpdateTemplate: false,
      dialogSaveDraftFromTemplate: false,
      dialogSaveDraftFromTemplateSuccess: false,
      dialogApprovalDataSuccess: false,
      messageApprovalDataSuccess: '',
      warningTemplateDataDialogPopup: false,
      messageWarningTemplate:this.$t('register_data.popup_message_warning_unshare_template'),
      exportDialog: false,
      exportDialogKey: 0.2,
      unCheckPermission: false,
      isShowPopupUnCheckPermission: false,
      isBranchUncheck: false,
      flg_permission: false,
      monthList: [],
      yearList: [],
      yearSelect: null,
      monthSelect: null,
      startMonth: null,
      isFixedButton: false,
      branchData: {},
      dialogDeleteDataApproval: false,
      messageDialogDeleteDataApproval: '',
      isSubmitToMyseft: false,
      isCheckLang: i18n.locale,
      linkedServiceDialog: false,
      isDisableInputInvidual: false,
    };
  },
  computed: {
    ...mapState('registerData', ['isLoading', 'isFullScreen']),
    ...mapState('userData', ['contractor', 'currentUser']),
    ...mapState('actionsTable', ['startAction']),
    ...mapState('approval', ['listApprovers']),
    ...mapState('commonApp', ['isExpand']),
    ...mapGetters('newRegisterData', ['isLoadingSubmitting']),
    isDisableEditProduct() {
      return this.isProductSubmit || this.permission !== 'edit' && this.$route.query?.id
    },
    getLastTimeUpdate() {
      return this.dateTimeFormat(this.latestRecord?.updated_at_latest);
    },

    isGetDetailProduct() {
      return this.$route.query?.id ? true : false;
    },

    isProductSubmit() {
      return this.$route.query?.id && this.status === STATUS_FIELD.STATUS_SUBMIT ? true : false;
    },

    isDisableSubmit() {
      return ((!this.product?.productName || this.product?.productName?.length <= 0) || !this.monthSelect || !this.yearSelect || this.isEmptySource) ? true : false
    },

    isShowAction() {
      return this.processClick?.isShowAction
    },

    getStyleActionPullDown() {
      const position = this.processClick
      return `left: ${position.leftPosition - 16}px; top: ${position.topPosition + 27}px; display: ${this.isShowAction ? 'flex' : 'none'}`
    },

    isGetAllData() {
      return this.$route.query?.type === 'view'
    },

    isHiddenOverFlow() {
      return this.dialogInputFree || this.dialogApportionment || this.dialogSelectEmission || this.dialogInputFree
    },

    getMessageError() {
      return this.listErrorMessage.join('\n')
    },

    isReadOnlyFacility() {
      return !this.isRegisterNew || this.permission === VIEW || !this.is_new_registration || this.$route.query.type === 'template';
    },

    isAdmin() {
      return this.currentUser?.user?.role_id === ROLE.ADMIN
    },

    isApproval() {
      return this.currentUser?.user?.role_id === ROLE.APPROVAL
    },

    isOnwer() {
      return this.dataWorkflow.is_owner === 1
    },

    isEditMonth() {
      if(!this.$route.query.id) {
        return true
      } else {
        return this.permission === EDIT
      }
    },

    isSubmitFromTemplate() {
      return this.$route.query?.type === 'template'
    },

    isChangeDataApproved() {
      const oldData = this.oldData
      return (this.isEditedData ||
        oldData.code !== this.product.productCode ||
        oldData.name !== this.product.productName ||
        oldData.year !== this.yearSelect ||
        oldData.month !== this.monthSelect) ? 1 : 0
    },

    isEditDuration() {
      return [STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(this.status)
    },

    isReadOnlyTitle() {
      return [
          STATUS_FIELD.STATUS_SUBMIT,
          STATUS_FIELD.STATUS_REPRENSENT_APPROVED,
          STATUS_FIELD.STATUS_APPROVED,
          STATUS_FIELD.STATUS_CANCEL_SUBMMITED
        ].includes(this.status);
    },

    getExportReportTitle() {
      if(this.product?.productCode) {
        return this.$t('b_export_report.label_output_the_data', { nameCode: this.product.productName + this.product.productCode })
      } else {
        return this.$t('b_export_report.label_output_the_data', { nameCode: this.product.productName })
      }
    },

    isShowLatestRecore() {
      return this.latestRecord.updated_at_latest && this.latestRecord.user_updated
    },

    isDisableActionTemplate() {
      if(!this.dataWorkflow.is_owner && !this.flg_permission && this.$route.query?.type === 'template') {
        return false
      }
      return !this.flg_permission && this.$route.query?.type === 'template'
    }
  },
  async mounted() {
    this.actionUpdateIsLoading(true)
    await Promise.all([
      this.getDetailBranch(),
      this.getApproversList(),
      this.getDataOrganization(),
      this.getListUnit(),
      this.getListCurentCy(),
      this.getListProductMaster()
    ])
    this.createUndoStack();
    if (this.isGetDetailProduct) {
      await this.getProductDetail(this.$route.query.id);
      if(this.$route.query?.is_allow_cancel) {
        await this.handleApprovalCancleSubmit();
      }
    }
    this.updateBreadCrumbByType();
    await this.getListDurations();
    this.getGridColumns()
    document.addEventListener('scroll', this.updateActionPosition);
    if (window.innerWidth < MOBILE_WIDTH) {
      this.isFixedButton = true;
    }
    this.actionUpdateIsLoading(false)
  },
  methods: {
    ...mapActions('commonApp', ['updateBreadCrumb', 'actionUpdateIsLoading']),
    ...mapActions('approval', ['getApproversList']),
    ...mapActions('actionsTable', ['actionUpdate', 'actionUpdateStatusBtn', 'updateActionDelete']),
    ...mapActions("product", ["actionUpdateNotification"]),
    updateBreadCrumbByType() {
      const type = this.$route.query?.type;
      let breadCrumbName = {
        text: this.$t('b_register_product.hyperlink_product_registration'),
        disabled: false,
        href: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_EMISSIONS,
      }
      let productName = {
        text: this.$t('b_register_product.label_new_registration_of_product'),
        disabled: true,
        href: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_EMISSIONS,
      }
      if (this.product.productName) {
        productName.text = `${this.$t('b_register_product.label_product_detail')}: ${this.product?.productName}${this.product?.productCode ? '(' + this.product?.productCode + ')' : ''}`;
      }
      if(!type || ['detail'].includes(type)) {
        breadCrumbName.text = this.$t('b_register_product.hyperlink_product_registration');
        breadCrumbName.href = ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_EMISSIONS;
      } else if(type === 'approval') {
        breadCrumbName.text = this.$t('b_list_approval.label_approval_of_emissions');
        breadCrumbName.href = ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_APPROVAL;
        if ([STATUS_FIELD.STATUS_CANCEL_SUBMMITED, STATUS_FIELD.STATUS_CANCEL_SUBMMITED_NON_USER].includes(this.status)) {
          breadCrumbName.text = this.$t('b_list_approval.label_approval_of_emissions');
        }
      } else if(type === 'template') {
        breadCrumbName.text = this.$t('b_list_template.label_template');
        breadCrumbName.href = ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_TEMPLATES;
      } else if (type === 'submit') {
        breadCrumbName.text =  this.$t('b_list_submited.label_application_status');
        breadCrumbName.href = ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_SUBMITTED;
      } else if (type === 'view') {
        breadCrumbName.text = this.$t('left_menu.button_list_product_emissions');
        breadCrumbName.href = ROUTES.PRODUCTS + '/' + ROUTES.LIST_PRODUCTS;
      }

      this.breadcrumb = [
        {
          text:this.$t('b_register_product.hyperlink_home'),
          disabled: false,
          href: ROUTES.PRODUCTS,
        },
        {
          text: breadCrumbName.text,
          disabled: false,
          href: breadCrumbName.href,
        },
        {
          text: productName.text,
          disabled: false,
          href: productName.href,
        },
      ]
      this.updateBreadCrumb(this.breadcrumb);
    },
    updateDataTitle(title) {
      this.dataTitle = title
    },
    getCurentPermission() {
      let permission = EDIT
      if(this.status === STATUS_FIELD.STATUS_DRAFT) {   //case draft
        if(this.isOnwer) {
          permission = EDIT
        } else if(this.isAdmin) {
          permission = VIEW
        } else {
          permission = NOT_VIEW
        }
      }

      if([STATUS_FIELD.STATUS_SUBMIT, STATUS_FIELD.STATUS_CANCEL_SUBMMITED].includes(this.status)) { // case subbmit
        if(this.isOnwer || this.isAdmin || this.isApproval) {
          permission = VIEW
        } else {
          permission = NOT_VIEW
        }
      }

      if([STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(this.status)) {  // case approved
        if(this.isAdmin) {
          permission = EDIT
        } else {
          permission = VIEW
        }
      }

      this.permission = permission
      return permission
    },
    getGridColumns() {
      this.getGridFacility()
      this.getGridProcess()
      this.getGridInformation()
    },
    getGridFacility() {
      this.facilityGrid = this.organizationPattern.header(this.listLayer, this.listOrganizational, this.isReadOnlyFacility, this.branchData)
    },
    getGridProcess() {
      this.processGrid = this.processPattern.header(this.handleIdClick, this.handleActionProcess, this.permission === 'view')
    },
    getGridInformation() {
      this.informationGrid = this.informationPattern.header(this.listUnit, this.listCurency, this.permission === 'view')
    },
    updateActionPosition() {
      this.processClick.isShowAction = false;
      if (window.innerWidth < 450) {
        const scrollElementTop = this.$refs.scrollElement.offsetTop; // Vị trí của phần tử
        const scrollPosition = window.scrollY + window.innerHeight; // Vị trí scroll hiện tại
        if (scrollPosition > (scrollElementTop + 215)) { // scrollElementTop + 215 ===  the space between the buttons with top screen
          this.isFixedButton = false;
        } else {
          this.isFixedButton = true;
        }
      }
    },
    async getListProductMaster() {
      try {
        const listProductMaster = await getProductMasterApi();
        this.listProductMaster = listProductMaster?.data || [];
      } catch (error) {
        console.warn(error)
      }
    },
    async getListUnit() {
      try {
        const listUnit = await getUnitListApi();
        this.listUnit = listUnit?.data || [];
      } catch (error) {
        console.warn(error)
      }
    },
    async getListCurentCy() {
      try {
        const listCurency = await getCurencyListApi();
        this.listCurency = listCurency?.data || [];
      } catch (error) {
        console.warn(error)
      }
    },
    async getDataOrganization() {
      try {
        const listOrganizationalApiResponse = await getListOrganizationalApi();
        this.getListOrganization(listOrganizationalApiResponse.data);
      } catch (error) {
        console.warn(error)
      }
    },
    async getProductDetail(id) {
      try {
        const productDetail = await getProductDetail(id)
        this.setDataFromProductDetail(productDetail)
        this.getCurentPermission()
      } catch (error) {
        console.warn(error);
        const type = this.$route.query?.type
        if(type === 'view') {
          this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.LIST_PRODUCTS })
        } else if (type === 'template') {
          this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_TEMPLATES })
        } else if (type === 'approval') {
          this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_APPROVAL })
        } else if (type === 'submit') {
          this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_SUBMITTED })
        } else {
          this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_EMISSIONS })
        }
      }
    },
    setDataFromProductDetail(productDetail) {
      const product = productDetail.data.product
      const product_workflow = productDetail.data.product_workflow
      const information = {
        ...productDetail.data.production,
        // amount: productDetail.data.production.amount?.toString()?.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,'),
        // quantity: productDetail.data.production.quantity?.toString()?.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,'),
        // amount_per_product: productDetail.data.production.amount_per_product?.toString()?.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,'),
        // total_quantity: productDetail.data.production.total_quantity?.toString()?.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,'),
        // total_amount: productDetail.data.production.total_amount?.toString()?.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,'),
        amount: addThoundSandComma(productDetail.data.production.amount),
        quantity: addThoundSandComma(productDetail.data.production.quantity),
        amount_per_product: addThoundSandComma(productDetail.data.production.amount_per_product),
        total_quantity: addThoundSandComma(productDetail.data.production.total_quantity),
        total_amount: addThoundSandComma(productDetail.data.production.total_amount),
      }
      const product_master = product?.product_master || {}

      this.latestRecord =  productDetail?.latest_record
      this.monthSelect = product_workflow.month;
      this.yearSelect = product_workflow.year;
      // this.selectDurationChange = product_workflow.duration_id
      this.totalComment = product_workflow.comment_count
      this.quantity = formatValue(information.quantity)
      this.amount = formatValue(information.amount)
      this.amount_unit = Number(information.amount_unit)
      this.quantity_unit = Number(information.quantity_unit) || null
      this.total_amount = formatValue(information.total_amount)
      this.total_quantity = formatValue(information.total_quantity)
      this.totalEmissions = product.emission_total_product
      this.amount_per_product = product.emission_per_product
      this.listLayer = productDetail.layer
      this.status = product_workflow.status
      this.dataWorkflow = product_workflow
      this.unCheckPermission = productDetail?.check_permission;
      this.is_new_registration = product.is_new_registration
      this.isBranchUncheck = product_master?.branch?.status || false
      this.flg_permission = productDetail.data.flg_permission
      if([STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(this.status)) {
        this.changedColumns = productDetail.data.changed_columns
        this.isEditedData = 0
        this.oldData = {
          name: product_master?.name,
          code: product_master?.code,
          duration: product.duration_id,
          month: product.month,
        }
        this.monthSelect = product.month;
        this.yearSelect = product.year;
        this.selectDurationChange = product.duration_id;
        this.productApprovedId = product.id
      }

      if(product_workflow.unread_flg) {
        this.handleUpdateNotification(product_workflow)
      }

      if (this.currentUser?.user?.role_id !== ROLE.USER && (product_workflow.status === STATUS_SUBMIT || product_workflow.status === STATUS_CANCEL_SUBMMITED)) {
        this.isShowPopupUnCheckPermission = this.unCheckPermission;
      }
      this.isHasData = true
      this.productMasterId = product_master.id
      this.oldProductName = product_master.name
      this.product = {
        productName: product_master.name,
        productCode: product_master.code
      }

      this.isEmptySource = productDetail.data.process.length === 0
      const dataBranch = this.status === STATUS_FIELD.STATUS_DRAFT ? product : product_master?.branch
      const organizations = [{
        id: product.id,
        business_name: dataBranch?.business_name,
        company_name: dataBranch?.company_name,
        country: dataBranch?.country,
        layer_3: dataBranch?.layer_3,
        layer_4: dataBranch?.layer_4,
        layer_5: dataBranch?.layer_5,
        layer_3: dataBranch?.layer_3,
        layer_6: dataBranch?.layer_6,
        organizational_division: dataBranch?.organizational_division,
        product_workflow_id: product.product_workflow_id
      }]

      const informations = [ information ]
      const processData = productDetail.data.process?.map((item, index)=> {
        const number_production_unit = item.number_production_unit ? parseInt(item.number_production_unit) : null;
        const number_total_production_unit = item.number_total_production_unit ? parseInt(item.number_total_production_unit) : null;
        return {
          ...item,
          index: index + 1,
          compilation_method: item.compilation_method === 'apointment_input' ? this.$t('b_register_product.label_apointment_input_minute') : item.compilation_method === 'input_service_link' ? this.$t('b_linked_service.smartlca_method') : this.$t('b_register_product.label_apointment_input_individual'),
          number_production_unit: number_production_unit,
          number_total_production_unit: number_total_production_unit,
          wsu_value: item.compilation_method === 'input_service_link' ? item?.wsu_value?.toString() : addThoundSandComma(item?.wsu_value) ,
          emissions: addThoundSandComma(item?.emissions),
          emissions_val: item?.emissions,
          compilation_method_code: item.compilation_method
        }
      })

      this.processFormat = [ ...processData ]
      this.informationFormat = [ ...informations ]
      this.organizationsFormat = [ ...organizations ]

      this.initialView(organizations, informations, processData);
    },
    dateTimeFormat(dateTime) {
      return formatDateTime(dateTime);
    },
    getSettingIcon(image) {
      if (image) {
        return require(`@/assets/icons/${image}`);
      }
      return '';
    },
    handleUpdateProductName(productName) {
      this.product.productName = productName
      this.checkTableHasData('Process');
    },
    handleUpdateProductCode(productCode) {
      this.product.productCode = productCode
    },
    handleUpdateEmissionToTable(data) {
      this.dataRegistedSelect = data
      this.totalEmissionSelect = data.reduce((a, b) => {
        return formatBigNumber(math.evaluate(`${formatValue(a?.toString())} + ${formatValue(b?.emissions)}`), 23)
        // return Number(formatValue(a)) + Number(formatValue(b?.emissions));
      }, 0)
    },
    handleUpdateEmission(newVal) {
      this.totalEmissionSelect = newVal
    },
    changeTooltipImage(event, type) {
      if (type === 'mouseleave') {
        event.target.src = this.getSettingIcon('helper-icon.svg');
        event.target.parentElement.style.cursor = 'default';
        this.showTooltip = false;
      } else {
        event.target.src = this.getSettingIcon('helper-icon_active.svg');
        event.target.parentElement.style.cursor = 'pointer';
        this.showTooltip = true;
      }
    },
    getDefaultYear() {
      if(!this.$route.query?.id) {
        const currentDate = new Date();
        const currentMonth = currentDate.getMonth() + 1;
        let currentYear = currentDate.getFullYear();
        if (this.startMonth > currentMonth) {
          currentYear = currentYear - 1;
        }
        this.yearSelect = currentYear;
      }
    },
    getListDurations() {
      getStartMonth(this.$store.state.userData.contractor).then((res) => {
        this.startMonth = res.data.start_month
        this.getDefaultYear();
        if(!this.$route.query?.id) {
          this.monthSelect = this.startMonth
        }
        this.yearList = calcYearFromStartMonthCustome(this.startMonth, this.yearSelect)
        this.monthList = getMonthListCustome(this.yearSelect, this.startMonth);
      });
    },
    getSelectedYearText() {
      return this.$t('register_data.year_calendar_select', {year: this.yearSelect});
    },
    getSelectedMonthText() {
      if (this.monthSelect) {
        return this.$t('register_data.month_select', { month: convertMonthCalendar(this.monthSelect)});
      }
      return `--${this.$t('b_register_product.label_month')}`;
    },
    getMonthSelectionList(startAt, endAt) {
      let durationStartAt = new Date(startAt);
      let durationEndAt = new Date(`${endAt}-02`);

      let timeFormat = durationStartAt.toLocaleString('ja-JP', {
        month: 'long',
        year: 'numeric',
      });

      this.monthYearSelectionList = [
        {
          text: timeFormat,
          value: durationStartAt.getMonth() + 1,
          year: parseInt(startAt.slice(0, 4)),
          yearText: parseInt(startAt.slice(0, 4)) + $t('b_register_product.label_year'),
          monthText: durationStartAt.getMonth() + 1 + this.this.$t('b_register_product.label_month')
        },
      ];
      while (durationStartAt.setMonth(durationStartAt.getMonth() + 1) < durationEndAt) {
        let localeString = durationStartAt.toLocaleString('ja-JP', {
          month: 'long',
          year: 'numeric',
        });

        let currentMonthYear = {
          text: localeString,
          value: durationStartAt.getMonth() + 1,
          year: parseInt(endAt.slice(0, 4)),
          yearText: parseInt(endAt.slice(0, 4)) + this.$t('b_register_product.label_year'),
          monthText: durationStartAt.getMonth() + 1 + this.$t('b_register_product.label_month')
        };
        this.monthYearSelectionList.push(currentMonthYear);
      }
    },
    isCreateNewData() {
      return false;
    },
    // formatNumber(num) {
    //   if(num === 0) {
    //     return '---'
    //   }
    //   return addThoundSandComma(num);
    // },
    formatNumber(num, isAlowMore25digit = false) {
      if(num === 0) {
        return '---'
      }
      return addThoundSandCommaWithTwoDigit(formatBigNumber(num, 35).replace(/[^0-9\.\-]+/g, ''), '', false, isAlowMore25digit);
    },
    initProductOrganizationTable(flexgrid) {
      this.productOrganizationFlexgrid = flexgrid;
      let filterIndex = {};
      let selectedFirst = null;
      let previousCellData = null;

      flexgrid.cellEditEnded.addHandler((s, e) => {
        let column = s.columns[e.col];
        const { row, col } = e.range;
        const cellData = s.getCellData(row, col, false);
        const view = s.collectionView;
        const source = view.sourceCollection;
        const currentItem = source[row] || {};

        handlerFilterData(
          s, e,
          this.$store.state.registerData?.listBranch?.filterPatternList,
          filterIndex,
          selectedFirst,
          previousCellData,
          false,
        );
        if([STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(this.status)) {
          this.isEditedData = 1
        }
      });

      flexgrid.beginningEdit.addHandler((s, e) => {
        let column = s.columns[e.col];

        const { row, col } = e.range;
        const cellData = s.getCellData(row, col, false);
        const view = s.collectionView;
        const source = view.sourceCollection;
        const currentItem = source[row] || {};
        previousCellData = cellData;
        handlerAddFilterData(
          s, e,
          filterIndex,
          selectedFirst,
          this.$store.state.registerData,
          this.branchData
        );
      });
      flexgrid.gotFocus.addHandler(() => {
        this.productInformationFlexgrid.select(-1 , -1);
        this.productProcessFlexgrid.select(-1, -1);
      })
    },
    initProductInformationTable(flexgrid) {
      this.productInformationFlexgrid = flexgrid;
      flexgrid.gotFocus.addHandler(() => {
        this.productOrganizationFlexgrid.select(-1, -1);
        this.productProcessFlexgrid.select(-1, -1);
      })
    },
    initProductProcessTable(flexgrid) {
      this.productProcessFlexgrid = flexgrid;
      this.highlightChangedRows();
      flexgrid.gotFocus.addHandler(() => {
        this.productOrganizationFlexgrid.select(-1, -1);
        this.productInformationFlexgrid.select(-1 , -1);
      })
    },
    initialView(organizations = [{}], informations = [{ amount_unit: 1}], process = []) {
      this.productOrganizationCollection = new CollectionView(organizations, {
        trackChanges: true,
      });
      this.productOrganizationCollection.currentItem = null;
      this.productInformationCollection = new CollectionView(informations, {
        trackChanges: false,
      });
      this.productInformationCollection.currentItem = null;
      const formatItems = ['activity_amount', 'wsu_value', 'emissions'];
      this.productProcessCollection = new CollectionView(process, {
        trackChanges: true,
        sortComparer: (a, b) => {
          if (!formatItems.includes(this.selectedFilterColumn)) return null;
          if ($_helper_isNumberType(a) && $_helper_isNumberType(b)) {
            const numA = Number(formatValue(a));
            const numB = Number(formatValue(b));
            return numA - numB;
          }
          return null;
        }
      });
      this.productProcessCollection.currentItem = null;
      setMinMaxSizeColumns(this.productOrganizationFlexgrid, organizations);
      setMinMaxSizeColumns(this.productInformationFlexgrid, informations);
      setMinMaxSizeColumns(this.productProcessFlexgrid, process);
    },

    highlightChangedRows() {
      if (this.informationFormat !== undefined) {
        this.productInformationFlexgrid.formatItem.addHandler((handler, eventHandler) => {
          if (
            eventHandler.panel.cellType === wjGrid.CellType.Cell ||
            eventHandler.panel.cellType === wjGrid.CellType.RowHeader
          ) {
            let currentItemChanged = this.changedColumns?.production || {};
            if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
              if (Object.keys(currentItemChanged).length) {
                eventHandler.cell.classList.add('wj-has-changed-mark');
              }
            } else {
              let column = handler.columns[eventHandler.col];
              if (Object.keys(currentItemChanged).length && (Object.keys(currentItemChanged).includes(column.binding))) {
                let tooltip = new wjcCore.Tooltip();
                eventHandler.cell.classList.add('wj-has-changed');
                tooltip.setTooltip(eventHandler.cell, this.getContentToolTip(currentItemChanged[column.binding]));
              }
            }
          }
        });
      }
    },

    highlightChangedRowsOrganization() {
      if (this.organizationsFormat.length > 0 ) {
        this.productOrganizationFlexgrid.formatItem.addHandler((handler, eventHandler) => {
          if (
            eventHandler.panel.cellType === wjGrid.CellType.Cell ||
            eventHandler.panel.cellType === wjGrid.CellType.RowHeader
          ) {
            let currentItemChanged = this.changedColumns?.product_master?.branch_id || {};
            if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
              if (Object.keys(currentItemChanged).length) {
                eventHandler.cell.classList.add('wj-has-changed-mark');
              }
            } else {
              if (Object.keys(currentItemChanged).length) {
                let tooltip = new wjcCore.Tooltip();
                eventHandler.cell.classList.add('wj-has-changed');
                tooltip.setTooltip(eventHandler.cell, this.getContentToolTip(currentItemChanged));
              }
            }
          }
        });
      }
    },

    highlightChangedRowsProcess() {
      if(!this.changedColumns || Object.keys(this.changedColumns).length <= 0) {
        return
      }
      if (this.processFormat.length > 0 && Object.keys(this.changedColumns?.process).length > 0) {
        this.productProcessFlexgrid.formatItem.addHandler((handler, eventHandler) => {
          let accessRowIndex = eventHandler.row;
          const id = this.productProcessFlexgrid.itemsSource.items[accessRowIndex]?.id
          const indexItem = this.processFormat.findIndex(item => item.id === id)
          const isHasChange = this.changedColumns?.process.some(item => item.id === id)
          if(indexItem < 0 || !isHasChange) {
            return
          }
          if (
            eventHandler.panel.cellType === wjGrid.CellType.Cell ||
            eventHandler.panel.cellType === wjGrid.CellType.RowHeader
          ) {
            const index = this.changedColumns?.process.findIndex(item => item.id === id)
            let currentItemChanged = this.changedColumns?.process[index] || {};

            if (eventHandler.panel.cellType === wjGrid.CellType.RowHeader) {
              if (currentItemChanged?.columns && Object.keys(currentItemChanged?.columns).length) {
                eventHandler.cell.classList.add('wj-has-changed-mark');
              }
            } else {
              let columnBinding = handler.columns[eventHandler.col].binding;
              const itemData = currentItemChanged?.columns;
              if (
                itemData &&
                Object.keys(itemData).length &&
                (Object.keys(itemData).includes(columnBinding))
              ) {
                let tooltip = new wjcCore.Tooltip();
                eventHandler.cell.classList.add('wj-has-changed');
                tooltip.setTooltip(eventHandler.cell, this.getContentToolTip(itemData[columnBinding]));
              }
            }
          }
        });
      }
    },

    getContentToolTip(item) {
      let updatedAt = moment.utc(item.updated_at).add(9, 'hours').format('YYYY/MM/DD HH:mm:ss');
      if(this.isCheckLang === 'vi') {
        return  `<div class='wj-cell-changed'>${this.$t("register_data.description_modified_by")} ` +
          ` ${this.$t("register_data.description_administrator")}<br/>` + item.updated_by + ' ' +
          updatedAt +
          '</div>'
      } else {
        return  `<div class='wj-cell-changed'>${this.$t("register_data.description_administrator")} ` +
          item.updated_by +
          ` ${this.$t("register_data.description_modified_by")}<br/>` +
          updatedAt +
          '</div>'
      }
      
    },

    checkTableHasData(typeTable) {
      let isOrganizationHasData = false;
      let isInfoMationHasData = false;
      let isProcessHasData = false;

      if (typeTable === this.tableTypes[0]) {
        const source = this.productOrganizationCollection.sourceCollection[0];
        const layers = [
          'organizational_division',
          'company_name',
          'business_name',
          'country',
          'layer_3',
          'layer_4',
          'layer_5',
          'layer_6',
        ];
        isOrganizationHasData = layers.some((field) => source?.[field]);
      }

      if (typeTable === this.tableTypes[1]) {
        const source = this.productInformationCollection.sourceCollection[0];
        const listItem = [
          'quantity',
          'quantity_unit',
          'amount',
          'amount_unit',
          'amount_per_product',
          'total_amount',
          'total_quantity',
        ];
        isInfoMationHasData = listItem.some((field) => source?.[field]);
      }

      if (typeTable === this.tableTypes[2]) {
        const source = this.productProcessCollection.sourceCollection[0];
        const listItem = [
          'process_item_name',
          'compilation_method',
          'input_type',
          'wsu_value',
          'activity_amount_unit',
          'activity_amount',
          'number_production',
          'number_production_unit',
          'number_total_production',
          'number_total_production_unit',
          'apportionment_unit',
          'emissions'
        ];
        isProcessHasData = listItem.some((field) => source?.[field]);
        this.totalEmissions = 0;
        this.productProcessCollection.sourceCollection?.forEach(process => {
          const subString = process.emissions_val?.toString()?.includes('-') ? 26 : 25
          const valueEmission = process.emissions_val?.toString()?.substring(0, subString)
          this.totalEmissions = formatBigNumber(math.evaluate(`${formatValue(this.totalEmissions?.toString())} + ${formatValue(valueEmission)}`), 23)
        })
      }
      this.isHasData = (isOrganizationHasData || isInfoMationHasData || isProcessHasData || this.isGetDetailProduct) && !this.isDisableSubmit;
    },

    handleInitOrganizationFlexgrid(productOrganizationFlexgrid) {
      this.productOrganizationFlexgrid = productOrganizationFlexgrid;

      this.productOrganizationFlexgrid.cellEditEnded.addHandler(() => {
        this.checkTableHasData('Organization');
        if([STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(this.status)) {
          this.isEditedData = 1
        }

      });

      this.productOrganizationFlexgrid.pasted.addHandler(() => {
        this.checkTableHasData('Organization');
      });

      this.productOrganizationFlexgrid.hostElement.addEventListener('keydown', (e) => {
        if (e.keyCode === KEYS_CODE.DOWN_ARROW) {
          //theProductOrganizationGrid
          let theProductOrGrid = Control.getControl(document.getElementById('theProductOrganizationGrid'));
          theProductOrGrid.selection = new wjGrid.CellRange(0, 0);
          theProductOrGrid.startEditing(true, 0, 0);

          let theProductInformationGrid = Control.getControl(document.getElementById('theProductInformationGrid'));
          theProductInformationGrid.selection = new wjGrid.CellRange(0, 0);
          theProductInformationGrid.startEditing(true, 0, 1);
        }

      }, false)
    },

    handleInitProcessFlexgrid(productProcessFlexgrid) {
      this.productProcessFlexgrid = productProcessFlexgrid;
    },

    handleInitInfoMation(productInformationFlexgrid) {
      this.productInformationFlexgrid = productInformationFlexgrid;
      const formatItems = ['quantity', 'amount', 'amount_per_product', 'total_amount', 'total_quantity']
      const propItems = ['quantity', 'amount', 'total_amount', 'total_quantity']
      const unitItem = ['amount_unit', 'quantity_unit']

      let oldQuantity = null
      let oldAmount = null
      let oldAmountPerProduct = null
      this.productInformationFlexgrid.cellEditEnding.addHandler((s, e) => {
        if(e.col === 1) {
          oldQuantity = e.data
        } else if(e.col === 3) {
          oldAmount = e.data
        } else if(e.col === 5) {
          oldAmountPerProduct = e.data
        }
      })

      this.productInformationFlexgrid.cellEditEnded.addHandler((s, e) => {
        this.checkTableHasData('Information');
        const column = s.columns[e.col];
        const { row, col } = e.range
        let cellData = s.getCellData(row, col, false)
        const view = s.collectionView
        const source = view.sourceCollection
        const currentItem = source[row] || {}


        if(formatItems.includes(column.binding)){
          cellData = cellData && fullWidthToHalfWidth(cellData)
          const isNumber = $_helper_isNumberType(cellData);
          const range = 25
          const isPositive = !cellData?.includes('-');
          // let newValue = (isNumber && isPositive) ?  formatValue(cellData)?.substring(0, range).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,') : ''
          let newValue = (isNumber && isPositive) ? addThoundSandComma(formatValue(cellData)?.substring(0, range)) : ''
          s.setCellData(row, col, newValue, false, true);

          if(propItems.includes(column.binding)) {
            this[column.binding] = newValue
          }
        }

        if([STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(this.status)) {
          this.isEditedData = 1
        }

        const quantityIndex = 1, amountIndex = 3, amountPerProductIndex = 5;
        const amount = currentItem.amount
        const quantity = currentItem.quantity
        const amount_per_product = currentItem.amount_per_product

        const amount_val = math.bignumber(formatValue(currentItem.amount))
        const quantity_val = math.bignumber(formatValue(currentItem.quantity))
        const amount_per_product_val = math.bignumber(formatValue(currentItem.amount_per_product))

        if(amount && quantity && !amount_per_product && column.binding !== 'amount_per_product') {
          // const newAmountPerProduct = formatBigNumber(math.evaluate(`${amount_val.toString()} / ${quantity_val.toString()}`))?.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
          const newAmountPerProduct = addThoundSandComma(formatBigNumber(math.evaluate(`${amount_val.toString()} / ${quantity_val.toString()}`)))
          s.setCellData(row, amountPerProductIndex, newAmountPerProduct, false, true);
        }

        if(amount && !quantity && amount_per_product && column.binding !== 'quantity') {
          // const newQuantity = formatBigNumber(math.evaluate(`${amount_val.toString()} / ${amount_per_product_val.toString()}`))?.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
          const newQuantity = addThoundSandComma(formatBigNumber(math.evaluate(`${amount_val.toString()} / ${amount_per_product_val.toString()}`)))
          s.setCellData(row, quantityIndex, newQuantity, false, true);
        }

        if(!amount && quantity && amount_per_product && column.binding !== 'amount') {
          // const newAmount = formatBigNumber(math.evaluate(`${quantity_val.toString()} * ${amount_per_product_val.toString()}`))?.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
          const newAmount = addThoundSandComma(formatBigNumber(math.evaluate(`${quantity_val.toString()} * ${amount_per_product_val.toString()}`)))
          s.setCellData(row, amountIndex, newAmount, false, true);
        }

        // remove data in grid
        if(column.binding === 'amount_per_product' && cellData && amount && quantity && cellData !== oldAmountPerProduct) {
          s.setCellData(row, quantityIndex, '', false, true);
          s.setCellData(row, amountIndex, '', false, true);
        }

        if(column.binding === 'quantity' && cellData && amount && amount_per_product && cellData !== oldQuantity) {
          s.setCellData(row, amountPerProductIndex, '', false, true);
          s.setCellData(row, amountIndex, '', false, true);
        }

        if(column.binding === 'amount' && cellData && quantity && amount_per_product && cellData !== oldAmount) {
          s.setCellData(row, amountPerProductIndex, '', false, true);
          s.setCellData(row, quantityIndex, '', false, true);
        }

        if(unitItem.includes(column.binding)) {
          this[column.binding] = cellData ? parseInt(cellData) : ''
        }

        // this.amount_per_product =  addThoundSandCommaWithTwoDigit(formatBigNumber(math.evaluate(`${this.totalEmissions.toString()} / ${quantity_val.toString()}`), 35))
        this.amount_per_product = addThoundSandCommaWithTwoDigit(formatBigNumber(math.evaluate(`${this.totalEmissions.toString()} / ${quantity_val.toString()}`), 35).replace(/[^0-9\.\-]+/g, ''), '', false, true);
      });

      this.productInformationFlexgrid.pasted.addHandler((s, e) => {
        const { col, col2, row, row2 } = e.range
        const view = s.collectionView
        for (let colIndex = col; colIndex <= col2; colIndex++) {
          for (let rowIndex = row; rowIndex <= row2; rowIndex++) {

            if (formatItems.includes(s.columns[colIndex].binding)) {
              const cellData = s.getCellData(rowIndex, colIndex, false)
              const cEvent = new CellEditEndingEventArgs(s, new CellRange(rowIndex, colIndex), cellData)
              cEvent.custom = true
              s.onCellEditEnded(cEvent)
            }
          }
        }
      })

      this.productInformationFlexgrid.hostElement.addEventListener('keydown', (e) => {
        if (e.keyCode === KEYS_CODE.UP_ARROW) {
          let theProductInformationGrid = Control.getControl(document.getElementById('theProductInformationGrid'));
          theProductInformationGrid.selection = new wjGrid.CellRange(0, 0);
          theProductInformationGrid.startEditing(true, 0, 0);

          let theProductOrGrid = Control.getControl(document.getElementById('theProductOrganizationGrid'));
          theProductOrGrid.selection = new wjGrid.CellRange(0, 2);
          theProductOrGrid.startEditing(true, 0, 2);
        }

      }, false);

      this.productInformationFlexgrid?.formatItem.addHandler((s, e) => {
        // custome tooltip
        const colBinding = e.panel.columns[e.col].binding;
        if (e.panel == s.columnHeaders) {
          if (colBinding === "quantity") {
            toolTipCustom(e, "t1", this.$t('b_register_product.tooltip_description_product_quantity'), this.hdrTips);
          }
          if (colBinding === "amount") {
            toolTipCustom(e, "t2", this.$t('b_register_product.tooltip_description_product_quantity'), this.hdrTips);
          }
          if (colBinding === "amount_per_product") {
            toolTipCustom(e, "t3", this.$t('b_register_product.tooltip_description_product_quantity'), this.hdrTips);
          }
          if (colBinding === "total_quantity") {
            toolTipCustom(e, "t4", this.$t('b_register_product.tooltip_description_production_quantity_of_all_products'), this.hdrTips);
          }
          if (colBinding === "total_amount") {
            toolTipCustom(e, "t5", this.$t('b_register_product.tooltip_description_production_amount_for_all_products'), this.hdrTips);
          }
        }
      })

      this.productInformationFlexgrid.beginningEdit.addHandler((s, e) => {
        const column = s.columns[e.col];
        if(formatItems.includes(column.binding)){
          const { row, col } = e.range
          const cellData = s.getCellData(row, col, false)
          s.setCellData(row, col, formatValue(cellData), false, true);
        }
      })
    },

    onFlexGridInitialDone(productOrganizationFlexgrid, productInformationFlexgrid, productProcessFlexgrid) {
      if (!productOrganizationFlexgrid || !productInformationFlexgrid || !productProcessFlexgrid) {
        return;
      }

      this.handleInitOrganizationFlexgrid(productOrganizationFlexgrid);
      this.handleInitProcessFlexgrid(productProcessFlexgrid);
      this.handleInitInfoMation(productInformationFlexgrid);

      if (!this.isGetDetailProduct) {
        this.initialView();
      }
    },

    getListOrganization(data) {
      this.listOrganizational = [];
      data.forEach((item) => {
        this.listOrganizational.push({
          id: item.id,
          value: item.type,
        });
      });
    },
    async getDetailBranch() {
      const response = await getListDetailBranch()
      this.branchData = prepareBranchData(response)
      this.listLayer = response.layer;
      this.layerBinding = ['organizational_division', 'company_name'].concat(response.layer.map(item => item.layer_field))
    },
    handleOpenPopupSelectRegister(valueIndex) {
      this.keyApportion += 1
      if (valueIndex === 1) {
        this.dialogApportionment = true;
        this.dataRegistedSelect = []
        this.totalEmissionSelect = 0
      } else if (valueIndex === 2) {
        this.dialogInputFree = true;
        this.isDisableInputInvidual = false;
      } else {
        this.linkedServiceDialog = true;
      }
      this.processSelect = {}
    },
    onAddProduct(product) {
      const itemsInTable = this.productProcessCollection.items
      let itemsInProduct = product.packages.map((item, index) => {
        if (item.package_used_number) {
          const emissionVal = formatValue(
            math.evaluate(
              `${math.bignumber(formatValue(item.package_used_number))} * ${math.bignumber(
                formatValue(item.value_source),
              )}`,
            ),
          );
          let data = {
            compilation_method: this.$t('b_linked_service.smartlca_method'),
            emissions: addThoundSandComma(emissionVal),
            process_item_name: item.package_name,
            wsu_value: item.value_source.toString(),
            emissions_val: +emissionVal,
            activity_amount: item.package_used_number,
            compilation_method_code: 'input_service_link',
            index: (index + this.productProcessCollection.itemCount).toString(),
            isAddnew: true
          }
          return data
        }
      }).filter(item => item?.activity_amount);

      this.productProcessCollection = new CollectionView([...itemsInTable,...itemsInProduct], {
        trackChanges: true,
      })

      this.isEmptySource = false;
      this.checkTableHasData('Process');

    },
    handleUpdateDataToTable(data) {
      const index = data.index < 0 ? this.productProcessCollection._src.length : data.index
      const subStringRangeEmissions = formatValue(data.emissions)?.includes('-') ? 26 : 25
      const subStringRangeNumberProduction = formatValue(data.number_production)?.includes('-') ? 26 : 25
      const subStringRangeNumberTotalProduction = formatValue(data.number_total_production)?.includes('-') ? 26 : 25
      const newData = {
        ...data,
        index: index.toString(),
        emissions_val: data.emissions,
        emissions: addThoundSandComma(formatValue(data.emissions)?.substring(0, subStringRangeEmissions)),
        isAddnew: isEmpty(this.processSelect)
      }
      if(data.index >= 0) { //handle update
        this.productProcessCollection.deferUpdate(() => {
          const index = this.productProcessCollection._src.findIndex((_, index) => index === data.index)
          this.productProcessCollection.sourceCollection[index] = newData

          if(this.productProcessCollection.itemsEdited.length === 0) {
            this.productProcessCollection.itemsEdited.push(newData)
          } else {
            const indexInItemsEdited = this.productProcessCollection.itemsEdited.findIndex(item => item.id === data.id)
            if(indexInItemsEdited < 0) {
              this.productProcessCollection.itemsEdited.push(newData)
            } else {
              this.productProcessCollection.itemsEdited[indexInItemsEdited] = newData
            }
          }
        })
      } else {
        this.productProcessCollection.addNew(newData);
        // this.totalEmissions += Number(data.emissions)
        this.productProcessCollection.commitNew();

        // update information (quantity, amount)
        const quantity = this.productInformationCollection.sourceCollection[0].quantity
        const amount = this.productInformationCollection.sourceCollection[0].amount
        const quantityUnit = this.productInformationCollection.sourceCollection[0].quantity_unit

        const isInformationEmptyQuantity = !quantity && quantity !== '0'
        const isInformationEmptyAmount = !amount && amount !== '0'
        const isInformationEmptyQuantityUnit = !quantityUnit
        const isRegisterQuantity = newData.apportionment_unit === '生産数量'

        const amount_val = math.bignumber(formatValue(amount))
        const quantity_val = math.bignumber(formatValue(quantity))

        if(newData.compilation_method === this.$t('b_register_product.label_apointment_input_minute') && isInformationEmptyQuantity && isRegisterQuantity) {
          //handle update data to information grid
          // const numberVal = formatValue(newData.number_production)?.substring(0, 20).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')
          // const numberValtotal = formatValue(newData.number_total_production)?.substring(0, 20).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')
          const numberVal = addThoundSandComma(formatValue(newData.number_production)?.substring(0, subStringRangeNumberProduction))
          const numberValtotal = addThoundSandComma(formatValue(newData.number_total_production)?.substring(0, subStringRangeNumberTotalProduction))

          const quantityVal = math.bignumber(formatValue(numberVal))
          let newAmountPerProduct = amount ? formatBigNumber(math.evaluate(`${amount_val.toString()} / ${quantityVal.toString()}`)) : ''
          newAmountPerProduct = formatValue(newAmountPerProduct.toString())

          const newProduct = {
            ...this.productInformationCollection.sourceCollection[0],
            quantity: numberVal,
            total_quantity: numberValtotal,
            amount_per_product: newAmountPerProduct,
          }

          if(isInformationEmptyQuantityUnit) {
            newProduct.quantity_unit = newData.number_production_unit
            this.quantity_unit = newData.number_production_unit
          }
          this.quantity = formatValue(numberVal)
          this.total_quantity = formatValue(numberValtotal)
          this.productInformationCollection.sourceCollection[0] = { ...newProduct }
          this.productInformationCollection.refresh()
        }

        if(newData.compilation_method === this.$t('b_register_product.label_apointment_input_minute') && isInformationEmptyAmount && !isRegisterQuantity) {
          // const numberVal = formatValue(newData.number_production)?.substring(0, 20).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')
          // const numberValtotal = formatValue(newData.number_total_production)?.substring(0, 20).replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,')
          const numberVal = addThoundSandComma(formatValue(newData.number_production)?.substring(0, subStringRangeNumberProduction))
          const numberValtotal = addThoundSandComma(formatValue(newData.number_total_production)?.substring(0, subStringRangeNumberTotalProduction))

          //handle update data to information grid
          const amountVal = math.bignumber(formatValue(numberVal))
          let newAmountPerProduct = quantity ? formatBigNumber(math.evaluate(`${amountVal.toString()} / ${quantity_val.toString()}`)) : ''
          newAmountPerProduct = formatValue(newAmountPerProduct.toString())

          const newProduct = {
            ...this.productInformationCollection.sourceCollection[0],
            amount: numberVal,
            total_amount: numberValtotal,
            amount_per_product: newAmountPerProduct,
          }

          this.amount = formatValue(numberVal)
          this.total_amount = formatValue(numberValtotal)
          this.productInformationCollection.sourceCollection[0] = { ...newProduct }
          this.productInformationCollection.refresh()
        }
      }

      this.isEmptySource = false;
      this.checkTableHasData('Process');
      this.isEditedData = true
      this.productProcessCollection.currentItem = null;

    },
    createUndoStack() {
      this.actionUpdateStatusBtn({
        undo: false,
        redo: false,
      });
      this.undoStack = new UndoStack('#undoable-table', {
        maxActions: 50,
        stateChanged: (s) => {
          this.canUndo = s.canUndo;
          this.canRedo = s.canRedo;
        },
      });
    },
    handleIdClick(context) {
      this.processSelect = { ...context.item, index: context.row.index }
      if(context.item.compilation_method_code !== 'apointment_input') {
        this.isDisableInputInvidual = context.item.compilation_method_code === 'input_service_link' ? true : false
        this.dialogInputFree = true
      } else {
        this.dialogApportionment = true
      }
      this.keyApportion ++
    },
    handleActionProcess(context, event) {
      event.stopPropagation()
      const element = document.getElementById(context.row.dataItem.index).getBoundingClientRect()
      const leftPosition = element.left;
      const topPosition = element.top;
      this.indexRowDelete = context.row.index;
      this.processClick = { ...context.row.dataItem, leftPosition, topPosition, index: context.row.index, isShowAction: true}
    },
    handleDeleteProcess() {
      this.dialogDeleteProcess = true
    },
    handleAcceptDeleteProcess() {
      // const index = this.productProcessCollection._src.findIndex(item => item.index === this.processClick.index)
      this.productProcessCollection.removeAt(this.indexRowDelete);
      this.productProcessCollection.commitNew();
      this.isEmptySource = this.productProcessCollection._src.length === 0
      this.dialogDeleteProcess = false
      // this.dialogNotification = true
      this.checkTableHasData('Process');
      this.isEditedData = true;
    },
    getProductMaster(type) {
      let productMaster = {
        name: this.product?.productName,
        code: this.product?.productCode,
        id: this.productMasterId
      }

      if([ACTION_HEADER_TABLE.SAVE_DRAFT].includes(type)) {  // case draft from product master only has id
        if(!this.isRegisterNew) {
          return {
            id: this.productId,
            name: this.product?.productName,
            code: this.product?.productCode,
          }
        } else {
          return productMaster
        }
      }

      if(!this.is_new_registration && this.$route.query?.id) {
        productMaster.id = this.productId ? this.productId : this.productMasterId
      }

      return productMaster
    },
    handleGetProductData(type) {
      const productMaster = this.getProductMaster(type)
      const layerData = this.productOrganizationCollection.sourceCollection[0]
      const inforValues = this.productInformationCollection.sourceCollection[0]
      const informationData = {
        ...inforValues,
        amount: formatValue(inforValues.amount),
        amount_per_product: formatValue(inforValues.amount_per_product),
        quantity: formatValue(inforValues.quantity),
        total_amount: formatValue(inforValues.total_amount),
        total_quantity: formatValue(inforValues.total_quantity),
      }
      const processData = this.productProcessCollection.sourceCollection.map(item => {
        const subStringRangEmissions = formatValue(item?.emissions_val)?.includes('-') ? 26 : 25
        let itemProcess = {
          ...item,
          compilation_method: item.compilation_method_code,
          number_production: formatValue(item.number_production),
          number_total_production: formatValue(item.number_total_production),
          wsu_value: formatValue(item.wsu_value),
          activity_amount: formatValue(item.activity_amount),
          // emissions: formatValue(item.emissions),
          emissions: formatValue(item?.emissions_val).slice(0, subStringRangEmissions)
        }
        if(itemProcess?.emissions_val) {
          delete itemProcess.emissions_val
        }
        delete itemProcess.index
        delete itemProcess.isAddnew
        delete itemProcess.compilation_method_code
        if(ACTION_HEADER_TABLE.SAVE_TEMPLATE_PRODUCT) {
          delete itemProcess.id
        }
        return itemProcess
      })

      let layerItem = {}
      this.layerBinding.forEach(itemKey => {
        layerItem[itemKey] = layerData[itemKey]
      })
      const branchData = getBranchId(this.branchData.filterPatternList, layerItem);
      const product = { ...layerData, is_new_registration: this.isRegisterNew ? 1 : 0}
      if(product?.id && ![STATUS_FIELD.STATUS_APPROVED, STATUS_FIELD.STATUS_REPRENSENT_APPROVED].includes(this.status)) {
        delete product.id
      }
      // productMaster.duration_id = this.selectDurationChange
      productMaster.branch_id = branchData?.id || null
      const durationSelected = this.durationList.find(duration => duration.id === this.selectDurationChange) || {}
      const dataWorkflow = {
        month: this.monthSelect,
        year: this.yearSelect,
        contractor_id: this.contractor,
        duration_id: durationSelected?.id,
        id: this.$route.query?.id
      }

      if(type === ACTION_HEADER_TABLE.SAVE_TEMPLATE_PRODUCT) {
        delete informationData.id
        delete dataWorkflow.id
        delete product.id
      }

      return { layerData, informationData, processData, product, dataWorkflow, productMaster }
    },
    getYearByDurationAndMonth(durationId, month) {
      const { start_at, end_at } = this.durationList.find(duration => duration.id === durationId)
      const [ startYear, startMonth ] = [...start_at.split('-')]
      const [ endYear, endMonth ] = [...end_at.split('-')]

      if(month < Number(startMonth)) {
        return Number(endYear)
      } else {
        return Number(startYear)
      }
    },
    getPayloadSubmitFromData(payload) {
      let itemProcessAdd = this.productProcessCollection.sourceCollection.filter(item => {
        return item.isAddnew
      })

      itemProcessAdd = itemProcessAdd.map(item => {
        const subStringRangEmissions = formatValue(item?.emissions_val)?.includes('-') ? 26 : 25
        let process = {
          ...item,
          compilation_method: item.compilation_method_code,
          number_production: formatValue(item.number_production),
          number_total_production: formatValue(item.number_total_production),
          wsu_value: formatValue(item.wsu_value),
          activity_amount: formatValue(item.activity_amount),
          // emissions: formatValue(item.emissions),
          emissions: formatValue(item?.emissions_val).slice(0, subStringRangEmissions)
        }
        delete process.index
        delete process.isAddnew
        delete process.compilation_method_code
        if(process?.emissions_val) {
          delete process.emissions_val
        }
        return process
      }) || {}

      const itemProcessEdited = this.productProcessCollection.itemsEdited?.filter(item => item.id)?.map(item => {
        const subStringRangEmissions = formatValue(item?.emissions_val)?.includes('-') ? 26 : 25
        let process = {
          ...item,
          compilation_method: item.compilation_method_code,
          number_production: formatValue(item.number_production),
          number_total_production: formatValue(item.number_total_production),
          wsu_value: formatValue(item.wsu_value),
          activity_amount: formatValue(item.activity_amount),
          // emissions: formatValue(item.emissions),
          emissions: formatValue(item?.emissions_val).slice(0, subStringRangEmissions)
        }
        delete process.index
        delete process.isAddnew
        delete process.compilation_method_code
        if(process?.emissions_val) {
          delete process.emissions_val
        }
        return process
      }) || {}

      const itemProcessRemoved = this.productProcessCollection.itemsRemoved?.map(item => item.id) || {}

      const processData = {
        add: itemProcessAdd,
        update: itemProcessEdited,
        delete: itemProcessRemoved
      }
      payload.data.process = processData
      return payload
    },
    getPayloadWhenSubmitFromTemplate(payload) {
      delete payload.dataWorkflow.id
      delete payload.data.product?.id
      delete payload.data.product?.product_workflow_id
      delete payload.data.production?.id
      delete payload.data.production?.product_id
      const itemProcessAdd = this.productProcessCollection.items?.map(item => {
        const subStringRangEmissions = formatValue(item?.emissions_val)?.includes('-') ? 26 : 25
        let process = {
          ...item,
          compilation_method: item.compilation_method_code,
          number_production: formatValue(item.number_production),
          number_total_production: formatValue(item.number_total_production),
          wsu_value: formatValue(item.wsu_value),
          activity_amount: formatValue(item.activity_amount),
          // emissions: formatValue(item.emissions),
          emissions: formatValue(item?.emissions_val).slice(0, subStringRangEmissions)
        }
        delete process.isAddnew
        delete process.index
        delete process.isAddnew
        delete process.id
        delete process.product_id
        delete process.compilation_method_code
        if(process?.emissions_val) {
          delete process.emissions_val
        }
        return process
      }) || {}
      payload.data.process.add = itemProcessAdd
      return payload
    },
    saveDataWithType(type, approvalFormData = {}, method = 'post', isUpdateTemplate = false, isSaveDraftFromTemplate = false) {
      const { informationData, processData, product, dataWorkflow, productMaster } = this.handleGetProductData(type) 
      let payload = {
        action: type,
        data: {
          process: processData,
          product: { ...product },
          production: { ...informationData },
          productMaster: productMaster,
        },
        dataWorkflow: dataWorkflow
      }

      if(type === ACTION_HEADER_TABLE.SUBMIT_DATA) {
        payload.dataWorkflow = {
          ...dataWorkflow,
          ...approvalFormData,
          comment: approvalFormData?.comment?.length > 255 ? approvalFormData.comment.substr(0, 255) : approvalFormData.comment,
          unread_flg: true,
        }

        payload = this.getPayloadSubmitFromData(payload)
        if(this.isSubmitFromTemplate) {
          payload = this.getPayloadWhenSubmitFromTemplate(payload)
        }

      }

      if(type === ACTION_HEADER_TABLE.UPDATE) {
        payload = this.getPayloadSubmitFromData(payload)
        if(approvalFormData.title) {
          payload.dataWorkflow.title = approvalFormData.title
        }
      }

      if(type === ACTION_HEADER_TABLE.SAVE_TEMPLATE_PRODUCT) {
        payload.dataWorkflow = {
          ...dataWorkflow,
          template_type: 1,
          title: approvalFormData.title,
        }
      }


      if(!payload.data.productMaster?.id) {
        delete payload.data.productMaster.id
      }

      if(isSaveDraftFromTemplate) {
        delete payload.dataWorkflow.id
      }

      this.listErrorMessage = this.getValidateData(payload, type)
      if(this.listErrorMessage.length > 0) {
        this.isShowPopupError = true
        return
      }

      actionProductWorkflow(payload, method).then(res => {
        const productItem = this.productProcessCollection.sourceCollection.map(item => {
          delete item.isAddnew
          return item
        })

        this.productProcessCollection = new CollectionView(productItem, {
        trackChanges: true,
      })

        this.handleRedirectAffterSuccess(type, res, isUpdateTemplate, isSaveDraftFromTemplate)
      }).catch(error => {
        let arrError = [];
        Object.keys(error.errors).forEach((err) => {
          arrError.push(...error.errors[err]);
        });
        this.isShowPopupError = true
        this.listErrorMessage = arrError
      })
    },
    getValidateData(payload, type) {
      const production = payload.data.production // check quantity vs total_quantity,amount vs total_amount
      const product = payload.data.product

      const quantity = Number(formatValue(production.quantity))
      const total_quantity = Number(formatValue(production.total_quantity))
      const amount = Number(formatValue(production.amount))
      const total_amount = Number(formatValue(production.total_amount))
      const layerData = this.productOrganizationCollection.sourceCollection[0]

      let layerItem = {}
      this.layerBinding.forEach(itemKey => {
        layerItem[itemKey] = layerData[itemKey]
      })
      // const branchData = getBranchId(this.$store.state.registerData.listBranch.filterPatternList, layerItem);

      let errorList = []
      if(quantity && total_quantity && quantity > total_quantity) {
        errorList.push(this.$t('b_register_product.label_validate_product_1'))
      }
      if(quantity && total_quantity && amount > total_amount) {
        errorList.push(this.$t('b_register_product.label_validate_product_2'))
      }

      // check branch
      // if(this.isBranchUncheck && !branchData?.id && this.layerBinding.some(itemKey => product[itemKey]) && [ACTION_HEADER_TABLE.SUBMIT_DATA, ACTION_HEADER_TABLE.UPDATE_APPROVED_DATA].includes(type)) {
      //   errorList.push('組織区分/法人名の組み合わせが存在しません。')
      // }
      return errorList
    },
    handleSaveDraftProduct(method = 'put', isSaveDraftFromTemplate) {
      if(isSaveDraftFromTemplate === true) {
        this.dialogSaveDraftFromTemplate = true
        return
      }
      this.saveDataWithType(this.isGetDetailProduct ? ACTION_HEADER_TABLE.UPDATE : ACTION_HEADER_TABLE.SAVE_DRAFT, {}, method)
    },
    handleSaveDraftFromTemplate() {
      this.dialogSaveDraftFromTemplate = false
      this.saveDataWithType(ACTION_HEADER_TABLE.SAVE_DRAFT, {}, 'post', false, true)
    },
    handleSaveTemplateProduct() {
      this.saveDataWithType('saveTemplate')
    },
    submitTemplateAction(title) {
      this.saveDataWithType('saveTemplate', { title })
    },
    submitQuestionPopup() {
      deleteProductData(this.$route.query.id).then(() => {
        const type = this.$route.query.type
        if(type === 'template') {
          this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_TEMPLATES })
        } else {
          this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_EMISSIONS })
        }
      }).catch(error => {
        this.dialogDeleteDataApproval = true
        this.messageDialogDeleteDataApproval = error.errors[0][0]
      })
      this.dialogQuestion = false
    },
    handleAction(action, product, data) {
      this.product = product
      switch (action) {
        case ACTION_HEADER_TABLE.SAVE_TEMPRORA: // save draft
          if(data === true) {
            this.dialogSaveDraftFromTemplate = true
          } else {
            this.handleSaveDraftProduct(data === true ? 'post' : 'put', data)
          }
          break;
        case ACTION_HEADER_TABLE.SAVE_TEMPLATE: // save template
          // data is checkHasUserAccessTemplateDetail
          if (data) {
            this.warningTemplateDataDialogPopup = true;
          } else {
            this.saveTemplateDataDialogPopup = true
          }
          break;

        case ACTION_HEADER_TABLE.DELETE_DATA: // delete data
        // data is checkHasUserAccessTemplateDetail
          if (data) {
            this.warningTemplateDataDialogPopup = true;
            this.messageWarningTemplate = this.$t('register_data.popup_message_delete_share_open_template')
            break;
          }
          this.dialogQuestion = true
          break;

        case ACTION_HEADER_TABLE.SUBMIT_DATA: // submit data
          this.submitDataDialogPopup = true
          break;

        case ACTION_HEADER_TABLE.REVIEWING_DATA: // approve data
          this.dialogApprovalData = true
          this.handleGetDescriptionPopupByWorkflow()
          break;

        case ACTION_HEADER_TABLE.CANCEL_SUBMITTED: // CANCEL SUBMITTED
          this.dialogCancleRequest = true
          break;

        case ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED: // ACCEPT CANCEL UBMITTED
          this.dialogAcceptCancel = true;
          this.listStatusCancel = [
            {
              id: CANCEL_TYPE.ACCEPT,
              name: this.$t('register_data.radio_accept_cancel_submit'),
              description: this.$t('register_data.description_accept_cancel_submit'),
            },
            {
              id: CANCEL_TYPE.REJECT,
              name: this.$t('register_data.radio_reject_cancel_submit'),
              description: this.$t('register_data.description_reject_cancel_submit'),
            },
          ];
          break;
        case ACTION_HEADER_TABLE.UPDATE_APPROVED_DATA:
          this.dialogUpdateApprovalData = true
          break;

        case ACTION_HEADER_TABLE.OVERRIDE_TEMPLATE: //OVERRIDE_TEMPLATE
          if (data) {
            this.warningTemplateDataDialogPopup = true;
            this.messageWarningTemplate = this.$t('register_data.popup_message_cannot_overwrite_template')
          } else {
            this.dialogOverrideTemplate = true
          }
          break

        case ACTION_HEADER_TABLE.CLONE_TEMPLATE: //CLONE_TEMPLATE
          this.saveTemplateDataDialogPopup = true
          break
        default:
          break;
      }
    },
    handleOverrideTemplate() {
      this.dialogOverrideTemplate = false
      // todo: call api
      this.saveDataWithType(ACTION_HEADER_TABLE.UPDATE, { title: this.dataTitle }, 'put', true)
    },
    submitCancleRequest() {
      this.dialogCancleRequest = false
      const payload = {
        action: ACTION_HEADER_TABLE.CANCEL_SUBMITTED,
        dataWorkflow: {
          id: this.dataWorkflow.id,
          comment: '',
        }
      }
      actionProductWorkflow(payload, 'put').then(() => {
        this.dialogCancleSuccess = true
      }).catch(error => {
        this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_EMISSIONS })
        console.warn(error)
      })
    },
    handleUpdateDataApproval() {
      // call api
      const { informationData, product, productMaster } = this.handleGetProductData()
      const itemProcessEdited = this.productProcessCollection.itemsEdited?.filter(item => item.id)?.map(item => {
        const subStringRangEmissions = formatValue(item?.emissions_val)?.includes('-') ? 26 : 25
        let process = {
          ...item,
          compilation_method: item.compilation_method_code,
          number_production: formatValue(item.number_production),
          number_total_production: formatValue(item.number_total_production),
          wsu_value: formatValue(item.wsu_value),
          activity_amount: formatValue(item.activity_amount),
          // emissions: formatValue(item.emissions),
          emissions: formatValue(item?.emissions_val).slice(0, subStringRangEmissions)
        }
        delete process.index
        if(process?.emissions_val) {
          delete process.emissions_val
        }
        return process
      }) || {}

      let productData = {}
      let productionData = {}
      const listKey = ['business_name', 'company_name','organizational_division', 'country', 'layer_3', 'layer_4','layer_5', 'layer_6']
      const listProductionKey = ['amount_unit', 'quantity','quantity_unit', 'amount', 'amount_per_product', 'total_quantity','total_amount']
      listKey.forEach(key => {
        productData[key] = product[key] || null
      })
      productData.month = this.monthSelect
      productData.year = this.yearSelect
      productData.duration_id = this.selectDurationChange
      listProductionKey.forEach(key => {
        productionData[key] = informationData[key] || null
      })

      const payload = {
        data: {
          process: {
            update: itemProcessEdited.length > 0 ? itemProcessEdited : [],
            delete: this.productProcessCollection.itemsRemoved?.map(item => item.id) || {}
          },
          product: productData,
          production: productionData,
          productMaster: productMaster,
        },
      }

      this.listErrorMessage = this.getValidateData(payload, ACTION_HEADER_TABLE.UPDATE_APPROVED_DATA)
      if(this.listErrorMessage.length > 0) {
        this.isShowPopupError = true
        this.dialogUpdateApprovalData = false
        return
      }

      updateProductApproved(this.productApprovedId, payload).then(() => {
        this.dialogUpdateApprovalData = false
        this.getProductDetail(this.$route.query.id);
      }).catch(error => {
        this.dialogUpdateApprovalData = false
        let arrError = [];
        Object.keys(error.errors).forEach((err) => {
          arrError.push(...error.errors[err]);
        });
        this.isShowPopupError = true
        this.listErrorMessage = arrError
      })
    },
    handleClosePopupCancleSuccess() {
      this.dialogCancleSuccess = false
      this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_EMISSIONS })
    },
    handleClosePopupAcceptCancleSuccess() {
      this.dialogAcceptCancleSuccess = false
      this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_APPROVAL })
    },
    handleAcceptRequestCancelSubmit(acceptCancelFormData) {
      this.dialogAcceptCancel = false
      let is_allow_cancel = null;
      if (acceptCancelFormData.status === CANCEL_TYPE.ACCEPT) {
        is_allow_cancel = CANCEL_TYPE.ACCEPT;
      } else {
        is_allow_cancel = CANCEL_TYPE.REJECT;
      }
      const payload = {
        action: ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED,
        dataWorkflow: {
          id: this.dataWorkflow.id,
          comment: acceptCancelFormData?.comment?.length > 255 ? acceptCancelFormData.comment.substr(0, 255) : acceptCancelFormData.comment,
        },
        is_allow_cancel: is_allow_cancel
      }

      actionProductWorkflow(payload, 'put').then(() => {
        this.dialogAcceptCancleSuccess = true
        this.messageAcceptCancle = acceptCancelFormData.status === CANCEL_TYPE.ACCEPT ? this.$t('register_data.popup_message_accept_cancel_approve') : this.$t('register_data.popup_message_accept_cancel_reject');
      }).catch(error => {
        this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_APPROVAL })
        console.warn(error)
      })
    },
    handleApprovalCancleSubmit() {
      const payload = {
        action: ACTION_HEADER_TABLE.ACCEPT_CANCEL_SUBMITTED,
        dataWorkflow: {
          id: this.dataWorkflow.id,
          comment: "",
        },
        is_allow_cancel: CANCEL_TYPE.ACCEPT
      }
      actionProductWorkflow(payload, 'put')
      .then(() => {
        this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_APPROVAL })
      })
      .catch(error => {
        this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_APPROVAL })
        console.warn(error)
      })

    },
    handleGetDescriptionPopupByWorkflow() {
      if (this.dataWorkflow.is_approver === 1) {
        this.listStatusApproval = [
          {
            id: APPROVAL_TYPE.APPROVE,
            name: this.$t('register_data.radio_approve'),
            description:  this.$t('register_data.description_approve'),
          },
          {
            id: APPROVAL_TYPE.REJECT,
            name: this.$t('register_data.radio_reject'),
            description: this.$t('register_data.description_reject'),
          },
        ];
      } else {
        this.listStatusApproval = [
          {
            id: APPROVAL_TYPE.APPROVE,
            name: this.$t('register_data.radio_proxy_approve'),
            description: this.$t('register_data.description_proxy_approve'),
          },
          {
            id: APPROVAL_TYPE.REJECT,
            name: this.$t('register_data.radio_proxy_reject'),
            description: this.$t('register_data.description_proxy_approve'),
          },
        ];
      }
    },
    approvalDataHandler(approvalFormData) {
      this.dialogApprovalData = false
      const status = this.handleGetStatusApprovalForPayload(approvalFormData.status,  this.dataWorkflow.is_approver === 1)
      const payload = {
        action: ACTION_HEADER_TABLE.APPROVED_DATA,
        dataWorkflow: {
          id: this.dataWorkflow.id,
          status: status,
          comment: approvalFormData?.comment?.length > 255 ? approvalFormData.comment.substr(0, 255) : approvalFormData.comment,
        }
      }
      actionProductWorkflow(payload, 'put').then(res => {
        this.handleRedirectAffterSuccess(ACTION_HEADER_TABLE.APPROVED_DATA, res, status)
      }).catch(error => {
        this.dialogDeleteDataApproval = true
        this.messageDialogDeleteDataApproval = error.errors[0][0]
      })
    },
    handleGetStatusApprovalForPayload(status, isApproval) {
      let response = null
      if (status === APPROVAL_TYPE.APPROVE) {
        response = isApproval ? STATUS_FIELD.STATUS_APPROVED : STATUS_FIELD.STATUS_REPRENSENT_APPROVED;
      } else {
        response = isApproval ? STATUS_FIELD.STATUS_RETURN : STATUS_FIELD.STATUS_REPRENSENT_RETURN;
      }
      return response
    },
    submitRegisterDataHandler(approvalFormData) {
      this.saveDataWithType(ACTION_HEADER_TABLE.SUBMIT_DATA, approvalFormData, 'put')
      this.submitDataDialogPopup = false
    },
    handleRedirectAffterSuccess(type, response, isUpdateTemplate = false, isSaveDraftFromTemplate = false) {
      const id = response.latest_record?.workflow?.id
      switch (type) {
        case ACTION_HEADER_TABLE.SAVE_DRAFT:
          if(isSaveDraftFromTemplate) {
            this.dialogSaveDraftFromTemplateSuccess = true
            return
          }

          if(!this.isGetDetailProduct){
            this.$router.replace({ path: `/products/register?type=detail&id=${id}`})
            this.getProductDetail(id);
          }
          break;

        case ACTION_HEADER_TABLE.UPDATE:
          if(isUpdateTemplate) {
            this.dialogUpdateTemplate = true
          } else {
            this.dialogNotification = true;
            this.isUpdateDraft = true
          }
          break;

        case ACTION_HEADER_TABLE.SUBMIT_DATA:
          this.dialogSubmitSuccess = true;
          this.isSubmitToMyseft = response.latest_record?.is_auto_approve ? true : false
          break;

        case ACTION_HEADER_TABLE.SAVE_TEMPLATE_PRODUCT:
          this.dialogSaveTemplateSuccess = true
        break;

        case ACTION_HEADER_TABLE.APPROVED_DATA:
          const status = isUpdateTemplate
          let message = ''
          this.dialogApprovalDataSuccess = true
          if(status === STATUS_FIELD.STATUS_APPROVED) {
            message = this.$t('register_data.popup_message_accept_approve')
          } else if(status === STATUS_FIELD.STATUS_REPRENSENT_APPROVED) {
            message = this.$t('register_data.popup_message_accept_approver_approve')
          } else if(status === STATUS_FIELD.STATUS_RETURN) {
            message = this.$t('register_data.popup_message_return_approve')
          } else {
            message = this.$t('register_data.popup_message_return_approver_approve')
          }
          this.messageApprovalDataSuccess = message
        break;

        default:
          break;
      }
    },
    handlerClosePopupApprovalSuccess() {
      this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_APPROVAL })
    },
    handleCloseDialogSaveTemplateSuccess(){
      this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_TEMPLATES })
    },
    handleCloseNotification() {
      if(this.isUpdateDraft) {
        location.reload()
      } else {
        this.dialogNotification = false
      }
    },
    handleClosePopupSubmitSuccess() {
      this.dialogSubmitSuccess = false;
      if(this.isSubmitToMyseft) {
        this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_APPROVAL })
      } else {
        this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_SUBMITTED })
      }
    },
    handlerCloseRegister(){
      this.$router.push({ path: ROUTES.PRODUCTS + '/' + ROUTES.PRODUCTS_LIST_EMISSIONS })
    },
    handleSelectProduct(productId) {
      this.productId = productId
      // set data Organization into grid when select product name
      const productSelected = this.listProductMaster.find(product => product.id === productId)
      this.productOrganizationCollection.sourceCollection[0] = { ...productSelected.branch }
      this.productOrganizationCollection.refresh()

    },
    updateIsRegisterNew(newVal) {
      this.isRegisterNew = newVal
      this.getGridFacility()
      this.productOrganizationCollection.sourceCollection[0] = {}
      this.productOrganizationCollection.refresh()
    },
    handleUpdateNotification(dataWorkFollow) {
      if (
        (dataWorkFollow.is_approver === 1 && [STATUS_SUBMIT, STATUS_CANCEL_SUBMMITED].includes(dataWorkFollow.status)) || // cal API if user is approver
        (dataWorkFollow.is_owner === 1 &&[STATUS_RETURN, STATUS_REPRENSENT_RETURN].includes(dataWorkFollow.status))) {
          if ([STATUS_SUBMIT, STATUS_RETURN, STATUS_REPRENSENT_RETURN, STATUS_CANCEL_SUBMMITED].includes(dataWorkFollow.status)) {
            const params = {
              id: dataWorkFollow.id,
            };
            updateProductNotification({...params}).then(() => {
              getProductNotification().then(res => {
                this.actionUpdateNotification(res.data)
              })
            });
          }
        }
    },
    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
    closeDialogWarningSaveTemplate() {
      this.warningTemplateDataDialogPopup = false;
    },
    onHandleShowExportPopup(value) {
      this.exportDialog = value;
      this.exportDialogKey++;
    },
  },
  watch: {
    startAction(newValue, _) {
      if (newValue.undo) {
        this.undoStack.undo();
      } else if (newValue.redo) {
        this.undoStack.redo();
      }
    },

    canUndo(newValue) {
      this.actionUpdateStatusBtn({
        undo: newValue,
        redo: this.canRedo,
      });
    },

    canRedo(newValue) {
      this.actionUpdateStatusBtn({
        undo: this.canUndo,
        redo: newValue,
      });
    },

    isHiddenOverFlow(newVal) {
      if(newVal) {
        document.documentElement.style.overflow = 'hidden';
         document.body.style.overflow = 'hidden';
      } else {
        document.body.style.overflow = 'auto';
        document.documentElement.style.overflow = 'auto';
      }
    },

    totalEmissions() {
      const quantityVal = math.bignumber(formatValue(this.quantity))
      if(quantityVal.toString() === 0 || quantityVal.toString() === '0') {
        this.amount_per_product =  0
        return
      }
      // this.amount_per_product =  addThoundSandCommaWithTwoDigit(formatBigNumber(math.evaluate(`${formatValue(this.totalEmissions.toString())} / ${quantityVal.toString()}`), 35))
      this.amount_per_product = addThoundSandCommaWithTwoDigit(formatBigNumber(math.evaluate(`${formatValue(this.totalEmissions.toString())} / ${quantityVal.toString()}`), 35).replace(/[^0-9\.\-]+/g, ''), '', false, true);
    },

    selectDurationChange(newId){
      const duration = this.durationList.find(item => item.id === newId)
      if(!duration) return
      this.getMonthSelectionList(duration.start_at, duration.end_at);
    },

    organizationsFormat() {
      this.highlightChangedRowsOrganization()
    },
    processFormat() {
      this.highlightChangedRowsProcess()
    },
    yearSelect(newYear, oldYear) {
      if(oldYear) {
        this.monthList = getMonthListCustome(newYear, this.startMonth);
        const curentIndexMonth = this.monthList.findIndex(item => item.value === this.monthSelect)
        if(curentIndexMonth < 0) {
          this.monthSelect = this.monthList[this.monthList.length - 1].value
        }
      }
    },
    monthSelect(newMonth, oldMonth) {
      if(oldMonth) {
        this.yearList = calcYearFromStartMonthCustome(this.startMonth, this.yearSelect)
      }
    }
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.updateActionPosition);
  }
};
</script>
<style scoped lang="scss">
@import '~vuetify/src/styles/styles.sass';
.fixed-button-bottom {
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  margin-right: 20px;
  .action-table {
    width: 106%;
    margin-left: 0;
  }
}
.select-duration {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  @include desktop {
    max-width: 200px;
    &-item{
      &-vi{
        .select-duration-item--label{
          flex: 1
        }
      }
    }
  }
  &-item {
    display: flex;
    align-items: center;
    gap: 8px;
    align-self: stretch;
    .year-select {
      // width: 104px;
    }
    .month-select {
      // width: 88px;
    }
    .view-only {
      display: flex;
      padding: 8px 16px;
      align-items: center;
      gap: 16px;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
      color: $monoBlack;
      border: 1px solid rgba(42, 42, 48, 0.10);
      &.year-text {
        width: 104px;
      }
      &.month-text {
        width: 88px;
      }
      @media screen and (max-width: 1024px) {
        &.year-text {
          width: 50%;
        }
        &.month-text {
          width: 50%;
        }
      }
    }
    &--label {
      font-size: 14px;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.42px;
      color: $monoBlack;
      border-radius: 4px;
    }
    &-require {
      display: flex;
      padding: 0px 4px 2px 4px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background: $goldMid;
      color: $monoWhite;
      font-size: 11px;
      font-style: normal;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: 0.33px;
    }
  }
}
.title-block {
  // margin-top: 40px;
  &-head {
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: $goldMid;
    width: max-content;
    min-width: 100px;
    white-space: nowrap;
  }
  .title-block--detail {
    &.has-opacity {
      opacity: 0;
    }
  }
}

.total-treatment-list {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 12px;

  .treatment-item {
    display: flex;
    justify-content: space-between;
    margin-bottom: 4px;

    .treatment-label {
      min-width: 1912x;
    }

    .treatment-value {
      color: $goldMid;
      line-height: 24px;
      text-align: left;
      margin-left: 10px;

      span {
        margin-left: 8px;
        font-size: 13px;
        display: inline-block;
      }
    }
  }
}

.data-is-registered::after {
  content: '';
  width: 8px;
  height: 8px;
  background: #bfd4d9;
  border-radius: 50%;
  position: relative;
  display: inline-block;
}

.duration-text {
  border: 1px solid rgba(42, 42, 48, 0.1);
  border-radius: 4px;
  min-width: 275px;
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: 8px;
  color: $monoBlack;

  span {
    padding: 8px 16px;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }
}

.durations-pulldown {
  margin-top: 48px;
  align-items: center;
  padding: 0px;
  gap: 8px;
  &.month-year-pulldown {
    margin-top: 20px;
  }
  .month-year-pulldown-top {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
  }
  .durations-label {
    font-family: $mainFont;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    letter-spacing: 0.03em;
    color: $monoBlack;
    &--require {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 0px 4px 2px;
      width: 31px;
      height: 20px;
      border-radius: 4px;
      background: $goldMid;
      font-weight: 500;
      font-size: 11px;
      line-height: 18px;
      letter-spacing: 0.03em;
      color: $monoWhite;
      margin-right: 13px;
      margin-left: 6px;
    }
  }

  .select-item {
    margin-top: 8px;
  }
}

.update-block {
  margin-top: 52px;

  .item {
    height: 42px;

    .item-icon {
      margin-right: 12px;
    }

    .item-detail {
      height: 42px;

      .item-title {
        font-family: $mainFont;
        font-style: normal;
        font-weight: 700;
        font-size: 11px;
        line-height: 18px;
        height: 18px;

        letter-spacing: 0.03em;
        color: $monoBlack;
      }

      .item-content {
        font-family: $mainFont;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        height: 24px;
        margin-bottom: 0;

        letter-spacing: 0.05em;
        color: $monoBlack;
      }
    }

    &:last-child {
      margin-top: 16px;
    }
  }
}

.margin-text {
  margin-left: 50px;
}

.total-result {
  margin-top: 20px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  //padding: 20px;

  background: $bgLight;
  border-radius: 4px;
  gap: 20px;

  .emissions {
    width: 100%;

    .total-label {
      font-family: $mainFont;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      &.has-tooltip {
        .tooltip {
          position: absolute;
          width: 400px;
          background: $monoWhite;
          display: block;
          justify-content: left;
          border-radius: 4px;
          color: $monoBlack;
          margin: 0 auto;
          font-size: 15px;
          padding: 10px;
          height: unset;
          max-width: max-content;
          // position: absolute;
          right: 0;
          // left: 0px;
          bottom: unset;
          z-index: 9999;
          box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
            0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
            0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
            &-vi {
              width: 100%;
            }
        }

        @media (max-width: 768px) {
        .tooltip {
            width: auto;
          }
        }
      }
    }

    .total-emission {
      margin-top: 8px;
      font-family: 'Century Gothic Pro';
      font-weight: 400;
      font-size: 34px;
      line-height: 40px;
      color: $goldMid;
      width: 100%;
      text-align: right;
      word-break: break-all;

      span {
        font-size: 13px;
        line-height: 28px;
        min-width: 40px;
        display: inline-block;
      }
    }
  }

  .method {
    width: 100%;

    .method-label {
      margin-bottom: 8px;
      color: $monoBlack;
      font-size: 14px;
      line-height: 24px;
    }

    .v-btn {
      width: 100%;
    }
  }
}

.selections {
  margin-bottom: 48px;
  background: $bgLight;
  @media screen and (max-width: 1024px) {
    margin-top: 48px;
    padding: 16px 20px 20px;
  }
}

.select-emission-opacity {
  display: none;
}

.produst-page-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  margin-top: 40px;
  margin-bottom: 48px;
  padding-left: 20px;
  &__head {
    font-style: normal;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: $goldMid;
    font-weight: 400;
  }
  .common-btn {
    display: none;
  }
}

.action-table {
  position: absolute;
  // margin-top: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 16px 20px;
  gap: 16px;
  width: 100%;
  background: $bgMid;
  height: 128px;
  box-shadow: 0px -3.13px 2.86674px rgba(160, 181, 186, 0.165596), 0px -1.24px 1.13351px rgba(160, 181, 186, 0.24);
  margin-top: -127px;
  margin-left: -20px;
  .common-btn {
    width: 100%;
    .icon-back_active {
      display: none;
    }
    &:hover {
      .icon-back {
        display: none;
      }
      .icon-back_active {
        display: block;
      }
    }
  }
}

.pull-down-action {
  position: fixed;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  min-width: 89px;
  background: $monoOffWhite;
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  cursor: pointer;
  z-index: 99;
  &.show {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 7px 16px 9px;
    gap: 8px;
    min-width: 89px;
    height: 40px;
    &__label {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
    }
    &__icon {
      display: block;
      &.active {
        display: none;
      }
    }

    &:hover {
      background: $goldLight;
      .pull-down-action-item__label {
        color: $monoOffWhite;
      }
      .pull-down-action-item__icon {
        display: none;
        &.active {
          display: block;
        }
      }
    }
  }

}


.full-screen {
  position: fixed;
  top: 84px;
  right: 0;
  z-index: 99;
  height: calc(100vh - 84px - 128px);
  padding-right: 20 !important;
  width: 100%;
  animation: bottotop 0.2s ease-in 0s 1 normal none;
  -webkit-animation: bottotop 0.2s ease-in 0s 1 normal none;
  overflow: scroll;

  &.detail-product {
    height: calc(100vh - 84px);
  }

  .category-table {
    height: 100%;
    background: #fff;
  }

  &.expanded {
    width: 100%;

    .category-table {
      height: 100%;
      background: #fff;
    }
  }

  &.no-expanded {
    width: 100%;

    .category-table {
      height: 100%;
      background: #fff;
    }
  }
}

.register-btn-full-screen {
  position: absolute !important;
  bottom: 0;
}

@include desktop {
  .produst-page-title {
    padding-inline: 40px;
    justify-content: space-between;
    .common-btn {
      display: block;
      min-width: 161px;
      .icon-back_active {
        display: none;
      }
      &:hover {
        .icon-back {
          display: none;
        }
        .icon-back_active {
          display: block;
        }
      }
    }
  }
  .total-treatment-list {
    display: flex;
    justify-content: space-between;
    width: max-content;
    margin-left: 40px;

    .treatment-item {
      margin-right: 20px;
      display: flex;
      justify-content: space-between;
      font-family: $subFont;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: right;
      letter-spacing: 0.03em;
      word-break: break-all;
      color: $monoBlack;
      align-items: flex-start;

      .treatment-value {
        color: $goldMid;
        line-height: 24px;
        text-align: left;
        margin-left: 10px;
      }
    }
  }
  .title-block {
    display: flex;
    margin-top: 24px;
    margin-bottom: 48px;
    padding: 0 40px;
    &-head {
      font-weight: 400;
      font-size: 22px;
      line-height: 36px;
      letter-spacing: 0.05em;
      color: $goldMid;
      width: max-content;
      min-width: 100px;
      white-space: nowrap;
    }
    .title-block--detail {
      width: 50%;
      padding-right: 0 !important;
      flex: 1 0;
      &.has-opacity {
        opacity: 0;
      }
    }
  }
  .register-page {
    .container {
      padding: 0 20px;
    }
  }
  .update-block {
    display: flex;
    justify-content: flex-end;
    // max-width: 50% !important;
    width: 100%;
    margin-top: 0;

    .item {
      &:last-child {
        margin-top: 0;
        margin-left: 20px;
      }
    }
  }
  .selections {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
    flex-wrap: wrap;
    gap: 40px;
    background: $bgLight;
    border-radius: 4px;
    padding: 16px 20px 20px 20px;
    margin: 0 auto;
    margin-bottom: 24px;
    width: calc(100% - 80px);
    .durations-pulldown {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-top: 16px;

      .durations-select-item {
        max-width: 275px;
        &.new-register {
          max-width: 275px;
          width: 275px;
        }
      }
    }

    .total-result {
      margin-top: 0;
      max-width: 720px;
      width: max-content;
      padding: 16px 20px 20px;
      flex-direction: row;
      gap: 40px;
      .emissions {
        width: max-content;
        max-width: 300px;
        .total-emission {
          text-align: left;
          // margin-left: 8px;
          min-height: 38px;
          max-width: 300px;
          word-break: break-all;
          line-height: 36px;
        }

        span {
          margin-left: 8px;
        }
        .total-label {
          &.has-tooltip {
            .tooltip {
              &-vi {
                width: 400px;
              }
            }
            
        @media (max-width: 768px) {
        .tooltip {
            width: auto;
          }
        }
      }
        }
      }

      .method {
        max-width: 200px;

        .common-btn {
          max-width: 200px;

          &:disabled {
            background: transparent !important;
            box-shadow: unset;
            border: 1px solid rgba(42, 42, 48, 0.1);
            box-sizing: border-box;
            border-radius: 4px;
          }

          .v-btn__content {
            text-transform: lowercase !important;
          }
        }
      }

      &.max-content {
        .emissions {
          .total-emission {
            max-width: 100%;
          }
        }
      }
    }
  }
  .select-month {
    .month {
      width: 70.33px;
      height: 42px;
      flex: none;
      align-self: stretch;
      flex-grow: 1;
      margin: 0px 0px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 6px 0px 0px;
      text-align: center;
      font-family: $mainFont;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      border-radius: 0;

      letter-spacing: 0.03em;

      color: $monoMid;
    }
  }
  .action-table {
    position: relative;
    margin-top: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 32px;
    gap: 8px;
    height: 56px;
    width: 100%;
    background: $bgMid;
    margin-bottom: -80px;
    margin-left: 0;
    .common-btn {
      width: 350px;
      &.back-btn {
        display: none;
      }
    }
  }
  .select-emission-opacity {
    display: block;
    opacity: 0.46;
    background-color: rgb(33, 33, 33);
    border-color: rgb(33, 33, 33);
    bottom: 0;
    height: 100%;
    left: 0;
    position: absolute;
    right: 0;
    top: -84px;
    transition: inherit;
    width: 56px;
    will-change: opacity;
    z-index: 205;
  }
  .duration-text {
    margin-top: 0;
  }
  .full-screen {
    position: fixed;
    top: 84px;
    right: 0;
    z-index: 99;
    height: calc(100vh - 84px - 56px);
    padding-right: 0 !important;
    width: 100%;
    overflow: hidden;

    &.detail-product {
      height: calc(100vh - 84px);
    }
    .category-table {
      height: 100%;
      background: #fff;

      .table-toolbar {
        margin-bottom: 0 !important;
      }
    }

    &.expanded {
      width: calc(100% - 235px);

      .category-table {
        height: 100%;
        background: #fff;
      }
    }

    &.no-expanded {
      width: calc(100% - 73px);

      .category-table {
        height: 100%;
        background: #fff;
      }
    }
  }
  .register-btn-full-screen {
    bottom: 80px;
  }
}

@include large-desktop {
  .total-treatment-list {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: max-content;
    margin-left: 45px;

    .treatment-item {
      margin-right: 24px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      font-family: $subFont;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      text-align: right;
      letter-spacing: 0.03em;
      word-break: break-all;
      color: $monoBlack;

      .treatment-value {
        color: $goldMid;
        line-height: 24px;
        text-align: left;
        margin-left: 0;
      }
    }
  }
}

@media (min-width: 1025px) and (max-width: 1160px) {
  .duration-text {
    min-width: 224px !important;
  }
  .selections .durations-pulldown .durations-select-item,
  .selections .durations-pulldown .durations-select-item.new-register {
    max-width: 224px !important;
    width: 224px !important;
  }
}
@media (min-width: 1024px) and (max-width: 1230px) {
  .selections {
    .total-result {
      max-width: 350px;
    }
  }
}

.custom-table {
  @media (max-width: $tablet) {
    padding-left: 20px !important;
  }

  &.full-screen {
    position: fixed;
    top: 84px;
    right: 0;
    z-index: 99;
    height: 100vh;
    padding-right: 20 !important;
    width: 100%;
    animation: bottotop 0.2s ease-in 0s 1 normal none;
    -webkit-animation: bottotop 0.2s ease-in 0s 1 normal none;

    .category-table {
      height: 100%;
      height: calc(100vh - 84px);
      background: #fff;
    }

    &.expanded {
      width: 100%;

      .category-table {
        height: 100%;
        background: #fff;
      }
    }

    &.no-expanded {
      width: 100%;

      .category-table {
        height: 100%;
        background: #fff;
      }
    }
  }

  @include desktop {
    &.full-screen {
      position: fixed;
      top: 84px;
      right: 0;
      z-index: 99;
      height: 100vh;
      padding-right: 0 !important;
      width: 100%;

      .category-table {
        height: 100%;
        background: #fff;

        .table-toolbar {
          margin-bottom: 0 !important;
        }
      }

      &.expanded {
        width: calc(100% - 235px);

        .category-table {
          height: 100%;
          background: #fff;
        }
      }

      &.no-expanded {
        width: calc(100% - 73px);

        .category-table {
          height: 100%;
          background: #fff;
        }
      }
    }
  }
}

.category-table {
  .wj-cell-check {
    margin: unset !important;
  }
}

.main-table {
  @media (orientation: landscape) {
    @media (max-width: 1000px) {
      padding-left: 20px !important;
    }
  }
}

.method-name {
  display: flex;
  align-items: center;
  font-size: 14px;
  min-width: 64px;
  width: 100%;
  border-radius: 4px;
  height: 40px!important;
  padding: 7px 16px 9px !important;
  border: 1px solid rgba(42, 42, 48, 0.1);
  span {
    flex: unset !important;
    display: block !important;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-transform: initial !important;
    color: $monoBlack;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.03em;
  }
}
</style>
<style lang="scss">
.durations-pulldown {
  .select-month {
    &.v-input--is-disabled {
      .v-input__slot {
        background: #e4e6e7 !important;
      }
    }
  }
}
.select-durations {
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background: $monoOffWhite;
  }
  &::-webkit-scrollbar-thumb {
    background: $bgMid;
    border-radius: 6px;
    z-index: 1000;
    border-left: 4px solid $monoOffWhite;
    border-right: 4px solid $monoOffWhite;
  }
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649),
    0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596),
    0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  max-height: 280px !important;
  margin-top: 45px !important;
  &.fullmode {
    margin-top: 34px !important;
  }
  .theme--light.v-list {
    background: $monoOffWhite;
    .v-list-item {
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      border-right: 1px solid rgba(42, 42, 48, 0.1);
      min-height: 40px;
      .v-list-item__content {
        padding: 8px 0;
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: $bgCusLight;
      }
    }
  }
  &.product {
    .theme--light.v-list {
      background: $monoOffWhite;
      .v-list-item {
        border-bottom: 1px solid rgba(42, 42, 48, 0.1);
        border-right: 1px solid rgba(42, 42, 48, 0.1);
        min-height: 40px;
        color: $monoBlack;
        font-size: 14px;
        font-weight: 400;

        .v-list-item__content {
          padding: 8px 0;
        }
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background: $bgCusLight;
        }
      }

      .v-list-item--active {
        border-bottom: 1px solid rgba(42, 42, 48, 0.1);
        border-right: 1px solid rgba(42, 42, 48, 0.1);
        min-height: 40px;
        color: $monoBlack !important;
        font-size: 14px;
        font-weight: 500;
        background-color: $bgCusLight !important;

        &.v-list-item--link:before {
          background-color: $bgCusLight !important;
        }

        .v-list-item__content {
          padding: 8px 0;
        }
        &:last-child {
          border-bottom: none;
        }
        &:hover {
          background: $bgCusLight;
        }
      }
    }
  }
}
.container-fluid {
  &.register-product_page{
    .v-input.field  {
      .v-input__control{
        .v-input__slot input{
          color:$monoBlack;
        }
      }
    }
  }
}
@media (max-width: 1025px) {
 .durations-label--require {
    margin: 0 !important;
 }
 .margin-text {
  margin: 0 !important;
 }
}
</style>
