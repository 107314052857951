<template>
  <div>
    <v-dialog
      v-model="dialog"
      @click:outside="handleClickOutSite"
      :width="width"
			:max-width="width"
      :right="true"
      :content-class="`product-dialog-popup apportion-input ${dialog ? 'fullscreen-dialog-transition-open-to' : 'fullscreen-dialog-transition-leave-to'}`"
      origin="top left"
      ref="dialog"
      :transition="getTransiTion"
      overlay-color="rgba(0, 0, 0)"
      overlay-opacity="0.5"
    >
      <div class="head-dialog-popup">
        <div class="head-close-btn" @click="close">
          <img class="head-image-dialog-popup head-btn-close" src="@/assets/images/dialog/close.svg" />
          <img class="head-image-dialog-popup head-btn-close_active" src="@/assets/images/dialog/close_active.svg" />
        </div>
        <span class="head-title">{{ title }}</span>
      </div>
      <div class="content-dialog-popup">
        <div class="content-title">{{ title }}</div>
        <div class="content" @scroll="handleGetPositionWhenScroll" id="content">
          <div class="content-error" v-if="isErrorData">{{ getMessageError }}</div>
          <div class="content-item padding-item">
            <div class="content-item_label">
              <span class="content-item_label--text">{{ $t('b_register_product.title_procedure_item_name') }}</span>
              <span class="content-item_label--require">{{ $t('b_register_product.label_essential') }}</span>
            </div>
            <InputField
              :inputValue="itemName"
              :isError="!validItemName"
              @focus="validItemName = true"
              @update:inputValue="handleUpdateValue"
            />
          </div>

          <div class="content-devider"></div>

          <div class="content-item">
            <div class="content-item_label">
              <span class="content-item_label--text">{{ $t('b_register_product.title_explainive_amount_for_apportioned') }}</span>
              <span class="content-item_label--require">{{ $t('b_register_product.label_essential') }} </span>
            </div>
            <CommonButton :label="$t('b_register_product.button_select_registered_emissions')" type="colored" @action="handleOpenSelectEmission" />
            <div class="content-item_total">
              <div class="content-item_total-label">{{ $t('b_register_product.label_registered_emissions_to_be_apportioned') }}</div>
              <div class="content-item_total-number">
                {{ formatNumber(totalEmissionSelect) }}
                <span class="unit">t-CO2</span>
              </div>
            </div>
          </div>

          <div class="content-devider"></div>

          <div class="content-item">
            <div class="content-item_label">
              <span class="content-item_label--text">{{ $t('b_register_product.title_calculation_of_appropriation_ratio') }}</span>
              <span class="content-item_label--require">{{ $t('b_register_product.label_essential') }}</span>
            </div>

            <div class="content-item-block">
              <div class="content-item-block-label">{{ $t('b_register_product.label_calculation_unit') }}</div>
              <default-pull-down-menu
                v-model="apportionmentUnit"
                :items="apportionmentUnitList"
                :initialValue="apportionmentUnit"
                :disabled="false"
                :placeHolderText="$t('b_register_product.pulldown_select_select_approver')"
                width="100%"
                class="select-pulldown"
                @input="handleUnitClicked"
              />
            </div>

            <div v-if="isHandleNumber" class="content-item-muti-block">
              <div class="content-item-block">
                <div class="content-item-block-label">{{ $t('b_register_product.label_production_quantity') }}</div>
                <InputField
                  :isNumberType="true"
                  :inputValue="numberVal"
                  :isError="!validApportment.number || !validApportment.numberType || !validApportment.quantityNumber"
                  @focus="validApportment.number = true, validApportment.numberType = true, validApportment.quantityNumber = true"
                  @update:inputValue="handleUpdateNumber"
                />
              </div>
              <div class="content-item-block unit">
                <div class="content-item-block-label">{{ $t('b_register_product.label_unit') }}</div>

                <default-pull-down-menu
                  v-model="unit"
                  :items="unitList"
                  :initialValue="unit"
                  :disabled="false"
                  :placeHolderText="$t('b_register_product.pulldown_select_select_approver')"
                  width="100%"
                  class="select-pulldown"
                  :class="{'error-unit': !validApportment.unit}"
                  @focusItem="validApportment.unit = true"
                />
              </div>
            </div>

            <div v-if="isHandleNumber" class="content-item-muti-block">
              <div class="content-item-block">
                <div class="content-item-block-label">{{ $t('b_register_product.table_production_quantity_of_all_products') }}</div>
                <InputField
                  :isNumberType="true"
                  :isError="!validApportment.numberTotal || !validApportment.numberTotalType"
                  @focus="validApportment.numberTotal = true; validApportment.numberTotalType = true"
									@update:inputValue="handleUpdateTotal"
                  :inputValue="numberAllVal"
                />
              </div>
              <div class="content-item-block unit">
                <div class="content-item-block-label">{{ $t('b_register_product.label_unit') }}</div>

                <default-pull-down-menu
                  v-model="unit"
                  :items="unitList"
                  :initialValue="unit"
                  :disabled="false"
                  :placeHolderText="$t('b_register_product.pulldown_select_select_approver')"
                  width="100%"
                  class="select-pulldown"
                  :class="{'error-unit': !validApportment.unit}"
                  @focusItem="validApportment.unit = true"
                />
              </div>
            </div>

            <!-- for price case -->
            <div v-if="isHandlePrice" class="content-item-muti-block">
              <div class="content-item-block">
                <div class="content-item-block-label">{{ $t('b_register_product.table_production_value') }}</div>
                <InputField
                  :isNumberType="true"
                  :isError="!validApportment.number || !validApportment.numberType || !validApportment.quantityPrice"
                  @focus="validApportment.number = true,  validApportment.numberType = true, validApportment.quantityPrice = true"
									@update:inputValue="handleUpdatePrice"
                  :inputValue="priceVal"
                />
              </div>
              <div class="content-item-block unit">
                <div class="content-item-block-label">{{ $t('b_register_product.label_unit') }}</div>

                <default-pull-down-menu
                  v-model="unit"
                  :items="unitList"
                  :initialValue="unit"
                  :disabled="false"
                  :placeHolderText="$t('b_register_product.pulldown_select_select_approver')"
                  width="100%"
                  class="select-pulldown"
                  :class="{'error-unit': !validApportment.unit}"
                  @focusItem="validApportment.unit = true"
                />
              </div>
            </div>

            <div v-if="isHandlePrice" class="content-item-muti-block">
              <div class="content-item-block">
                <div class="content-item-block-label">{{ $t('b_register_product.label_production_amount_of_all_products') }}</div>
                <InputField
                  :isNumberType="true"
                  :isError="!validApportment.numberTotal || !validApportment.numberTotalType"
                  @focus="validApportment.numberTotal = true; validApportment.numberTotalType = true"
									@update:inputValue="handleUpdatePriceAll"
                  :inputValue="priceAllVal"
                />
              </div>
              <div class="content-item-block unit">
                <div class="content-item-block-label">{{ $t('b_register_product.label_unit') }}</div>
                <default-pull-down-menu
                  v-model="unit"
                  :items="unitList"
                  :initialValue="unit"
                  :disabled="false"
                  :placeHolderText="$t('b_register_product.pulldown_select_select_approver')"
                  width="100%"
                  class="select-pulldown"
                  :class="{'error-unit': !validApportment.unit}"
                  @focusItem="validApportment.unit = true"
                />
              </div>
            </div>
            <!-- end price case -->

            <div class="content-item_total">
              <div class="content-item_total-label">{{ $t('b_register_product.label_comparative_ratio') }}</div>
              <div class="content-item_total-number">
                {{ formatNumber(apportionPercent) }}
                <span class="unit">%</span>
              </div>
            </div>
          </div>

          <div class="content-devider"></div>
        </div>
      </div>
      <div class="action-dialog-popup">
        <div class="left-action"></div>
        <div class="main-action">
          <div class="content-emissions">
            <div class="content-emissions-detail">
              <div class="content-emissions-detail--label">{{ $t('b_register_product.title_emission') }}</div>
              <div class="content-emissions-detail--number">
                {{ formatNumber(totalEmissionApportionment) || '---' }}
                <span class="unit">t-CO2</span>
              </div>
            </div>
          </div>

          <div class="content-action">
            <CommonButton class="submit-btn" :label="$t('b_register_product.button_keep')" type="colored" @action="handleSubmitData" :isDisable="disableButton" />
          </div>
        </div>
      </div>
    </v-dialog>
    <SelectEmissionRegistedPopup
      :dialog="dialogSelectEmission"
      :listLayer="listLayer"
      :durationList="durationList" 
      :currentSelectedData="currentSelectedData"
      :startMonth="startMonth"
      @close="dialogSelectEmission = false"
      @selectEmissions="updateEmissionToTable"
    />
  </div>
</template>

<script>
import InputField from '@/components/products/input/InputField.vue';
import CommonButton from '@/components/utils/button.vue';
import { formatValue, $_helper_isNumberType, math, formatBigNumber } from '@/concerns/newRegisterData/wijmo.helper';
import { mapState } from 'vuex';
import SelectEmissionRegistedPopup from '@/components/products/dialogs/SelectEmissionRegistedPopup.vue';
import DefaultPullDownMenu from '@/components/products/common/pull-down-select.vue';
import {addThoundSandComma} from '@/utils/convertNumber';
export default {
  components: { InputField, CommonButton,  SelectEmissionRegistedPopup, DefaultPullDownMenu },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    confirmText: {
      type: String,
      default: '保存',
    },
    message: {
      type: String,
      default: '保存',
    },
    number: {
      type: [Number, String],
      default: 0,
    },
    price: {
      type: [Number, String],
      default: 0,
    },
    numberAll: {
      type: [Number, String],
      default: 0,
    },
    priceAll: {
      type: [Number, String],
      default: 0,
    },
    totalEmissionSelect: {
      type: [Number, String],
      default: 0,
    },
    dataRegistedSelect: {
      type: Array,
      default: () => [],
    },
    processSelect: {
      type: Object,
      default: () => {},
    },
    listCurency: {
      type: Array,
      default: () => [],
    },
    listUnit: {
      type: Array,
      default: () => [],
    },
    durationList: {
      type: Array,
      default: () => [],
    },
    listLayer: {
      type: Array,
      default: () => [],
    },
    defaultQuantityUnit: {
      type: Number,
      default: 1,
    },
    defaultAmountUnit: {
      type: Number,
      default: 1,
    },
    startMonth: {
      type: Number,
      default: 4,
    }
  },
  data() {
    return {
      width: '',
      title: this.$t('b_register_product.title_enter_a_proposal_from_the_registered_emission'),
      itemName: '',
      priceVal: null,
      priceAllVal: null,
      numberVal: null,
      numberAllVal: null,
      unitList: [],
      unit: null,
      apportionmentUnitList: [
        {
          text: this.$t('b_register_product.input_field_quantity'),
          value: '生産数量',
          name: this.$t('b_register_product.input_field_quantity'),
        },
        {
          text: this.$t('b_register_product.input_field_amount_of_money'),
          value: '生産金額',
          name: this.$t('b_register_product.input_field_amount_of_money'),
        },
      ],
      apportionmentUnit: '生産数量',
      apportionPercent: null,
      totalEmissionApportionment: 0,
      validItemName: true,
      validSelect: true,
      isErrorData: true,
      validApportment: {
        number: true,
        numberType: true,
        numberTotalType: true,
        numberTotal: true,
        unit: true,
        quantityNumber: true,
        quantityPrice: true
      },
			isCheck: false,
      topPosition: 0,
      dialogSelectEmission: false,
      errorMessageNumber: this.$t('b_register_product.popup_validate_product_1'),
      errorMessagePrice: this.$t('b_register_product.popup_validate_product_2'),
      currentSelectedData: [],
    };
  },
  mounted() {
    this.getWidth();
    window.addEventListener('resize', this.getWidth);
    this.handleUnitChange(this.apportionmentUnit);
    
    this.numberVal = this.formatNumberWithComma(this.number);
    this.numberAllVal = this.formatNumberWithComma(this.numberAll);
    this.priceVal = this.formatNumberWithComma(this.price);
    this.priceAllVal = this.formatNumberWithComma(this.priceAll);

    if(Object.keys(this.processSelect).length) {
      this.itemName = this.processSelect?.process_item_name
      this.totalEmissionApportionment = formatValue(this.processSelect?.emissions_val)
      this.apportionmentUnit = this.processSelect?.apportionment_unit
      this.handleUnitChange(this.apportionmentUnit, false);
      this.unit = this.processSelect?.number_production_unit

      if(this.isHandleNumber) {
        this.numberAllVal = this.processSelect?.number_total_production
        this.numberVal = this.processSelect?.number_production
      } else {
        this.priceAllVal = this.processSelect?.number_total_production
        this.priceVal = this.processSelect?.number_production
      }
    }

    this.handleCalcEmissions(true)

    this.$nextTick(() => {
      this.handleGetPositionWhenScroll()
    })
  },
  methods: {
    handleUnitClicked() {
      this.handleUnitChange(this.apportionmentUnit);
    },
    handleCloseSelect() {
      for (let item in this.$refs) {
        if (this.$refs[item]) {
          this.$refs[item].isMenuActive = false;
        }
      }
    },
    close() {
      this.$emit('close');
    },
    submit() {
      this.$emit('submit');
    },
    handleClickOutSite() {
      this.$emit('close');
    },
    getWidth() {
      if (window.innerWidth > 1024) {
        this.width = this.isExpand ? 'calc(100% - 236px)' : 'calc(100% - 72px)';
        return
      }
      this.width = '100%';
    },
    handleUpdateValue(newVal) {
      this.itemName = newVal;
    },
    handleUpdatePrice(newVal) {
      this.priceVal = newVal;
      this.handleCalcEmissions()
    },
    handleUpdateNumber(newVal) {
      this.numberVal = newVal;
      this.handleCalcEmissions()
    },
		handleUpdateTotal(newVal) {
			this.numberAllVal = newVal
      this.handleCalcEmissions()
		},
		handleUpdatePriceAll(newVal) {
			this.priceAllVal = newVal
      this.handleCalcEmissions()
		},
    handleUnitChange(apportionmentUnit, isCalcEmission = true) {
      if(isCalcEmission) {
        this.handleCalcEmissions()
      }
      if (apportionmentUnit === this.apportionmentUnitList[0].value) {
        this.unitList = this.listUnit.map(item => {
          return {
            value: item.id,
            text: item.name,
            name: item.name
          }
        });
        this.unit = this.defaultQuantityUnit;
      } else {
        this.unitList = this.listCurency.map(item => {
          return {
            value: item.id,
            text: item.name,
            name: item.name
          }
        });
        this.unit = this.defaultAmountUnit;
      }
    },
    handleOpenSelectEmission() {
      this.dialogSelectEmission = true;
    },
    resetToValidData() {
      this.validItemName = true;
      this.validSelect = true;
      this.validApportment.number = true;
      this.validApportment.numberTotal = true;
      this.validApportment.numberType = true;
      this.validApportment.numberTotalType = true;
      this.validApportment.unit = true;
      this.validApportment.quantityNumber = true;
      this.validApportment.quantityPrice = true;
    },
    isValidData() {
      this.isCheck = true;
      this.resetToValidData();
      if(!this.unit) {
        this.validApportment.unit = false;
      }

      if (!this.itemName) {
        this.validItemName = false;
      }

      if (this.dataRegistedSelect.length <= 0) {
        this.validSelect = false;
      }
      if ((!this.priceVal && this.isHandlePrice) ||  (!this.numberVal && this.isHandleNumber)) {
        this.validApportment.number = false;
      } else if (
        (!$_helper_isNumberType(this.priceVal) && this.isHandlePrice) ||
        (!$_helper_isNumberType(this.numberVal) && this.isHandleNumber)
      ) {
        this.validApportment.numberType = false;
      }
      if ((!this.priceAllVal && this.isHandlePrice) || (!this.numberAllVal && this.isHandleNumber)) {
        this.validApportment.numberTotal = false;
      } else if (
        (!$_helper_isNumberType(this.priceAllVal) && this.isHandlePrice) ||
        (!$_helper_isNumberType(this.numberAllVal) && this.isHandleNumber)
      ) {
        this.validApportment.numberTotalType = false;
      }
      
      if(
        this.isHandleNumber &&
        $_helper_isNumberType(this.numberVal) && 
        $_helper_isNumberType(this.numberAllVal) &&
        formatValue(this.numberVal) - formatValue(this.numberAllVal) > 0
      ) {
        this.validApportment.quantityNumber = false;
      }
  
      if(
        this.isHandlePrice &&
        $_helper_isNumberType(this.priceVal) && 
        $_helper_isNumberType(this.priceAllVal) &&
        formatValue(this.priceVal) - formatValue(this.priceAllVal) > 0
      ) {
        this.validApportment.quantityPrice = false;
      }

      return (
        this.validItemName &&
        (this.validSelect || this.totalEmissionSelect) &&
        this.validApportment.number &&
        this.validApportment.numberType &&
        this.validApportment.numberTotal &&
        this.validApportment.numberTotalType &&
        this.validApportment.unit &&
        this.validApportment.quantityPrice &&
        this.validApportment.quantityNumber
      );
    },
    handleSubmitData() {
      if (!this.isValidData()) {
        const element = document.getElementById('content');
        element?.scrollTo(0, 0);
        return;
      }

      const data = {
        compilation_method: this.$t('b_register_product.label_apointment_input_minute'),
        process_item_name: this.itemName,
        apportionment_unit: this.apportionmentUnit,
        number_production: this.isHandlePrice ? this.priceVal : this.numberVal,
        number_production_unit: this.unit,
        number_total_production: this.isHandlePrice ? this.priceAllVal : this.numberAllVal,
        number_total_production_unit: this.unit,
        emissions: this.totalEmissionApportionment,
        index: this.processSelect?.index >= 0 ? this.processSelect?.index : -1,
        compilation_method_code: 'apointment_input'
      }
      if(this.processSelect?.id) {
        data.id = this.processSelect?.id
      }
      this.$emit('submitDataRegisted', data);
      this.$emit('close');
    },
    formatNumber(num) {
      return addThoundSandComma(num);
      // return formatBigNumber(num)
      //   .replace(/[^0-9\.\-]+/g, '')
      //   .replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,');
    },
    handleCalcEmissions(isFromProcess = false) {
      if (this.apportionmentUnit === this.apportionmentUnitList[0].value && this.numberVal && this.numberAllVal) {
        this.apportionPercent = formatBigNumber(math.evaluate(`${formatValue(this.numberVal?.toString())} / ${formatValue(this.numberAllVal?.toString())} * 100`), 23)
      } else {
        if(this.priceVal && this.priceAllVal) {
          this.apportionPercent = formatBigNumber(math.evaluate(`${formatValue(this.priceVal?.toString())} / ${formatValue(this.priceAllVal?.toString())} * 100`), 23)
        }
      }
      if(isFromProcess) {
        if(this.totalEmissionApportionment && this.apportionPercent) {
          const totalEmissions = formatBigNumber(math.evaluate(`${formatValue(this.totalEmissionApportionment?.toString())} / ${formatValue(this.apportionPercent?.toString())} * 100`), 23)
          this.$emit('updateTotalEmission', totalEmissions)
        }
      } else {
        if (this.totalEmissionSelect && this.apportionPercent) {
          this.totalEmissionApportionment = formatBigNumber(math.evaluate(`${formatValue(this.totalEmissionSelect?.toString())} * ${formatValue(this.apportionPercent?.toString())} / 100`), 23)
        }
      }
    },
    handleGetPositionWhenScroll() {
      let isAnyMenuOpen = false
      this.topPosition = 0
      for (let item in this.$refs) {
        if (this.$refs[item] && this.$refs[item]?.isMenuActive) {
          isAnyMenuOpen = true
        }
      }
      const element = document.getElementById('content');
      this.topPosition = isAnyMenuOpen ? element.scrollTop : 0
    },
    updateEmissionToTable(data) {
      this.currentSelectedData = data;
      this.$emit('handleUpdateEmissionToTable', this.currentSelectedData);
    },
    formatNumberWithComma(value) {
      if ($_helper_isNumberType(value)) {
        // return value?.toString()?.replace(/(?<!\..*)(\d)(?=(?:\d{3})+(?:\.|$))/g, '$1,'); // add , to after 3 numbers
        return addThoundSandComma(value?.toString())
      }
      return value;
    },
  },
  computed: {
		...mapState('commonApp', ['isExpand']),
    isHandleNumber() {
      return this.apportionmentUnit === this.apportionmentUnitList[0].value;
    },
    isHandlePrice() {
      return this.apportionmentUnit === this.apportionmentUnitList[1].value;
    },
    getMessageError() {
      let messageError = '';
			if(!this.isCheck) return ''
      if (
        !this.validItemName ||
        (!this.totalEmissionSelect && this.dataRegistedSelect.length <= 0 )||
        !this.validApportment.number || 
				!this.validApportment.numberTotal || 
        !this.validApportment.unit
      ) {
				messageError += this.$t('validation.error_required')
      }

      if(!this.validApportment.numberType ||  !this.validApportment.numberTotalType) {
				messageError += this.$t('validation.error_number_required')
			}
      if(!this.validApportment.quantityNumber) 
      {
        messageError += this.$t('validation.error_total_production')
      }
      if(!this.validApportment.quantityPrice) {
        messageError += this.$t('validation.error_total_money')
      }
      return messageError
    },
    getTransiTion() {
      if (window.innerWidth > 1024){
        return 'slide-x-reverse-transition'
      } else {
        return 'dialog-bottom-transition'
      }
    },
    disableButton() {
      if (this.isHandleNumber) {
        return this.itemName === "" || this.itemName === null ||
          this.numberAllVal === "" || this.numberAllVal === null ||
          this.numberVal === "" || this.numberVal === null ||
          this.unit === "" || this.unit === null;
      }
      return this.itemName === "" || this.itemName === null ||
        this.priceAllVal === "" || this.priceAllVal === null ||
        this.priceVal === "" || this.priceVal === null ||
        this.unit === "" || this.unit === null;
    },
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.getWidth);
  },
  watch: {
    totalEmissionSelect() {
      this.handleCalcEmissions()
    },
    apportionmentUnit() {
      this.isCheck = false;
      this.resetToValidData();
    }
  }
};
</script>

<style lang="scss" scoped>
.select-pulldown {
  position: relative;
  box-sizing: content-box;
}
.animated {
  animation-duration: 0.1s;
  animation-fill-mode: both;
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}
</style>