<template>
  <div>
    <v-dialog
      v-model="getDialog"
      :width="width"
      :max-width="width"
      :right="true"
      :content-class="`product-dialog-popup select-emission input-free ${
        dialog ? 'fullscreen-dialog-transition-open-to' : 'fullscreen-dialog-transition-leave-to'
      }`"
      origin="top left"
      ref="dialog"
      :transition="getTransiTion"
      overlay-color="rgba(0, 0, 0)"
      overlay-opacity="0.5"
      scrollable
    >
      <div class="head-dialog-popup">
        <div class="head-close-btn" @click="close">
          <img class="head-image-dialog-popup head-btn-close" src="@/assets/images/dialog/close.svg" />
          <img class="head-image-dialog-popup head-btn-close_active" src="@/assets/images/dialog/close_active.svg" />
        </div>
        <span class="head-title">{{ $t('b_linked_service.title_popup_list_service') }}</span>
      </div>
      <div class="content-dialog-popup content-list-linked">
        <div class="content-title">{{ $t('b_linked_service.description_popup_list_service') }}</div>
        <div class="search-item">
          <div class="search-list" id="content">
            <div class="search-list__title">{{ $t('b_linked_service.title_detail_service_lca') }}</div>
            <div class="search-service">
              <div class="search-service__input">
                <InputField
                  v-model="searchValue"
                  :inputValue="searchValue"
                  tabIndex="-1"
                  :label="$t('b_linked_service.placeholder_input_search')"
                ></InputField>
              </div>
              <div class="search-service__action">
                <CommonButton
                  class="submit-btn"
                  :label="$t('b_linked_service.button_search')"
                  @action="searchService"
                  :isDisable="isDisableSearch"
                  type="colored"
                  :loading="isLoadingSubmit"
                />
              </div>

              <div v-if="dataProduct" class="search-service__detail">
                <div>{{ $t('b_linked_service.label_name_product') }}：{{ dataProduct.product_name }}</div>
                <div>{{ $t('b_linked_service.label_code_product') }}: {{ dataProduct.code }}</div>
                <div>{{ $t('b_linked_service.label_emission_product') }}：{{ dataProduct.co2_emission }}t-CO2</div>
              </div>
            </div>
          </div>

          <div v-if="dataProduct" class="content-table-detail-service">
            <data-table
              :init-grid="initGrid"
              :data-source="packageMaterialGrid"
              :grid-columns="externalColumns"
              :allowAddNew="false"
              :rowFocus="1"
              :isShowToolbar="false"
              :showFocus="true"
              @onHandlerSelecteFilterColumn="onHandlerSelecteFilterColumn"
            />
          </div>

          <div v-if="dataProduct">
            <CommonButton
              class="submit-btn"
              :label="$t('b_linked_service.button_submit')"
              type="colored"
              @action="importData"
            />
          </div>
        </div>
      </div>
    </v-dialog>
    <ErrorPopup :dialog="dialogError" :message="messageError" @submit="dialogError = false" typeColor="redColor" />
  </div>
</template>
      
<script>
import InputField from '@/components/products/input/InputField.vue';
import CommonButton from '@/components/utils/button.vue';
import { CollectionView } from '@mescius/wijmo';
import { mapState } from 'vuex';
import SelectEmissionRegistedPopup from '@/components/products/dialogs/SelectEmissionRegistedPopup.vue';
import DefaultPullDownMenu from '@/components/products/common/pull-down-select.vue';
import DataTable from '@/components/category/data-table';
import ErrorPopup from '@/components/dialogs/notification-popup.vue';
import { getproductById } from '@/api/linked-service-management';
import { getWidthByTextContent } from '@/utils/calcTextWidth';
import { formatBigNumber, math, formatValue } from '@/concerns/newRegisterData/wijmo.helper'
import { addThoundSandComma } from '@/utils/convertNumber';

export default {
  components: { InputField, CommonButton, SelectEmissionRegistedPopup, DefaultPullDownMenu, DataTable, ErrorPopup },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    idService: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      width: '',
      title: this.$t('b_register_product.title_enter_a_proposal_from_the_registered_emission'),
      isShowListService: true,
      packageMaterial: null,
      gridColumns: [],
      externalColumns: [],
      flexgrid: null,
      packageMaterialGrid: null,
      dialogError: false,
      messageError: this.$t('b_linked_service.error_search_error'),
      searchValue: '',
      dataProduct: null,
      searchOldValue: '',
      selectedFilterColumn: null,
      isLoadingSubmit: false
    };
  },
  mounted() {
    this.getWidth();
    window.addEventListener('resize', this.getWidth);
  },
  methods: {
    getWidth() {
      if (window.innerWidth > 1024) {
        this.width = this.isExpand ? 'calc(100% - 236px)' : 'calc(100% - 72px)';
        return;
      }
      this.width = '100%';
    },

    async fetchDetailProduct() {
      this.isLoading = true;
      try {
        // IuK8nqBx3d
        this.searchOldValue = this.searchValue;

        const res = await getproductById({ service_link_id: this.idService, product_code: this.searchValue });
        if (!res.data || !res.data.length) {
          this.dialogError = true;
          this.dataProduct = null;
          this.isLoadingSubmit = false;
          return;
        }
        this.dataProduct = res.data[0];
        this.dataProduct.packages = res.data[0].packages.map((item) => {
          let totalEmissionValue = formatValue(
            math.evaluate(
              `${math.bignumber(formatValue(item.package_used_number))} * ${math.bignumber(
                formatValue(item.value_source),
              )}`,
            )
          );
          item.total_emission = addThoundSandComma(totalEmissionValue) + 't-CO2';
          item.value_source_unit = item.value_source.toString() + 't-CO2';
          return item;
        });
        this.packageMaterialGrid = new CollectionView([...this.dataProduct.packages]);
        if (this.packageMaterialGrid) {
          this.data = this.packageMaterialGrid.items.filter((item) => item.id && item.id !== BLANK_ID);
          this.packageMaterialGrid.currentItem = null;
        }
        if (this.flexgrid) {
          this.flexgrid.columnGroups = this.initGridColumn();
        }
        this.packageMaterialGrid.currentItem = null;
        this.isLoading = false;
        this.isLoadingSubmit = false;
      } catch (error) {
        console.error(error);
        this.isLoadingSubmit = false;
      }
    },

    initGridColumn() {
      return [
        {
          header: this.$t('b_linked_service.label_table_packaging_name'),
          binding: 'package_name',
          minWidth: getWidthByTextContent(this.$t('b_linked_service.label_table_packaging_name')),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_linked_service.label_table_quantity'),
          binding: 'package_used_number',
          minWidth: 70,
          maxWidth: 1428,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          wordWrap: true,
        },
        {
          header: this.$t('b_linked_service.label_table_intensity'),
          binding: 'value_source_unit',
          minWidth: getWidthByTextContent(this.$t('b_linked_service.label_table_intensity')),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          wordWrap: true,
          align: 'right',
          cssClassAll: 'single-row',
        },
        {
          header: this.$t('b_linked_service.label_table_quantity_2'),
          binding: 'total_emission',
          minWidth: getWidthByTextContent(this.$t('b_linked_service.label_table_quantity_2')),
          maxWidth: 400,
          allowSorting: false,
          isRequired: false,
          isReadOnly: true,
          wordWrap: true,
          align: 'right',
          cssClassAll: 'single-row',
        },
      ];
    },

    initGrid(grid) {
      this.flexgrid = grid;
      if (this.flexgrid) {
        this.flexgrid.columnGroups = this.initGridColumn();
      }

      grid.formatItem.addHandler((s, e) => {});
    },

    searchService() {
      this.isLoadingSubmit = true;
      this.fetchDetailProduct();
    },

    importData() {
      this.$emit('close');
      this.$emit('updateData', this.dataProduct);
      this.close();
    },

    close() {
      this.getDialog = false;
      this.dataProduct = null;
      this.searchOldValue = null;
      this.searchValue = null;
    },

    onHandlerSelecteFilterColumn(column) {
      this.selectedFilterColumn = column;
    },
  },
  computed: {
    ...mapState('commonApp', ['isExpand']),
    getTransiTion() {
      if (window.innerWidth > 1024) {
        return 'slide-x-reverse-transition';
      } else {
        return 'dialog-bottom-transition';
      }
    },
    getDialog: {
      get() {
        return this.dialog;
      },
      set(value) {
        this.$emit('update:dialog', value);
      },
    },
    isDisableSearch() {
      if (this.isLoadingSubmit) {
        return false;
      }
      return this.searchOldValue === this.searchValue || !this.searchValue;
    },
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.getWidth);
  },
};
</script>

<style lang="scss">
.style-emission {
  display: flex !important;
  justify-content: flex-end !important;

  .wj-flexgrid .wj-cell.wj-align-right {
    justify-content: flex-end;
  }
  .wj-flexgrid .wj-cell.wj-align-center {
    justify-content: center;
  }
}
</style>
      
<style lang="scss" scoped>
.select-pulldown {
  position: relative;
  box-sizing: content-box;
}

.animated {
  animation-duration: 0.1s;
  animation-fill-mode: both;
}
.content-list-linked {
  height: 100% !important;
}

.search-list {
  width: 100%;
  display: flex;

  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;

  &__title {
    color: $monoBlack;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px;
    /* 175% */
    letter-spacing: 1.6px;
  }

  .search-service {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__input {
      width: 200px;
    }

    &__detail {
      font-size: 15px;
      font-style: normal;
      font-weight: 400;
      line-height: 28px;
      /* 186.667% */
      letter-spacing: 0.75px;
      color: $monoBlack;
    }
  }
}

@include desktop {
  .content-list {
    gap: 24px;
  }

  .content-table-detail-service {
    margin: 16px 0;
  }

  .search-item {
    padding: 16px 32px 32px 32px;
  }

  .item-service {
    width: 636px;

    &__action {
      display: flex;
      padding: 8px 16px;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      align-self: stretch;
    }
  }
}

@media only screen and (max-width: 786px) {
  .content-dialog-popup {
    display: block !important;
  }

  .content-table-detail-service {
    margin: 16px 20px !important;

    #category-table {
      margin-bottom: 0px !important;
    }
  }

  .search-item {
    padding: 16px 20px 32px 20px;
  }
  .content-list {
    gap: 32px;
    padding: 16px 20px 20px;

    .title-list {
      gap: 24px;
    }

    .item-service {
      width: 288px;
      margin: 0 auto;

      &__action {
        display: flex;
        flex-direction: column;
      }
    }
  }
}

@keyframes slideInRight {
  from {
    transform: translateX(100%);
  }

  to {
    transform: translateX(0);
  }
}

@keyframes slideOutRight {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(100%);
  }
}
</style>
    