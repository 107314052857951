import request from './base/request';
import store from '@/store';
const SERVICE_MANAGEMENT_URL = '/services';

export const getListServiceManagement = () => {
    return request
        .get(SERVICE_MANAGEMENT_URL)
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const getDetailsServiceManagement = (params) => {
    return request
        .get(`${SERVICE_MANAGEMENT_URL}/link`, { params })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const updateParnerCompanyId = (data) => {

    return request
        .post(`${SERVICE_MANAGEMENT_URL}/link`, data)
        .then((res) => {
            return res;
        })
        .catch((error) => {
            throw error;
        });
};

export const getAllProduct = () => {
    const contractorId = store.state.userData.contractor;
    console.log('contractorId', contractorId)
    return request
        .get('/services/list-product', { params: { contractor_id: contractorId } })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const getproductById = (params) => {
    const contractorId = store.state.userData.contractor;
    return request
        .get('/services/get-detail-service-by-product-code', { params: { ...{ contractor_id: contractorId }, ...params } })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};

export const checkServiceLinked = (params) => {
    return request
        .get('/services/link', { params: params })
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
}

export const getAllService = () => {
    return request
        .get('/services?is_exist_service_linked=1')
        .then((res) => {
            return res;
        })
        .catch((err) => {
            throw err;
        });
};




